import { Form, Row } from 'react-bootstrap'
import AirportSearch from '../../Users/components/AirportSearch'
import LocationSearch from './LocationSearch'
import React from 'react'
import { useTranslation } from 'react-i18next'

function FlightsForm ({ register, errors, namePrefix, setValue, index, flight }) {

  const { t } = useTranslation()

  return (
    <>
      <hr/>

      <p>{t('pages.top_15_deals.headers.flights_details')}</p>
      <Row className="">
        <input
          type="hidden"
          {...register(`${namePrefix}id`)}
          value={flight?.id}
        />
        <input
          type="hidden"
          {...register(`${namePrefix}inbound_flight_id`)}
          value={flight?.inbound_flight_id}
        />
        <input
          type="hidden"
          {...register(`${namePrefix}outbound_flight_id`)}
          value={flight?.outbound_flight_id}
        />
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.total_flight_price')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.total_flight_price') })}
            {...register(`${namePrefix}price`)}
            isInvalid={!!(errors && errors.price)}
            step="0.01"
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.price && errors.price.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.booking_link')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.booking_link') })}
            {...register(`${namePrefix}link`)}
            isInvalid={!!(errors && errors.link)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.link && errors.link.message}
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mt-2 col col-12" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text text-primary">
            {t('pages.top_15_deals.form.outbound_flight')}
          </Form.Label>
        </Form.Group>

        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.airline_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.airline_name') })}
            {...register(`${namePrefix}outbound_airline_name`)}
            isInvalid={!!(errors && errors.outbound_airline_name)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.outbound_airline_name && errors.outbound_airline_name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.airline_code')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.airline_code') })}
            {...register(`${namePrefix}outbound_airline_code`)}
            isInvalid={!!(errors && errors.outbound_airline_code)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.outbound_airline_code && errors.outbound_airline_code.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.departure_time')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.departure_time') })}
            {...register(`${namePrefix}outbound_departure_time`)}
            isInvalid={!!(errors && errors.outbound_departure_time)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.outbound_departure_time && errors.outbound_departure_time.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.arrival_time')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.arrival_time') })}
            {...register(`${namePrefix}outbound_arrival_time`)}
            isInvalid={!!(errors && errors.outbound_arrival_time)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.outbound_arrival_time && errors.outbound_arrival_time.message}
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mt-2 col col-12" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text text-primary">
            {t('pages.top_15_deals.form.inbound_flight')}
          </Form.Label>
        </Form.Group>

        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.airline_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.airline_name') })}
            {...register(`${namePrefix}inbound_airline_name`)}
            isInvalid={!!(errors && errors.inbound_airline_name)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.inbound_airline_name && errors.inbound_airline_name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.airline_code')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.airline_code') })}
            {...register(`${namePrefix}inbound_airline_code`)}
            isInvalid={!!(errors && errors.inbound_airline_code)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.inbound_airline_code && errors.inbound_airline_code.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.departure_time')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.departure_time') })}
            {...register(`${namePrefix}inbound_departure_time`)}
            isInvalid={!!(errors && errors.inbound_departure_time)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.inbound_departure_time && errors.inbound_departure_time.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.arrival_time')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.arrival_time') })}
            {...register(`${namePrefix}inbound_arrival_time`)}
            isInvalid={!!(errors && errors.inbound_arrival_time)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.inbound_arrival_time && errors.inbound_arrival_time.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  )
}

export default FlightsForm