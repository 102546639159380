import LOCATION from '@/constants/Location'
import locationsPage from '@/pages/Admin/Locations'
import Edit from '@/pages/Admin/Locations/Edit'
import Create from '@/pages/Admin/Locations/Create'

const Locations = [
  {
    path: LOCATION.DESTINATIONS.LIST.path,
    component: locationsPage,
    exact: true,
    main: false,
    gate: 'locations',
  },
  {
    path: LOCATION.DESTINATIONS.EDIT.path,
    component: Edit,
    exact: true,
    main: false,
    gate: 'locations',
  },
  {
    path: LOCATION.DESTINATIONS.CREATE.path,
    component: Create,
    exact: true,
    main: false,
    gate: 'locations',
  },
]

export default Locations