import {useLocation, useParams} from "react-router-dom";
import {useAuth} from "../../services/Auth";
import React, {useEffect, useState} from "react";
import OnPageNotification from "../../shared/components/OnPageNotification";
import CheckCircleIcon from "../../shared/components/Icons/CheckCircleIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import LoadingCover from "../../shared/components/LoadingCover";
import LOCATION from "../../constants/Location";

function PaymentCallback() {

    const {t} = useTranslation();

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const auth = useAuth();

    const {product, response_type} = useParams();

    const [processing, setProcessing] = useState(true);
    const [processingError, setProcessingError] = useState(false);

    const updateSubscription = () => {

        auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/paypal/success`,{
            subscription_id: params.get('subscription_id'),
        })
            .then(response => {
                setProcessing(false);
            })
            .catch(error => {
                setProcessingError(true);
            })

    }

    useEffect(() => {
        if(response_type === 'success'){
            updateSubscription();
        }
    }, [])

    if (processingError) {
        return (
            <OnPageNotification
                notificationTitle={t('pages.payments.notifications.payment_processing_error.title')}
                notificationDescription={t('pages.payments.notifications.payment_processing_error.body')}
                notificationLinkText={t('common.dialogs.actions.okay')}
                notificationAction={() => window.location.href = LOCATION.ROOT.path}
                notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
            />
        )
    }

    if (response_type === 'cancel') {
        return (
            <OnPageNotification
                notificationTitle={t('pages.payments.notifications.payment_canceled.title')}
                notificationDescription={t('pages.payments.notifications.payment_canceled.body')}
                notificationLinkText={t('common.dialogs.actions.okay')}
                notificationAction={() => window.location.href=LOCATION.ROOT.path}
                notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
            />
        )
    }


    if (processing) return <LoadingCover/>

    return (

        <OnPageNotification
            notificationTitle={t('common.notifications.subscribed.title')}
            notificationDescription={t('common.notifications.subscribed.body')}
            notificationLinkText={t('common.dialogs.actions.okay')}
            notificationAction={() => window.location.href = "/"}
            notificationIcon={<FontAwesomeIcon icon={faCheckCircle}/>}
        />
    )

}

export default PaymentCallback;