import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'

function ManageAccountDetails(){

  const { t } = useTranslation()
  const auth = useAuth()

  return (
    <>
      <h4 className="heading-text">{t('pages.profile.account_details')}</h4>
      <Row className="mb-4 pb-4">
        <Col className="col-12">
          <h6 className="my-2">{t('common.form.email')}</h6>
          <h5 className="fw-bold mb-4">{auth.user.email}</h5>
        </Col>
        <Col className="col-12 d-grid">
         <Link className="btn btn-dark text-white heading-text" to={LOCATION.PROFILE.EDIT_ACCOUNT_DETAILS.path}>{t('common.buttons.edit')}</Link>
        </Col>
      </Row>
    </>
  )
}
export default ManageAccountDetails;