import TripOverview from '@/pages/Deals/DealSelectionProcess/Steps/TripOverview'
import React, {useEffect, useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import DealForm from "./components/DealForm";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useGet from "../../../hooks/useGet";
import {Button, Form} from "react-bootstrap";

function Edit () {

    const { t } = useTranslation()
    const auth = useAuth()
    const history = useHistory()
    const route = useLocation()

    const { id, week_id } = useParams()

    const [deal, setDeal] = useState(null)
    const [loading, setLoading] = useState(true)
    const [chosenValues, setChosenValues] = useState(route.state && route.state.chosenValues || {
        hotel_price: 0,
        flights_price: 0
    })

    const [fetchingTrip, setFetchingTrip] = useState(true)

    const getDeal = () => {
        return auth.getRequest(`${LOCATION.DEALS.API.path}/${id}`)
            .then(response => {
                setDeal(response.data?.data)
                setLoading(false)
                reset(mapDeal(response.data?.data))
            })
            .catch(error => {
                setLoading(false)
            })
    }

    useEffect(() => {
        let request = getDeal()

        return () => request?.abort && request.abort()
    }, [])

    const { data: holidayTypes } = useGet(LOCATION.HOLIDAY_TYPES.API.path)

    const schema = yup.object().shape({
        adults: yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.adults') })),
        children: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        children_ages: yup.array()
            .typeError(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
            .when(['children'], function (children, schema) {
                return children && children != 0 ?
                    schema.of(yup.number()
                        .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
                        .transform((value) => (isNaN(value) ? undefined : value)))
                    : schema.nullable()
            }),
        holiday_type_id: yup.number()
            .required(t('common.form_validation.is_required', { attribute: t('pages.trips.steps.holiday_type.title') })),
        airport_id: yup.number()
            .required(t('common.form_validation.is_required', { attribute: t('common.airport') })),
        location_id: yup.number()
            .required(t('common.form_validation.is_required', { attribute: t('pages.locations.location') })),
        custom_message: yup.string().nullable(),
        flights: yup.object().shape({
            inbound_airline_code: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_code') })),
            inbound_airline_name: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_name') })),
            inbound_arrival_time: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.arrival_time') })),
            inbound_departure_time: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.departure_time') })),
            outbound_airline_code: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_code') })),
            outbound_airline_name: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_name') })),
            outbound_arrival_time: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.arrival_time') })),
            outbound_departure_time: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.departure_time') })),
            price: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.total_price') })),
        }),
        hotel: yup.object().shape({
            name: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.hotel_name') })),
            hotel_teaser: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.hotel_description') })),
            star_rating: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.star_rating') })),
            guest_star_rating: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.guest_star_rating') })),
            thumbnail_url: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.thumbnail_url') })),
            price: yup.string()
                .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.total_price') })),
        })
    });

    const mapDeal = (deal) => {

        return {
            id: deal.id,
            trip_request_id: deal.trip_request_id,
            adults: deal.trip_request.adults,
            children: deal.trip_request.children,
            children_ages: deal.trip_request.children_ages,
            holiday_type_id: deal.trip_request.holiday_type_id,
            airport: deal.airport,
            airport_id: deal.airport.id,
            location: deal.location,
            location_id: deal.location.id,
            custom_message: deal.custom_message,
            flights: {
                ...deal.flight,
                id:  deal.flight.id,
                inbound_flight_id:  deal.flight.inbound_flight.id,
                outbound_flight_id:  deal.flight.outbound_flight.id,
                inbound_airline_code:  deal.flight.inbound_flight.airline_code,
                inbound_airline_name:  deal.flight.inbound_flight.airline_name,
                inbound_arrival_time:  deal.flight.inbound_flight.arrival_time,
                inbound_flight_number:  deal.flight.inbound_flight.flight_number,
                inbound_departure_time:  deal.flight.inbound_flight.departure_time,
                outbound_airline_code:  deal.flight.outbound_flight.airline_code,
                outbound_airline_name:  deal.flight.outbound_flight.airline_name,
                outbound_arrival_time:  deal.flight.outbound_flight.arrival_time,
                outbound_departure_time:  deal.flight.outbound_flight.departure_time,
                outbound_flight_number:  deal.flight.outbound_flight.flight_number,
                link:  deal.flight.link,
                price:  deal.flight.price,
            },
            hotel: {
                ...deal.hotel,
                id:  deal.hotel.id,
                name: deal.hotel.name,
                link: deal.hotel.link,
                hotel_teaser: deal.hotel.hotel_teaser,
                star_rating: parseInt(deal.hotel.start_rating),
                guest_star_rating: parseInt(deal.hotel.guest_rating),
                thumbnail_url: deal.hotel.thumbnail_url,
                price: deal.hotel.price,
                hotel_amenities: deal.hotel.hotel_amenities,
            }
        }

    }

    const {
        handleSubmit,
        register,
        unregister,
        setValue,
        watch,
        reset,
        formState: { errors, defaultValues },
    } = useForm({
        defaultValues: ((_) => {
            return {
                id: deal?.id,
                trip_request_id: deal?.trip_request_id,
                adults: deal?.trip_request.adults,
                children: deal?.trip_request.children,
                children_ages: deal?.trip_request.children_ages,
                holiday_type_id: deal?.trip_request.holiday_type_id,
                airport: deal?.airport,
                airport_id: deal?.airport.id,
                location: deal?.location,
                location_id: deal?.location.id,
                custom_message: deal?.custom_message,
                flights: {
                    ...deal?.flight,
                    id:  deal?.flight.id,
                    inbound_flight_id:  deal?.flight.inbound_flight.id,
                    outbound_flight_id:  deal?.flight.outbound_flight.id,
                    inbound_airline_code:  deal?.flight.inbound_flight.airline_code,
                    inbound_airline_name:  deal?.flight.inbound_flight.airline_name,
                    inbound_arrival_time:  deal?.flight.inbound_flight.arrival_time,
                    inbound_departure_time:  deal?.flight.inbound_flight.departure_time,
                    outbound_airline_code:  deal?.flight.outbound_flight.departure_time,
                    outbound_airline_name:  deal?.flight.outbound_flight.airline_name,
                    outbound_arrival_time:  deal?.flight.outbound_flight.arrival_time,
                    outbound_departure_time:  deal?.flight.outbound_flight.departure_time,
                    link:  deal?.flight.link,
                    price:  deal?.flight.price,
                },
                hotel: {
                    ...deal?.hotel,
                    id:  deal?.hotel.id,
                    name: deal?.hotel.name,
                    link: deal?.hotel.link,
                    hotel_teaser: deal?.hotel.hotel_teaser,
                    star_rating: parseInt(deal?.hotel.start_rating),
                    guest_star_rating: parseInt(deal?.hotel.guest_rating),
                    thumbnail_url: deal?.hotel.thumbnail_url,
                    price: deal?.hotel.price,
                    hotel_amenities: deal?.hotel.hotel_amenities,
                }
            }
        })(),
        resolver: yupResolver(schema),
    })

    const setSelections = (values) => {
        setChosenValues({ ...chosenValues, ...values })
    }

    const getTrip = () => {
        return auth.getRequest(LOCATION.TRIPS.API.path, {
            deal_id: deal?.id,
            flight_id: deal?.flight_id,
            hotel_id: deal?.hotel_id,
            user_id: deal?.user_id,
        }).then(response => {
            setFetchingTrip(false)
        })
    }

    const updateDeal = (values) => {
        return auth.putRequest(`${LOCATION.DEALS.API.path}/${id}`, values)
            .then(response => {
                // Go back to the deals page
                history.goBack()
            });
    }

    useEffect(() => {
        if( deal ){
            let request = getTrip()
            return () => request?.abort && request.abort()
        }
    }, [deal])

    if (loading || fetchingTrip) return <LoadingCover/>;

    return (
        <div className="p-10">
            <form onSubmit={handleSubmit(updateDeal)}>
                <DealForm
                    deal={deal}
                    watch={watch}
                    removeCustomDeal={() => null}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    holidayTypes={holidayTypes}
                    errors={errors}
                    namePrefix=""
                />

                <Form.Group className="col col-12 my-2" controlId="formBasicName">
                    <Button variant="primary" className="heading-text text-white" type="submit">{t('common.buttons.update')}</Button>
                </Form.Group>
            </form>
        </div>
    )
}

export default Edit