const Signup = {
  title: 'Sign Up for the free travel lite membership',
  title_max: 'SIGN UP FOR TRAVEL MAX',
  title_lite: 'SIGN UP FOR TRAVEL LITE',
  link_title: 'Sign Up',
  already_have_an_account_login: 'Already have an account?',
  benefits: {
    travel_lite: {
      see_results: 'See the results of Deal Searches',
      save_deal: 'Save Deals for Later',
      deal_sent_to_inbox: 'Deals Sent Straight to Your Inbox',
      view_to_15_deals: 'View our Top 15 Deals of the Week',
    },
    travel_max: {
      see_results: 'See the results of Deal Searches',
      save_deal: 'Save Deals for Later',
      deal_sent_to_inbox: 'Deals Sent Straight to Your Inbox',
      view_to_15_deals: 'View our Top 15 Deals of the Week',
    }
  }
}
export default Signup
