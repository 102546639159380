import { Redirect } from 'react-router-dom'
import React from 'react'
import { useAuth } from '../services/Auth'
import TravelArea from '../pages/TravelArea'
import LOCATION from '../constants/Location'

function Dashboard (props) {
  const auth = useAuth()

  if (auth.user?.client) return <TravelArea/>
  if (auth.user?.admin) return (
    <Redirect
      to={{
        pathname: LOCATION.USERS.LIST.path,
        state: { from: props.location }
      }}
    />
  )
}

export default Dashboard