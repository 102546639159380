import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {getDates, getRequestProgressStatus} from "../../../utility";

function TripSummary ({ tripRequest, short }) {
  const { t } = useTranslation()

  return (
    <>
      {
        !short &&
        <>
          <p className="mb-1">{t('pages.trips.steps.summary.step_headings.budget')}</p>
          <p className="fw-bold mb-2">
            {t('pages.trips.steps.summary.values.budget_summary', {
              min: tripRequest?.min_budget,
              max: tripRequest?.max_budget
            })}
          </p>

          <p className="mb-1 mt-4">{t('pages.trips.steps.summary.step_headings.holiday_type')}</p>
          <p className="fw-bold mb-2">
            {tripRequest?.surprise_holiday_type ?
              <span>{t('pages.trips.steps.holiday_type.types.surprise')}</span>
              :
              <span>{tripRequest?.holiday_type?.name}</span>
            }
          </p>
        </>
      }

      <p
        className={`mb-1 ${short ? 'mt-2 small' : 'mt-4'}`}>{t('pages.trips.steps.summary.step_headings.where_from')}</p>
      <p className={`fw-bold mb-2 ${short ? 'small' : ''}`}>
        {
          tripRequest?.airports?.map(_ => _.region_name).join(', ')
        }
      </p>

      <p className={`mb-1 ${short ? 'mt-2 small' : 'mt-4'}`}>{t('pages.trips.steps.summary.step_headings.where_to')}</p>
      <p className={`fw-bold mb-2 ${short ? 'small' : ''}`}>
        {
          (tripRequest?.holiday_type_id && (tripRequest?.any_location_holiday || tripRequest?.locations?.length === 0)) ?
            <span>{`Any ${tripRequest?.holiday_type?.name} Break`}</span> :
            tripRequest?.surprise_holiday_type ?
              <span>{t('pages.trips.steps.holiday_type.types.surprise')}</span>
              :
              <span>{tripRequest?.locations?.map(_ => _.destination).join(', ')}</span>
        }
      </p>
      {
        !short &&
        <>
          <p className="mb-1 mt-4">{t('pages.trips.steps.summary.step_headings.who_is_going')}</p>
          <p className="fw-bold mb-2">
            {t('pages.trips.steps.summary.values.adults', { count: tripRequest?.adults })}
            {
              (tripRequest?.children && tripRequest?.children?.length !== 0) ?
                <div>
                  {t('pages.trips.steps.summary.values.children', { count: tripRequest?.children })},&nbsp;
                  ({t('pages.trips.steps.summary.values.children_ages', { count: tripRequest?.children_ages?.join(', ') })})
                </div>
                : null
            }
          </p>
        </>
      }
      <p className={`mb-1 ${short ? 'mt-2 small' : 'mt-4'}`}>{t('pages.trips.steps.summary.step_headings.when')}</p>
      <p className={`fw-bold mb-2 ${short ? 'small' : ''}`}>
        {getDates(tripRequest, t)},&nbsp;{t('pages.trips.form.labels.when.night', { count: tripRequest?.how_long })}
      </p>
      <p className={`mb-1 ${short ? 'mt-2 small' : 'mt-4'}`}>{t('common.status')}</p>
      <p className={`fw-bold mb-2 ${short ? 'small' : ''}`}>
        {getRequestProgressStatus(tripRequest, t)}
      </p>
    </>
  )
}

export default TripSummary