import LOCATION from "../../../constants/Location";
import TripRequests from "../../../pages/Admin/TripRequests";
import ShowRequest from "../../../pages/Admin/TripRequests/ShowRequest";

const Requests = [
  {
    path: LOCATION.TRIPS.REQUESTS.path,
    component: TripRequests,
    exact: true,
    main: false,
    gate: 'trip_requests',
  },
  {
    path: LOCATION.TRIPS.REQUEST_SHOW.path,
    component: ShowRequest,
    exact: true,
    main: false,
    gate: 'trip_requests',
  },
];

export default Requests;