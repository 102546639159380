function BagIcon({size, color}) {
  return (
    <svg width={size ?? 43} height={size ?? 38} viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M19.4206 0.23877C17.766 0.23877 16.1792 0.896056 15.0092 2.06603C13.8392 3.23601 13.1819 4.82284 13.1819 6.47744V8.55699H6.94326C5.28866 8.55699 3.70183 9.21428 2.53185 10.3843C1.36188 11.5542 0.70459 13.1411 0.70459 14.7957V31.4321C0.70459 33.0867 1.36188 34.6735 2.53185 35.8435C3.70183 37.0135 5.28866 37.6708 6.94326 37.6708H36.057C37.7116 37.6708 39.2985 37.0135 40.4684 35.8435C41.6384 34.6735 42.2957 33.0867 42.2957 31.4321V14.7957C42.2957 13.1411 41.6384 11.5542 40.4684 10.3843C39.2985 9.21428 37.7116 8.55699 36.057 8.55699H29.8184V6.47744C29.8184 4.82284 29.1611 3.23601 27.9911 2.06603C26.8211 0.896056 25.2343 0.23877 23.5797 0.23877H19.4206ZM25.6593 8.55699H17.341V6.47744C17.341 5.9259 17.5601 5.39696 17.9501 5.00697C18.3401 4.61698 18.8691 4.39788 19.4206 4.39788H23.5797C24.1312 4.39788 24.6602 4.61698 25.0502 5.00697C25.4402 5.39696 25.6593 5.9259 25.6593 6.47744V8.55699Z"
            fill={color?? 'white'}/>
    </svg>

  )
}

export default BagIcon;