import { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'

import Table, { useColumns } from '@/shared/Tables/Locations'
import LocationFilters from './components/LocationFilters'
import LOCATION from '@/constants/Location'
import { Helmet } from 'react-helmet'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'

function Locations () {

  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15, latest: 1, location_type: 1, filter: false })

  const [searching, setSearching] = useState(false)

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    )
  }, [query])

  return (
    <div className="py-2 px-4">
      <Helmet>
        <title>{t('pages.locations.title')} - {t('common.app')}</title>
      </Helmet>

      <div className="my-4 d-flex flex-sm-row flex-column justify-content-sm-between">
        <AdminPageHeader
          mainTitle={t('pages.locations.title')}
        />

        <div className="d-grid mt-2 mt-sm-0 d-sm-flex">
          <Link to={`${LOCATION.DESTINATIONS.LIST.path}/create`} className="btn btn-primary">
            <h6 className="heading-text my-2 px-5">{t('pages.locations.buttons.new_location')}</h6>
          </Link>
        </div>
      </div>
      <div className="my-4 bg-white">
        <LocationFilters
          searching={searching}
          values={query}
          loadLocations={setQuery}
        />
      </div>
      <div className="my-4 bg-white">
        <Table query={query} showPagination={true} onData={_ => setSearching(!_)}/>
      </div>
    </div>
  )

}

export default Locations