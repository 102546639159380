import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAuth } from '@/services/Auth'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import LOCATION from '@/constants/Location'
import DealsFilter from './components/DealsFilter'
import DealsTable, { useColumns } from '@/shared/Tables/Trips'
import { Card, Col } from 'react-bootstrap'
import moment from 'moment'
import DealsSummary from "../Dashboard/components/DealsSummary";

function AdminDeals ({trip_request_id_prop}) {
  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const { trip_request_id } = useParams()

  const [query, setQuery] = useState(route.state && route.state.query || {
    page: 0,
    skip: 0,
    limit: 15,
    sort_by: 'latest',
    trip_request_id: trip_request_id ?? trip_request_id_prop ,
  })

  const [searching, setSearching] = useState(false)

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    )
  }, [query])

  return (
    <div className={`py-2 ${trip_request_id_prop? 'px-2' :'px-4'}`}>
      {
        !trip_request_id_prop && <>
          <Helmet>
            <title>{t('pages.deals.title')} - {t('common.app')}</title>
          </Helmet>
          <div className="my-4 d-flex justify-content-between">
            <AdminPageHeader
              mainTitle={trip_request_id ? t('pages.deals.title_results') : t('pages.deals.title')}
            />
            <div className="d-flex">
              <Link className="btn btn-primary heading-text mx-2 px-3"
                    to={LOCATION.TRIPS.START_YOUR_JOURNEY.path}>{t('pages.deals.buttons.search_for_deals')}</Link>
            </div>
          </div>
          <DealsSummary/>
        </>
      }

      {
        (!trip_request_id && !trip_request_id_prop) ? 

          <div className="my-4 bg-white">
            <DealsFilter
              searching={searching}
              values={query}
              loadDeals={setQuery}
            />
          </div>
          : null
      }
      <div className="my-4 bg-white">
        <DealsTable query={query} showPagination={true} url={LOCATION.DEALS.API.path} type="deals"
                    onData={_ => setSearching(!_)}/>
      </div>
    </div>
  )
}

export default AdminDeals