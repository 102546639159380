import { Badge, Button, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheck } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { getNumberOfTravelers, getPricePerPerson } from '@/utility'
import { useTranslation } from 'react-i18next'
import { isValidHttpUrl } from '@/utility'
import { useAuth } from '@/services/Auth'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import moment from 'moment/moment'
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";

function CityCard ({ deal, fullCard, dealCard, goToBook, removeTrip, showDates, goToOptions, defaultImage }) {

  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL
  const { t } = useTranslation()

  let imageUrl = (deal && deal?.location.featured_image) ? isValidHttpUrl(deal.location?.featured_image) ? deal.location?.featured_image : `${baseUrl}/${deal.location?.featured_image}` : null

  return (
    <Card className={`${fullCard || dealCard ? 'card-layout h-100' : 'card-layout h-100'}`}>
		<div className="float-left w-100 block image-slider">
      <Card.Img className="deal-card" variant="top" src={imageUrl}/>
	  </div>
      <Card.Body className="d-flex flex-column justify-content-start align-items-start">
	  	<div className="float-left w-100 location-top">
        <h5 className="heading-text text-primary">
            {
                deal.location?.page ?
                    <a target="_blank" href={`${deal.location.page}`}>{deal.location.destination}</a>
                    :
                    deal.location.destination
            }
        </h5>
		
		{
          fullCard || dealCard ?
			<div className="float-left w-100 flex justify-content-start align-items-end">
            <div className="pricing">
              <span>From</span> <strong>{deal.currency_symbol}{getPricePerPerson(deal.total_amount, deal)} pp</strong> <small>({deal.currency_symbol}{deal.total_amount})</small>
            </div>
			</div>
            : null
        }
		</div>
        
        

        {
          showDates ?
            <>
              <ul className="list-group list-group-flush benefits-list mt-4">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faCalendar}/>
                  {moment(deal?.date).format(t('common.formats.date_with_day'))} - {moment(deal.flight?.inbound_flight?.departure_time).format(t('common.formats.date_with_day'))}
                </div>
              </ul>
              <hr/>
            </>
            : null
        }


        <ul className="list-group list-group-flush benefits-list">
          <li className="list-group-item  px-0">
            <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
            {t('pages.deals.flights_and_accommodation_for', { count: getNumberOfTravelers(deal) })}
          </li>
          {
            deal.location?.location_features?.map(locationFeature => {
              return (
                <li className="list-group-item px-0" key={locationFeature.id}>
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  {locationFeature.name}
                </li>
              )
            })
          }
        </ul>
        {
          dealCard ?
            <div className="d-flex gap-2">
              {
                deal.flight.booked ? <Badge bg="primary">{t('pages.trips.flight_booked')}</Badge> : null
              }
              {
                deal.hotel.booked ? <Badge bg="primary">{t('pages.trips.hotel_booked')}</Badge> : null
              }
              {
                deal.hotel.sold_out ? <Badge bg="danger">{t('pages.trips.hotel_sold_out')}</Badge> : null
              }
              {
                deal.flight.sold_out ? <Badge bg="danger">{t('pages.trips.flight_sold_out')}</Badge> : null
              }
              {
                deal.flight.expired ? <Badge bg="danger">{t('pages.trips.flight_expired')}</Badge> : null
              }
              {
                deal.hotel.expired ? <Badge bg="danger">{t('pages.trips.hotel_expired')}</Badge> : null
              }
            </div>
            : null
        }
      </Card.Body>
      {
        fullCard ?
          <Card.Footer className="hotels col">
            {
              goToOptions ?
                <Link to={`${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/results/${deal.trip_request?.id}/${deal.id}`}
                      className="btn btn-primary text-white heading-text">
                  <div className="d-flex justify-content-between align-items-center">
                    {t('pages.deals.buttons.view_this_deal')}
                    <FontAwesomeIcon icon={faArrowRight}/>
                  </div>
                </Link>
                :
                <Link to={`${LOCATION.DEALS.LIST.path}/${deal.id}`} className="btn btn-primary text-white heading-text">
                  <div className="d-flex justify-content-between align-items-center">
                    {t('pages.deals.buttons.view_this_deal')}
                    <FontAwesomeIcon icon={faArrowRight}/>
                  </div>
                </Link>
            }
          </Card.Footer> : null
      }
      {
        dealCard ?
          <Card.Footer className="hotels col">
            <>
              <Link
                to={`${LOCATION.TRIPS.LIST.path}/${deal.id}`}
                className="btn btn-primary heading-text text-white"
              >
                <div className="d-flex justify-content-between align-items-center">
                  {t('pages.trips.buttons.book_now')}
                  <FontAwesomeIcon icon={faArrowRight}/>
                </div>
              </Link>
              <Button variant="danger" className="heading-text text-white" onClick={() => removeTrip(deal)}>
                {t('common.buttons.remove')}
              </Button>
            </>
          </Card.Footer> : null
      }
    </Card>
  )
}

export default CityCard