function UserIcon({color, size}) {
  return (
    <svg width={`${size ?? 39}`} height={`${size ?? 40}`} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1422 9.58456C10.1422 14.7439 14.3408 18.9426 19.5002 18.9426C24.6596 18.9426 28.8582 14.7439 28.8582 9.58456C28.8582 4.42519 24.6596 0.226562 19.5002 0.226562C14.3408 0.226562 10.1422 4.42519 10.1422 9.58456ZM36.1366 39.7381H38.2162V37.6586C38.2162 29.6336 31.6843 23.1017 23.6593 23.1017H15.3411C7.31399 23.1017 0.78418 29.6336 0.78418 37.6586V39.7381H36.1366Z"
        fill={color ?? 'white'}/>
    </svg>
  )
}

export default UserIcon;