function TripIcon({color, size}) {
  return (
    <svg width={`${size ?? 51}`} height={`${size ?? 50}`} viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.1937 41.5907H13.8419C10.9742 41.5907 8.643 39.2595 8.643 36.3918C8.643 33.5241 10.9742 31.1929 13.8419 31.1929H28.3988C32.4123 31.1929 35.6772 27.928 35.6772 23.9145C35.6772 19.9009 32.4123 16.636 28.3988 16.636H18.2901C17.5263 18.1331 16.5806 19.53 15.4743 20.7951H28.3988C30.1186 20.7951 31.5181 22.1947 31.5181 23.9145C31.5181 25.6343 30.1186 27.0338 28.3988 27.0338H13.8419C8.68251 27.0338 4.48389 31.2324 4.48389 36.3918C4.48389 41.5512 8.68251 45.7498 13.8419 45.7498H33.7911C32.7706 44.4662 31.8993 43.0709 31.1937 41.5907ZM10.7226 4.15869C7.28297 4.15869 4.48389 6.95777 4.48389 10.3974C4.48389 17.027 10.7226 20.7951 10.7226 20.7951C10.7226 20.7951 16.9612 17.0249 16.9612 10.3974C16.9612 6.95777 14.1621 4.15869 10.7226 4.15869ZM10.7226 13.5167C10.3128 13.5166 9.90705 13.4357 9.52852 13.2788C9.14999 13.1218 8.80608 12.8919 8.51642 12.602C8.22676 12.3122 7.99703 11.9681 7.84035 11.5895C7.68366 11.2108 7.60308 10.8051 7.60322 10.3953C7.60336 9.98551 7.6842 9.57977 7.84114 9.20124C7.99808 8.82271 8.22804 8.4788 8.51789 8.18915C8.80774 7.89949 9.15181 7.66976 9.53044 7.51307C9.90907 7.35638 10.3149 7.27581 10.7246 7.27595C11.5522 7.27622 12.3458 7.60524 12.9308 8.19062C13.5158 8.776 13.8442 9.56978 13.844 10.3974C13.8437 11.2249 13.5147 12.0185 12.9293 12.6035C12.3439 13.1885 11.5501 13.517 10.7226 13.5167Z"
        fill={color ?? 'white'}/>
      <path
        d="M39.8368 29.1133C36.3972 29.1133 33.5981 31.9124 33.5981 35.3519C33.5981 41.9816 39.8368 45.7497 39.8368 45.7497C39.8368 45.7497 46.0755 41.9795 46.0755 35.3519C46.0755 31.9124 43.2764 29.1133 39.8368 29.1133ZM39.8368 38.4713C39.427 38.4711 39.0213 38.3903 38.6428 38.2334C38.2642 38.0764 37.9203 37.8465 37.6307 37.5566C37.341 37.2668 37.1113 36.9227 36.9546 36.5441C36.7979 36.1654 36.7173 35.7596 36.7175 35.3499C36.7176 34.9401 36.7985 34.5344 36.9554 34.1558C37.1123 33.7773 37.3423 33.4334 37.6322 33.1437C37.922 32.8541 38.2661 32.6243 38.6447 32.4677C39.0233 32.311 39.4291 32.2304 39.8389 32.2305C40.6665 32.2308 41.46 32.5598 42.045 33.1452C42.63 33.7306 42.9585 34.5244 42.9582 35.3519C42.9579 36.1795 42.6289 36.9731 42.0436 37.5581C41.4582 38.1431 40.6644 38.4716 39.8368 38.4713Z"
        fill={color ?? 'white'}/>
    </svg>

  )
}

export default TripIcon