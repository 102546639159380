function GrassesIcon({color, size}) {
  return (
    <svg width={`${size ?? 47}`} height={`${size ?? 30}`} viewBox="0 0 47 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.8887 16.1232V15.6716C46.8887 15.3215 46.8512 15.2513 46.6695 15.1569C46.3479 14.9834 46.0146 14.8322 45.6721 14.7046C45.6027 14.6805 45.5255 14.6594 45.4569 14.636C42.4062 4.63699 39.1785 0.00244141 37.7748 0.00244141C37.016 0.00244141 36.7571 0.528829 36.4802 1.73835C35.8143 4.63621 36.4381 5.5533 36.8335 5.95647C37.3794 6.51327 38.2668 6.05629 38.2668 2.96347C38.8611 4.41708 41.2357 8.88553 43.0745 14.1034C40.002 13.6448 36.0747 13.6846 33.9528 13.8765C31.0776 14.1346 28.6788 14.7959 26.7167 15.8276C24.8794 16.7681 22.1219 16.7681 20.2847 15.8276C18.3203 14.7959 15.923 14.1346 13.0517 13.8765C10.9251 13.6854 6.99707 13.6448 3.92687 14.1034C5.76416 8.8863 8.13797 4.41786 8.73142 2.96347C8.73142 6.05629 9.62043 6.51327 10.1655 5.95647C10.5601 5.5533 11.1863 4.63621 10.5204 1.73835C10.2412 0.528829 9.98384 0.00244141 9.22272 0.00244141C7.81746 0.00244141 4.59285 4.63699 1.54136 14.6376C1.47351 14.6602 1.39865 14.6812 1.33158 14.7046C0.990016 14.8271 0.655467 14.9862 0.331836 15.1569C0.149355 15.2513 0.112703 15.3215 0.112703 15.6716V16.1232C0.112703 16.9732 0.00742541 16.6425 0.600099 16.9966C1.75503 17.6898 2.40931 19.4866 2.76258 21.9618C3.26245 25.5685 4.98744 27.8285 7.79406 28.9093C10.4066 29.9083 13.3013 29.8762 15.8911 28.8196C17.3049 28.2418 18.5441 27.3262 19.5298 25.843C21.2579 23.2415 20.7393 21.6179 21.6314 19.3836C22.4081 17.4325 24.5909 17.4325 25.3692 19.3836C26.2613 21.6179 25.7427 23.2415 27.47 25.843C28.455 27.3262 29.6957 28.2418 31.1095 28.8196C33.6992 29.8766 36.5941 29.9087 39.2065 28.9093C42.0108 27.8285 43.7397 25.5685 44.2388 21.9618C44.5897 19.4866 45.2456 17.6891 46.3997 16.9966C46.9924 16.6425 46.8887 16.9732 46.8887 16.1232Z"
        fill={color ?? 'white'}/>
    </svg>
  )
}

export default GrassesIcon;