import {Card} from "react-bootstrap";
import Chart from "react-apexcharts";
import React from "react";
import {useTranslation} from "react-i18next";

function HourlyCallsGraph({stats}) {

  const {t} = useTranslation();

  const series = stats.calls;

  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    colors: ['#28A745', '#FFC107', '#DC3545', '#17A2B8'],
    stroke: {
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    labels: stats.hours,
    markers: {
      size: 5
    },
    yaxis: {
      title: {
        text: '# Calls',
      },
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Calls";
          }
          return y;

        }
      }
    }
  };
  return (
    <Card className="my-2">
      <Card.Header>{t('pages.dashboard.api_call_graph')}</Card.Header>
      <Card.Body>
        <Chart options={options} series={series} type="line" height={350}/>
      </Card.Body>
    </Card>
  )
}

export default HourlyCallsGraph