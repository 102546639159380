import PageHeader from '../layouts/components/PageHeader'

function PaymentScreen () {
  return (
    <>
      <PageHeader
        title="Payment"
        bgColor="primary"
      />
      <div className="container h-100 p-0">
        <div className="w-100 h-100 py-5 mb-5 d-flex align-items-center justify-content-between bg-dark-grey">
          <h4 className="w-100 text-center heading-text text-white">PAYMENT SCREEN</h4>
        </div>
      </div>
    </>
  )
}

export default PaymentScreen