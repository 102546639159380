import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '@/services/Auth'

function FreeCustomTripsProgress () {
  const auth = useAuth()

  const { t } = useTranslation()

  const claimedTrips = auth.user?.trip_request_count ?? 0
  const freeRTrips = auth.user?.max_trip_request_count

  return (
    <div className="p-3 text-center">
      <p className="my-3 pb-3 free-trip-title">{t('pages.trips.free_custom_trips.title')}</p>
      <div className="position-relative trip-progress-container mx-auto">
        <div className="d-flex justify-content-between align-items-center">
          {
            [...Array(freeRTrips)].map((claimedTrip, index) => {
              return (
                <div
                  className={`rounded-icon ${index < claimedTrips ? 'checked' : 'unchecked'} d-flex align-items-center justify-content-center`}
                  key={index}
                >
                  {
                    (index + 1) <= claimedTrips ? <FontAwesomeIcon si className="text-white" icon={faCheck}/> : null
                  }
                </div>
              )
            })
          }
        </div>
        <div className="d-flex trip-progress">
          {
            [...Array(freeRTrips - 1)].map((claimedTrip, index) => {
              return (
                <div
                  className={`bg-primary trip-progress-line ${index < (claimedTrips - 1) ? 'border-primary' : 'border-gray-400'}`}
                  style={{ width: (100 / (freeRTrips - 1)) + '%' }}></div>
              )
            })
          }
        </div>
      </div>
      <p className="my-3 pt-3 free-trip-progress-text">
        {t('pages.trips.free_custom_trips.claimed_trip', { count: claimedTrips })}
      </p>
    </div>
  )
}

export default FreeCustomTripsProgress