import {useAuth} from "@/services/Auth";
import React, {useState} from "react";
import Main from "@/layouts/Main";
import {BrowserRouter as Router} from "react-router-dom";

function Layout() {
  let auth = useAuth();
  return (
    <Router>
      <div className={`app`}>
        <Main/>
      </div>
    </Router>
  )
}

export default Layout;
