import {useQuery} from 'react-query';
import {useAuth} from "../services/Auth";

function useGet(url, params) {
  const auth = useAuth();

  let totalRows = 0;

  const {data, status} = useQuery(url, () => {
    return auth.getRequest(url, params).then(response => {
      totalRows = response.data?.length;
      return response.data?.data ?? response.data;
    }).catch(error => error);
  });

  return {data, totalRows, loading: status === 'loading'};
}

export default useGet;