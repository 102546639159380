import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import Paginate from '../Paginate'
import LOCATION from '@/constants/Location'
import moment from 'moment'

function useColumns (filter, type, dealWeek) {
  const { t } = useTranslation()

  let columns = [
    type === 'deals' &&
    {
      dataField: 'id',
      text: t('common.id'),
      formatter: (cell, row) => {
        if( dealWeek ){
          return <Link to={`${LOCATION.DEALS.LIST.path}/${row?.id}/${dealWeek?.id}`} className="table-links text-nowrap">{cell}</Link>
        }
        return <Link to={`${LOCATION.DEALS.LIST.path}/${row?.id}`} className="table-links text-nowrap">{cell}</Link>
      },
    },
    type === 'deals' &&
    {
      dataField: 'user',
      text: t('common.user'),
      formatter: (cell, row) => {
        return <Link to={`${LOCATION.USERS.LIST.path}/${cell?.id}`} className="table-links text-nowrap">{cell?.name}</Link>
      },
    },
    type === 'deals' &&
    {
      dataField: 'trip_request.how_long',
      text: t('common.duration'),
      formatter: (cell, row) => {

        return <span>{t('common.duration_in_days', {numberOfDays: ( moment(row.to).diff(moment(row.from), 'days') )})}</span>
      },
    },
    type === 'deals' ?
    {
      dataField: 'dates',
      text: t('common.dates'),
      formatter: (cell, row) => {
        return (
          <span className="text-nowrap">
            {moment(row.date).format(t('common.formats.short_date'))}-
            {moment(row.flight.inbound_flight.departure_time).format(t('common.formats.short_date'))}
          </span>
        )
      },
    }:
    {
      dataField: 'dates',
      text: t('common.dates'),
      formatter: (cell, row) => {
        return (
          <span className="text-nowrap">
            {moment(row.date).format(t('common.formats.short_date'))}-
            {moment(row.end_date).format(t('common.formats.short_date'))}
          </span>
        )
      },
    },
    {
      dataField: 'location',
      text: t('pages.locations.location'),
      formatter: (cell, row) => {
        if( type === 'deals' ){
          return <span className="text-nowrap">{cell.destination}</span>
        }
        return <Link to={`${LOCATION.TRIPS.LIST.path}/${row?.id}`} className="table-links text-nowrap">{cell.destination}</Link>
      },
    },
    type === 'deals' &&
    {
      dataField: 'airport.name',
      text: t('common.airport'),
      formatter: (cell, row) => {
        return (
          <span className="text-nowrap">{cell}</span>
        )
      },
    },
    {
      dataField: 'hotel.name',
      text: t('common.hotel'),
      formatter: (cell, row) => {
        return (
          <span className="text-nowrap">{cell}</span>
        )
      },
    },
    {
      dataField: 'total_price',
      text: t('common.total_price_heading'),
      formatter: (cell, row) => <span className="text-nowrap"> {t('common.total_price_from_short',  {currency: row.currency_symbol, price: row.total_amount})}</span>,
    },
    {
      dataField: 'created_at',
      text: t('common.date_created'),
      formatter: (cell, row) => <span> {moment(cell).format(t('common.formats.short_date'))}</span>,
    },
  ].filter(Boolean)

  return filter && columns.filter(filter) || columns
}

function Users ({ data: recordsProp, paginate, url, query, type, dealWeek, ...props }) {
  let auth = useAuth()
  const { t } = useTranslation()

  const perPage = query.limit || 20
  const exclude = props.exclude || [{dataField: auth.user.id}]
  const showPagination = paginate == null ? true : paginate

  const request = useRef(null)
  const [page, setPage] = useState(0)
  const [totalRecords, setTotal] = useState(false)
  const [recordsDownloaded, setRecords] = useState(false)

  const getRecords = () => {
    request?.current?.abort && request.current.abort()

    const critera = { ...(query || {}) }

    critera.take = perPage
    critera.skip = page * perPage

    request.current = auth.getRequest(url || LOCATION.TRIPS.API.path, critera)

    setRecords(false)

    request.current
      .then(response => {
        setRecords(response.data.data)
        setTotal(response.data.length)
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name), type, dealWeek)

  const changePage = (event) => {
    setPage(event.selected)
  }

  const records = recordsProp || recordsDownloaded

  useEffect(() => {
    if (!recordsProp) {
      getRecords()
    }

    return () => request?.current?.abort && request.current.abort()
  }, [page, query, url])

  useEffect(() => {
    props.onData && props.onData(records)
  }, [records])

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords)
  }, [totalRecords])

  return (
    <div className="list-table">
      <BootstrapTable
        keyField="id"
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('common.no_data') : t('common.loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{ changePage, totalRecords, page, perPage }} />
        || ''
      }
    </div>
  )
}

export default Users

export {
  useColumns
}
