import TripOverview from './DealSelectionProcess/Steps/TripOverview'
import ChooseAccommodation from './DealSelectionProcess/Steps/ChooseAccommodation'
import ChooseFlight from './DealSelectionProcess/Steps/ChooseFlight'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'

function Deal () {

  const { t } = useTranslation()
  const auth = useAuth()
  const history = useHistory()
  const route = useLocation()

  const { id, week_id } = useParams()

  const [deal, setDeal] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentView, setCurrentView] = useState('trip_overview')
  const [chosenValues, setChosenValues] = useState(route.state && route.state.chosenValues || {
    hotel_price: 0,
    flights_price: 0
  })

  const [fetchingTrip, setFetchingTrip] = useState(true)
  const [tripExists, setTripExist] = useState(false)

  const getDeal = () => {
    return auth.getRequest(`${LOCATION.DEALS.API.path}/${id}`)
      .then(response => {
        setDeal(response.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const setSelections = (values) => {
    setChosenValues({ ...chosenValues, ...values })
  }

  useEffect(() => {
    let request = getDeal()

    return () => request?.abort && request.abort()
  }, [])



  const getTrip = () => {
    return auth.getRequest(LOCATION.TRIPS.API.path, {
      deal_id: deal.id,
      flight_id: deal.flight_id,
      hotel_id: deal.hotel_id,
      user_id: deal.user_id,
    }).then(response => {
      if (response.data?.data?.length !== 0) {
        setTripExist(true)
      }
      setFetchingTrip(false)
    })
  }

  useEffect(() => {
    if( deal ){
      let request = getTrip()
      return () => request?.abort && request.abort()
    }
  }, [deal])

  if (loading || fetchingTrip) return <LoadingCover/>

  return (
    <>
      <TripOverview
        deal={deal}
        dealWeekId={week_id}
        tripExists={tripExists}
      />
    </>
  )
}

export default Deal