import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@/shared/components/LoadingButton'
import LOCATION from '@/constants/Location'
import useGet from '@/hooks/useGet'
import LoadingCover from '@/shared/components/LoadingCover'

function UserForm ({ user, updateUser, savingUser, serverErrors, submitLabel, admin }) {
  const { t } = useTranslation()

  const { data: roles, loadingRoles } = useGet(LOCATION.ROLES.API.path)

  const [adminRole, setAdminRole] = useState(1)

  const schema = yup.object().shape({
    first_name: yup.string()
      .required(t('common.form_validation.first_name_is_required')),
    last_name: yup.string()
      .required(t('common.form_validation.last_name_is_required')),
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.is_required', { attribute: t('common.form.email') })),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
      }
    })(),
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    let roleId = roles?.find(_ => _.name_key === 'Admin')?.id
    if (roleId) {
      setAdminRole(roleId)
    }
  }, [roles])

  if (loadingRoles) return <LoadingCover/>

  return (
    <>
      <Form className="location-form" noValidate onSubmit={handleSubmit(updateUser)}>
        {
          admin ?
          <input
            type="hidden"
            value={adminRole}
            {...register('role_id')}
          /> : null
        }
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('common.form.first_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.first_name') })}
            {...register('first_name')}
            isInvalid={!!errors.first_name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.first_name && errors.first_name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.last_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.last_name') })}
            {...register('last_name')}
            isInvalid={!!errors.last_name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.last_name && errors.last_name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.email')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.email') })}
            {...register('email')}
            isInvalid={!!errors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>

        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-4">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold"
                   key={index}>{t(error)}</p>)
            }
          </div>
        }
        <Form.Group className="d-grid gap-2 mt-5">
          <LoadingButton
            className="auth-btn text-white px-5 heading-text text-uppercase"
            loading={savingUser}
            variant="primary"
            titleTranslationKey={submitLabel}
            type="submit"
          />
        </Form.Group>
      </Form>
    </>
  )

}

export default UserForm