import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import PageHeader from '@/layouts/components/PageHeader'
import { Button, Form } from 'react-bootstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import LoadingButton from '@/shared/components/LoadingButton'
import LOCATION from '@/constants/Location'
import { useParams } from 'react-router-dom'
import AppModal from '@/shared/components/AppModal'
import { getValidationErrors } from '@/utility'

function EditProfile () {

  const { t } = useTranslation()
  const auth = useAuth()

  const { email, code } = useParams()

  const [step, setStep] = useState('form')
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(!!(email && code))
  const [newEmail, setNewEmail] = useState(email)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [confirmText, setConfirmText] = useState(t('common.dialogs.actions.okay'))
  const [serverErrors, setServerErrors] = useState([])

  const schema = yup.object().shape({
    email: emailSent ? yup.string().nullable() : yup.string().email()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.email') })),
    code: !emailSent ? yup.string().nullable() : yup.number()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.confirmation_code') })),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        email: auth?.user?.email,
        code: code,
      }
    })(),
    resolver: yupResolver(schema),
  })

  const onSubmit = (values) => {
    setLoading(true)
    if (emailSent) {
      validateAndUpdate(values)
    } else {
      setNewEmail(values.email)
      auth.putRequest(`${LOCATION.PROFILE.API.path}/${auth.user.id}/edit-profile`, values)
        .then(response => {
          setLoading(false)
          setEmailSent(true)
        })
        .catch(error => {
          setLoading(false)
          setServerErrors(getValidationErrors(error))
        })
    }

  }

  const validateAndUpdate = (values) => {
    setLoading(true)
    auth.postRequest(`${LOCATION.PROFILE.API.path}/${auth.user.id}/validate-email-code`, {
      email: newEmail,
      code: values.code,
    })
      .then(response => {
        setLoading(false)
        setModalTitle(t('pages.profile.notifications.email_updated.title'))
        setShowModal(true)
      })
      .catch(error => {
        setLoading(false)
        setServerErrors(getValidationErrors(error))
      })
  }

  const handleConfirm = () => {
    setShowModal(false)

    window.location.href = LOCATION.PROFILE.EDIT.path
  }


  return (
    <>

      <AppModal
        show={showModal}
        title={modalTitle}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />

      <PageHeader
        title={t('pages.profile.edit_profile_form')}
      />

      <div className="container">

        {
          (serverErrors.length !== 0) &&
          <div className="form-group mt-4 text-center">
            {
              serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
            }
          </div>
        }

        {
          !emailSent ?

            <Form className="location-form mx-auto mt-5" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="my-4" controlId="formBasicEmail">
                <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                  {t('common.form.email')}
                  <span className="text-danger ms-2 small">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.email') })}
                  {...register('email')}
                  isInvalid={!!errors.email}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-4 d-grid">
                <LoadingButton
                  loading={loading}
                  variant="dark"
                  className="heading-text"
                  titleTranslationKey={t('common.buttons.submit')}
                />
              </Form.Group>
            </Form>
            :
            <Form className="location-form mx-auto mt-5" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="my-4">
                <p className="text-success">{t('pages.profile.verification_code_sent')}</p>
              </Form.Group>
              <Form.Group className="my-4" controlId="formBasicEmail">
                <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                  {t('common.form.confirmation_code')}
                  <span className="text-danger ms-2 small">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.confirmation_code') })}
                  {...register('code')}
                  isInvalid={!!errors.code}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.code && errors.code.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-4 d-grid">
                <LoadingButton
                  loading={loading}
                  variant="dark"
                  type="submit"
                  className="heading-text"
                  titleTranslationKey={t('pages.profile.buttons.validate_and_update')}
                />
              </Form.Group>
            </Form>
        }
      </div>
    </>
  )
}

export default EditProfile