import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import CountrySearch from './CountrySearch'
import React, { useState } from 'react'
import RegionSearch from './RegionSearch'
import LOCATION from '@/constants/Location'
import useGet from '@/hooks/useGet'
import LoadingCover from '@/shared/components/LoadingCover'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import LoadingButton from '@/shared/components/LoadingButton'
import FeaturedImageUpload from './FeaturedImageUpload'
import SubLocationForm from './SubLocationForm'
import AppModal from '@/shared/components/AppModal'
import { isNull } from 'lodash'
import AirportSelection from '@/pages/Trips/Request/components/AirportSelection'
import { formatRecord } from '@/utility'
import MonthsSelection from "../../../Trips/Request/components/MonthsSelection";

function LocationForm ({
  location,
  saveLocation,
  submitLabel,
  submitting,
  serverErrors,
  deleting,
  deleteLocationRequest
}) {

  const { t } = useTranslation()
  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL

  const { data: locationFeatures, loading: loadingFeatures } = useGet(LOCATION.LOCATION_FEATURES.API.path)
  const { data: holidayTypes, loading: loadingHolidays } = useGet(LOCATION.HOLIDAY_TYPES.API.path)

  const [selectedFeatures, setSelectedFeatures] = useState(location?.location_features?.map(_ => {
    return {
      value: _.id,
      label: _.name
    }
  }) ?? [])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(t('common.dialogs.actions.okay'))
  const [cancelText, setCancelText] = useState(t('common.dialogs.actions.cancel'))

  const [indexToRemove, setIndexToRemove] = useState(null)
  const [subLocationToDelete, setSubLocationToDelete] = useState(null)

  const {data:months, loading:loadingMonths} = useGet(LOCATION.MONTHS.API.path);

  const schema = yup.object().shape({
    name: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.name') })),
    country_id: yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .required(t('common.form_validation.is_required', { attribute: t('common.form.country') })),

    months: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_month'))
      .of(yup.number()
        .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.locations.form.labels.months') })),
      ),
    description: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.description') })),
    location_definition: yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.location_definition') })),

    airports: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.locations.form.labels.airports') })),
        label: yup.string(),
      })),

    featured_image: yup.mixed()
      .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.featured_image') })),
    location_features: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_location_feature'))
      .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.features_message') })),
    holiday_types: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_holiday_type'))
      .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.holiday_types') })),
    longitude: yup.number()
      .typeError(t('common.form_validation.must_be_a_number', { attribute: t('pages.locations.form.labels.longitude') }))
      .transform((value) => (isNaN(value) ? null : value))
      .when(['location_definition'], function (location_definition, schema) {
        return location_definition == '1' ?
          schema.required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.longitude') }))
          :
          schema.nullable()
      }),
    latitude: yup.number()
      .typeError(t('common.form_validation.must_be_a_number', { attribute: t('pages.locations.form.labels.latitude') }))
      .transform((value) => (isNaN(value) ? null : value))
      .when(['location_definition'], function (location_definition, schema) {
        return location_definition == '1' ?
          schema.required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.latitude') }))
          :
          schema.nullable()
      }),
    radius: yup.number()
      .typeError(t('common.form_validation.must_be_a_number', { attribute: t('pages.locations.form.labels.radius') }))
      .transform((value) => (isNaN(value) ? null : value))
      .when(['location_definition'], function (location_definition, schema) {
        return location_definition == '1' ?
          schema.required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.radius') }))
          :
          schema.nullable()
      }),
    sub_locations: yup.array()
      .when(['location_definition'], function (location_definition, schema) {
        return location_definition == '4' ?
          schema.min(1, t('pages.locations.form.labels.define_one_sub_location'))
            .of(yup.object().shape({
              name: yup.string()
                .transform((value) => (isNull(value) ? '' : value))
                .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.sub_location_name') })),
              longitude: yup.number()
                .typeError(t('common.form_validation.must_be_a_number', { attribute: t('pages.locations.form.labels.longitude') }))
                .transform((value) => (isNaN(value) ? null : value))
                .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.longitude') })),
              latitude: yup.number()
                .typeError(t('common.form_validation.must_be_a_number', { attribute: t('pages.locations.form.labels.latitude') }))
                .transform((value) => (isNaN(value) ? null : value))
                .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.latitude') })),
              radius: yup.number()
                .typeError(t('common.form_validation.must_be_a_number', { attribute: t('pages.locations.form.labels.radius') }))
                .transform((value) => (isNaN(value) ? null : value))
                .required(t('common.form_validation.is_required', { attribute: t('pages.locations.form.labels.radius') })),
            }))
          :
          schema.nullable()
      }),

    destination_specific_deal_months: yup.array()
        .of(yup.number()
            .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.locations.form.labels.months') })),
        ),
  })

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        id: location?.id,
        name: location?.name,
        page: location?.page,
        description: location?.description,
        country_id: location?.country_id,
        location_definition: location?.location_definition ?? 1,
        location_keyword: location?.location_keyword,
        region_id: location?.region_id,
        airports: location?.airports?.map(formatRecord),
        longitude: location?.longitude,
        latitude: location?.latitude,
        featured_image: location?.featured_image,
        radius: location?.radius,
        direct_flights_only: location?.direct_flights_only,
        holiday_types: location?.holiday_types?.map(_ => `${_.id}`) ?? [],
        location_features: location?.location_features?.map(_ => `${_.id}`) ?? [],
        sub_locations: location?.sub_locations,
        months: location?.months?.map(_ =>  `${_.id}`),

        // Destination Specific Deal Settings
        destination_specific_deal_duration: location?.destination_specific_deal_duration,
        destination_specific_deal_months: location?.destination_specific_deal_months?.map(_ =>  `${_.id}`),
        destination_specific_deal_min_budget: location?.destination_specific_deal_min_budget,
        destination_specific_deal_max_budget: location?.destination_specific_deal_max_budget,
      }
    })(),
    resolver: yupResolver(schema),
  })

  const locationDefinition = watch('location_definition')
  const selectedHolidayTypes = watch('holiday_types')

  const { fields, append, prepend, remove, swap, move, insert, replace } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'sub_locations', // unique name for your Field Array
  })

  const addSubLocation = () => {
    append({ name: null, longitude: null, latitude: null, radius: null })
  }

  const removeSubLocation = (subLocation, index) => {
    if (subLocation.sub_location_id) {
      setIndexToRemove(index)
      setSubLocationToDelete(subLocation)
      setModalTitle(t('pages.locations.notifications.remove_sub_location.title'))
      setModalBody(t('pages.locations.notifications.remove_sub_location.body'))
      setShowModal(true)
    } else {
      remove(index)
    }
  }

  const deleteSubLocation = async () => {
    remove(indexToRemove)
    await auth.deleteRequest(`${LOCATION.DESTINATIONS.API.path}/${subLocationToDelete.sub_location_id}`)
  }

  const handleConfirm = () => {
    if (subLocationToDelete) {
      deleteSubLocation()
    }

    setShowModal(false)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  if (loadingFeatures || loadingHolidays || loadingMonths) {
    return <LoadingCover/>
  }


  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Form className="location-form" noValidate onSubmit={handleSubmit(saveLocation)}>
        <Form.Group className="my-4">
          <Form.Label>
            {t('pages.locations.form.labels.name')}
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.name') })}
            {...register('name')}
            isInvalid={!!errors.name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Group>
            <Form.Label>
              {t('common.form.country')}
              <span className="text-danger">*</span>
            </Form.Label>

            <CountrySearch
              country={location?.country}
              placeHolder={t('common.search')}
              setValue={setValue}
            />

            {
              (errors.country_id && errors.country_id.message) ?
                <div className="invalid-feedback d-block">{errors.country_id.message}</div> : null
            }
          </Form.Group>
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Group>
            <Form.Label>
              {t('pages.locations.form.labels.location_definition')}
            </Form.Label>

            <Form.Control
              as="select"
              placeholder={t('common.form.placeholder_choose', { attribute: t('pages.locations.form.labels.location_definition') })}
              {...register('location_definition')}
              isInvalid={!!errors.location_definition}
            >
              <option value={1}>{t('pages.locations.location_definitions.gps')}</option>
              {/*<option value={2}>{t('pages.locations.location_definitions.keyword_search')}</option>*/}
              <option value={3}>{t('pages.locations.location_definitions.region_id')}</option>
              <option value={4}>{t('pages.locations.location_definitions.sub_locations')}</option>
            </Form.Control>

            <Form.Control.Feedback type="invalid">
              {errors.location_definition && errors.location_definition.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Group>
        {
          locationDefinition == 3 ?
            <p className="fst-italic"><span className="text-danger">*</span>{t('pages.locations.form.labels.region_ids_are_defined_by_airports')}</p> : null
        }
        {
          locationDefinition == 1 ?
            <>
              <Row className="my-4">
                <Form.Group className="col col-12 col-md-4">
                  <Form.Label>
                    {t('pages.locations.form.labels.longitude')}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="mb-3 mb-md-1"
                    placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.longitude') })}
                    {...register('longitude')}
                    isInvalid={!!errors.longitude}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.longitude && errors.longitude.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col col-12 col-md-4">
                  <Form.Label>
                    {t('pages.locations.form.labels.latitude')}
                    <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    className="mb-3 mb-md-1"
                    placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.latitude') })}
                    {...register('latitude')}
                    isInvalid={!!errors.latitude}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.latitude && errors.latitude.message}
                  </Form.Control.Feedback>
                </Form.Group>


                <Form.Group className="col col-12 col-md-4">
                  <Form.Label>
                    {t('pages.locations.form.labels.radius')}
                    <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    className="mb-3 mb-md-1"
                    placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.radius') })}
                    {...register('radius')}
                    isInvalid={!!errors.radius}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.radius && errors.radius.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </> : null
        }
        {
          locationDefinition == 2 ?
            <Form.Group className="my-4">
              <Form.Label>
                {t('pages.locations.form.labels.location_keyword')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.location_keyword') })}
                {...register('location_keyword')}
                isInvalid={!!errors.location_keyword}
              />

              <Form.Control.Feedback type="invalid">
                {errors.location_keyword && errors.location_keyword.message}
              </Form.Control.Feedback>
            </Form.Group> : null
        }
        {
          locationDefinition == 4 ?
            <Form.Group className="my-4">
              <Form.Label>
                {t('pages.locations.form.labels.sub_locations')}
              </Form.Label>
              {
                fields.length !== 0 ?

                  fields.map((subLocation, index) =>
                    <>
                      <SubLocationForm
                        index={index}
                        subLocation={subLocation}
                        key={subLocation.id}
                        namePrefix={`sub_locations[${index}]`}
                        register={register}
                        errors={errors?.sub_locations?.[index] ?? {}}
                        remove={removeSubLocation}
                      />
                    </>
                  )
                  : null
              }

              <div className="d-grid gap-2">
                <Button
                  onClick={() => addSubLocation()}
                  className="heading-text"
                >
                  {t('pages.locations.buttons.add_sub_location')}
                </Button>
              </div>
            </Form.Group>
            :null
        }
        <Form.Group className="my-4">
          <Form.Group>
            <Form.Label>
              {t('pages.locations.form.labels.airports')}
              <span className="text-danger">*</span>
            </Form.Label>

            <AirportSelection
              errors={errors}
              unregister={unregister}
              register={register}
              reset={reset}
              setValue={setValue}
              fieldName="airports"
              selectedAirports={location?.airports?.map(formatRecord)}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="my-4" controlId="formBasicDate">
          <Form.Check
            inline
            label={t('pages.locations.form.labels.direct_flights_only')}
            name="direct_flights_only"
            type="switch"
            {...register('direct_flights_only')}
          />
        </Form.Group>
        <Form.Group controlId="formFile" className="">
          <Form.Label>
            {t('common.form.featured_image')}
            <span className="text-danger">*</span>
          </Form.Label>

          <FeaturedImageUpload
            errors={errors}
            setValue={setValue}
            register={register}
            fieldName="featured_image"
            location={location}
          />
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label>
            {t('common.form.description')}
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.description') })}
            {...register('description')}
            isInvalid={!!errors.description}
          />

          <Form.Control.Feedback type="invalid">
            {errors.description && errors.description.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Group>
            <Form.Label>
              {t('pages.locations.form.labels.months')}
              <span className="text-danger">*</span>
            </Form.Label>
            <Row>
              {
                months.map((month) => {
                  return (
                    <Col className="col-lg-3 col-sm-4 col-6 my-2" key={month.id}>
                      <Form.Check
                        inline
                        label={month.name}
                        id={`month-${month.id}`}
                        name="months"
                        value={`${month.id}`}
                        {...register('months')}
                      />
                    </Col>
                  )
                })
              }
            </Row>
            {
              (errors.months && errors.months.message) &&
              <div className="invalid-feedback d-block">{errors.months.message}</div>
            }
          </Form.Group>
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label>
            {t('pages.locations.form.labels.features')}
            <span className="text-danger">*</span>
          </Form.Label>

          <CreatableSelect
            className="w-100 react-select"
            isMulti
            defaultValue={selectedFeatures}
            isClearable={true}
            isOptionDisabled={(option) => selectedFeatures.length >= 3}
            onChange={(e) => {
              setSelectedFeatures(e)
              setValue('location_features', e?.map(_ => _.value))
            }}
            options={locationFeatures.map(_ => { return { value: _.id, label: _.name } })}
          />

          {
            (errors.location_features && errors.location_features.message) ?
              <div className="invalid-feedback d-block">{errors.location_features.message}</div> : null
          }
        </Form.Group>
        <Form.Group className="my-4">
          <Form.Label className="d-block">
            {t('pages.locations.form.labels.holiday_types')}
            <span className="text-danger">*</span>
          </Form.Label>

          {
            holidayTypes.map(holidayType => {
              return (
                <Form.Check
                  inline
                  label={holidayType.name}
                  name="holiday_types"
                  value={holidayType.id}
                  type="checkbox"
                  {...register('holiday_types')}
                />
              )
            })
          }

          {
            (errors.holiday_types && errors.holiday_types.message) ?
              <div className="invalid-feedback d-block">{errors.holiday_types.message}</div> : null
          }
        </Form.Group>

        <Form.Group className="my-4 border rounded p-3">
          <Form.Label className="d-block">
            {t('pages.locations.destination_specific_deal_settings_title')}
          </Form.Label>

          <Form.Group className="my-4">
            <Form.Label>
              {t('pages.locations.form.labels.page')}
            </Form.Label>
            <Form.Control
                placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.page') })}
                {...register('page')}
                isInvalid={!!errors.page}
            />

            <Form.Control.Feedback type="invalid">
              {errors.page && errors.page.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-4">
            <Form.Label>
              {t('pages.locations.destination_specific_deal_settings.form.labels.duration')}
            </Form.Label>
            <Form.Control
                type="number"
                {...register('destination_specific_deal_duration')}
                isInvalid={!!errors.destination_specific_deal_duration}
            />

            <Form.Control.Feedback type="invalid">
              {errors.destination_specific_deal_duration && errors.destination_specific_deal_duration.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-4">
            <Form.Label>
              {t('pages.locations.form.labels.min_budget')}
            </Form.Label>
            <Form.Control
                type="number"
                step="0.01"
                min="0"
                placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.min_budget') })}
                {...register('destination_specific_deal_min_budget')}
                isInvalid={!!errors.destination_specific_deal_min_budget}
            />

            <Form.Control.Feedback type="invalid">
              {errors.destination_specific_deal_min_budget && errors.destination_specific_deal_min_budget.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-4">
            <Form.Label>
              {t('pages.locations.form.labels.max_budget')}
            </Form.Label>
            <Form.Control
                type="number"
                step="0.01"
                min="0"
                placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.max_budget') })}
                {...register('destination_specific_deal_max_budget')}
                isInvalid={!!errors.destination_specific_deal_max_budget}
            />

            <Form.Control.Feedback type="invalid">
              {errors.destination_specific_deal_max_budget && errors.destination_specific_deal_max_budget.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              {t('pages.locations.destination_specific_deal_settings.form.labels.months')}
            </Form.Label>
            <Row>
              {
                months.map((month) => {
                  return (
                      <Col className="col-lg-3 col-sm-4 col-6 my-2" key={month.id}>
                        <Form.Check
                            inline
                            label={month.name}
                            id={`destination_specific_deal_months-${month.id}`}
                            name="destination_specific_deal_months"
                            value={`${month.id}`}
                            {...register('destination_specific_deal_months')}
                        />
                      </Col>
                  )
                })
              }
            </Row>
            {
                (errors.destination_specific_deal_months && errors.destination_specific_deal_months.message) &&
                <div className="invalid-feedback d-block">{errors.destination_specific_deal_months.message}</div>
            }
          </Form.Group>
        </Form.Group>

        {
          (serverErrors.length !== 0) &&
          <div className="form-group mt-4">
            {
              serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error}</p>)
            }
          </div>
        }
        <Form.Group className="my-4">

          {
            location &&
            <LoadingButton
              loading={deleting}
              type="button"
              variant="danger"
              onSubmit={deleteLocationRequest}
              className="text-white me-2 px-5 heading-text"
              titleTranslationKey="pages.locations.buttons.delete_location"
            />
          }
          <LoadingButton
            loading={submitting}
            className="text-white px-5 heading-text"
            titleTranslationKey={submitLabel}
          />
        </Form.Group>

      </Form>
    </>
  )

}

export default LocationForm