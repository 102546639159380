import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import Paginate from '../Paginate'
import LOCATION from '@/constants/Location'
import moment from 'moment'

function useColumns (filter) {
  const { t } = useTranslation()

  let columns = [
    {
      dataField: 'name',
      text: t('common.form.name'),
      formatter: (cell, row) => {
        return <Link to={`${LOCATION.USERS.LIST.path}/${row?.id}`} className="table-links text-nowrap">{cell}</Link>
      },
    },
    {
      dataField: 'email',
      text: t('common.form.email'),
    },
    {
      dataField: 'airports',
      text: t('common.form.preferred_airport'),
      formatter: (cell, row) => {
        return cell?.map(_ => _.name).join(',')
      },
    },
    {
      dataField: 'user_type',
      text: t('pages.users.form.labels.user_type'),
      formatter: (cell, row) => {
        return <span>{t(`pages.users.user_types.${cell}`)}</span>
      },
    },
    {
      dataField: 'subscription.valid_until',
      text: t('common.expires'),
      formatter: (cell, row) => {
        return cell ? <span>{moment(cell).format(t('common.formats.short_date'))}</span> : null
      },
    },
  ]

  return filter && columns.filter(filter) || columns
}

function Users ({ data: recordsProp, paginate, url, query, setQuery, ...props }) {
  let auth = useAuth()
  const { t } = useTranslation()

  const perPage = query.limit || 20
  const exclude = props.exclude || [{dataField: auth.user.id}]
  const showPagination = paginate == null ? true : paginate

  const request = useRef(null)
  const [page, setPage] = useState(0)
  const [totalRecords, setTotal] = useState(false)
  const [recordsDownloaded, setRecords] = useState(false)

  const getRecords = () => {
    request?.current?.abort && request.current.abort()

    const critera = { ...(query || {}) }

    critera.take = perPage
    critera.skip = page * perPage

    request.current = auth.getRequest(url || LOCATION.USERS.API.path, critera)

    setRecords(false)

    request.current
      .then(response => {
        let recordsData  = response.data.data;
        if(  query?.sort_by === 'expire_first'){
          let recordWithSubscription = recordsData.filter(_ => _?.subscription?.valid_until);
          let recordWithoutSubscription = recordsData.filter(_ => !_?.subscription?.valid_until);

          recordWithSubscription.sort((a,b) => new Date(a?.subscription?.valid_until).getTime() - new Date(b?.subscription?.valid_until).getTime());
          recordsData = [...recordWithSubscription, ...recordWithoutSubscription ];
        }
        setRecords(recordsData)
        setTotal(response.data.length)
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name))

  const changePage = (event) => {
    setPage(event.selected)
  }

  const records = recordsProp || recordsDownloaded

  useEffect(() => {
    if (!recordsProp && !query.reset) {
      getRecords()
    }
    if( query.reset ){
      setPage(0);
      setQuery({...query, reset: false})
    }

    return () => request?.current?.abort && request.current.abort()
  }, [page, query, url])

  useEffect(() => {
    props.onData && props.onData(records)
  }, [records])

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords)
  }, [totalRecords])

  return (
    <div className="list-table">
      <BootstrapTable
        keyField="id"
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('common.no_data') : t('common.loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{ changePage, totalRecords, page, perPage }} />
        || ''
      }
    </div>
  )
}

export default Users

export {
  useColumns
}
