import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import React, { useEffect, useState } from 'react'
import LoadingCover from '@/shared/components/LoadingCover'
import TripTable, { useColumns } from '@/shared/Tables/Trips'
import { Breadcrumb, Button, Col, Row } from 'react-bootstrap'
import UserAvatar from '@/shared/components/UserAvatar'
import moment from 'moment'
import ManageSubscription from './components/ManageSubscription'
import { Helmet } from 'react-helmet'
import ManageUser from './components/ManageUser'
function Show () {

  const { t } = useTranslation()
  const auth = useAuth()

  const { id } = useParams()

  const [user, setUser] = useState(null)
  const history = useHistory()
  const route = useLocation()

  const [tripsQuery, setTripsQuery] = useState(route.state && route.state.query || {
    page: 0,
    skip: 0,
    limit: 15,
    user_id: null,
  })

  const [searching, setSearching] = useState(false)

  const getUser = () => {
    return auth.getRequest(`${LOCATION.USERS.API.path}/${id}`)
      .then(response => {
        setUser(response.data?.data)
        let query = { ...tripsQuery }
        query.user_id = response.data?.data?.id
        setTripsQuery(query)
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    let request = getUser()

    return () => request?.abort && request.abort()
  }, [])

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        tripsQuery,
      }
    )
  }, [tripsQuery])

  if (!user) return <LoadingCover/>
  return (
    <>
      <Helmet>
        <title>{user.name} - {t('pages.users.title')} - {t('common.app')}</title>
      </Helmet>

      <Row className="h-100">
        <Col className="col-12 col-md-9 p-4">
          <div className="d-flex justify-content-between">
            <Breadcrumb className="text-dark heading-text">
              <Breadcrumb.Item className="">
                <Link to={LOCATION.USERS.LIST.path}>{t('pages.users.title')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{user.name}</Breadcrumb.Item>
            </Breadcrumb>
            <UserAvatar
              size="64px"
              avatarLink={user.avatar}
            />
          </div>
          <div className="my-4">
            <h5 className="heading-text my-3">{t('pages.trips.title')}</h5>
            <TripTable query={tripsQuery} type="trip" showPagination={true} onData={_ => setSearching(!_)}/>
          </div>
        </Col>
        <Col className="col-12 col-md-3 user-info-panel h-100 p-4 ">
          <div className="d-grid gap-2 mb-4">
            <Link to={`${LOCATION.USERS.LIST.path}/${user.id}/edit`}
                  className="btn btn-dark heading-text">{t('pages.users.buttons.edit_details')}</Link>

            {
              user.id !== 1 &&
              <ManageUser user={user}/>
            }
          </div>
          <p className="fw-bold my-2">{user.name}</p>
          <p className="fw-bold my-2">{user.email}</p>
          <hr/>

          <p className="mb-1">{t('pages.users.form.labels.user_type')}</p>
          <p className="fw-bold mb-2">{t(`pages.users.user_types.${user.user_type}`)}</p>

          {
            user.user_type === 'travel_max' ?
              <>
                <p className="mb-1 mt-4">{t('pages.users.form.labels.start_end_date')}

                  {
                    user.subscription.is_trial && <span className="text-danger">&nbsp;(Trial)</span>
                  }
                </p>

                <p className="fw-bold mb-2">

                  {moment(user.subscription.created_at).format(t('common.formats.short_date'))}&nbsp;-&nbsp;

                  {
                    user.subscription.is_trial ?
                      <span>
                        {moment(user.subscription.trial_ends_at).format(t('common.formats.short_date'))}
                      </span>
                      :
                      moment(user.subscription.valid_until).format(t('common.formats.short_date'))
                  }
                </p>

                <p className="mb-1 mt-4">{t('pages.users.form.labels.payment_type')}</p>
                <p
                  className="fw-bold mb-2">{user.subscription.auto_renew ? t(`pages.users.payment_types.auto_renew`):  t(`pages.users.payment_types.manual`)}</p>

              </>
              :
              <p className="text-danger my-4">{t('common.no_active_subscription')}</p>
          }

          <p className="mb-1 mt-4">{t('common.form.preferred_airport')}</p>
          <p className="fw-bold mb-4">{user.airports?.map(_ => _.name).join(', ')}</p>
          <ManageSubscription user={user}/>
        </Col>
      </Row>
    </>
  )

}

export default Show