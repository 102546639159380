import LOCATION from "../../../../constants/Location";
import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import {useTranslation} from "react-i18next";

function UsersSummary() {

  const auth = useAuth();
  const {t} = useTranslation();

  const [userStats, setUserStats] = useState(null);
  const getUserStats = () => {

    return auth.getRequest(`${LOCATION.USERS.API.path}/stats`)
      .then(response => {
        setUserStats(response.data);
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    let request = getUserStats();
    return () => request?.abort && request.abort();
  }, []);

  if (!userStats) return null;

  return (
    <div className="my-1 row">
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-cent justify-content-center flex-column">
              <h1>{userStats.users}</h1>
              <h6>{t('pages.users.title')}</h6>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-cent justify-content-center flex-column">
              <h1>{userStats.travel_max_users}</h1>
              <h6>{t('pages.users.travel_max_users')}</h6>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-cent justify-content-center flex-column">
              <h1>{userStats.travel_max_users_with_airports}</h1>
              <h6>{t('pages.users.travel_max_users_with_airports')}</h6>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-2">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-cent justify-content-center flex-column">
              <h1>{userStats.travel_max_users_with_destinations}</h1>
              <h6>{t('pages.users.travel_max_users_with_destinations')}</h6>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default UsersSummary;