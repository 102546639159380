import AuthLayout from "./AuthLayout";
import {useTranslation} from "react-i18next";
import {Form, Row} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import {useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import LOCATION from "@/constants/Location";
import LoadingButton from "@/shared/components/LoadingButton";
import { getValidationErrors } from '@/utility'

function ForgotPassword() {
  const {t} = useTranslation();

  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])
  const [resetEmailSent, setResetEmailSent] = useState(false)

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.email_address_is_required')),
  })

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  })


  const requestReset = (values) => {
    setServerError('')
    setLoading(true)
    auth.postRequest(`${LOCATION.AUTH.FORGOT_PASSWORD.path}/request`, values)
      .then(response => {
        setLoading(false)
        if (response.data.message === 'OK') {
          setResetEmailSent(true)
        }
      })
      .catch(error => {
        setLoading(false)
        setServerError(error.response?.message);
        setServerErrors(getValidationErrors(error))
      })
  }
  return (
    <AuthLayout
      title={t('pages.forgot_password.title')}
      subtitle={t('pages.forgot_password.instructions')}
      showNotification={resetEmailSent}
      notificationTitle={t('pages.forgot_password.success.title')}
      notificationDescription={t('pages.forgot_password.success.description')}
      notificationLink={LOCATION.ROOT.path}
      notificationLinkText={t('common.okay')}
    >
      <Form noValidate onSubmit={handleSubmit(requestReset)}>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder={t('common.form.placeholder_enter', {attribute: t('common.form.email')})}
            {...register('email')}
            isInvalid={!!errors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>
        {
          serverError !== '' &&
          <div className="form-group mb-2">
            <p className="text-danger py-1 mb-0">{t(serverError)}</p>
          </div>
        }
        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-2">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
            }
          </div>
        }
        <Form.Group className="">
          <div className="d-grid gap-2">
            <LoadingButton
              className="auth-btn text-white px-5 heading-text"
              loading={loading}
              variant="primary"
              titleTranslationKey="pages.forgot_password.reset"
              type="submit"
            />
          </div>
        </Form.Group>
      </Form>
    </AuthLayout>
  )
}

export default ForgotPassword;