import { Card, Form } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import StripeSetupForm from './StripeSetupForm'
import React from 'react'
import LoadingButton from '@/shared/components/LoadingButton'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import PaymentMethodInformation from '../../Profile/components/PaymentMethodInformation'

function PaymentMethodForm ({ processSubmit, savePaymentMethod, stripePromise, stripeSetupFormRef, errors, loading,source, buyTripPlan, serverError }) {

  const auth = useAuth();
  const { t } = useTranslation()

  const TripPlanPrice = 10

  return (
    <div className="container  mt-5" style={{ maxWidth: '600px' }}>
      <Card className="mt-2 border-0">
        <Form noValidate onSubmit={processSubmit(savePaymentMethod)}>
          <Card.Body class="p-0">
            {
              source === 'buy-trip' ?

                <Card.Title>{t('pages.payments.trip_method_form')}</Card.Title>
                :
                <Card.Title>{t('pages.payments.payment_method_form')}</Card.Title>
            }
            <div className="my-3">
              {
                source === 'buy-trip' && auth.user.payment_method ?
                  <>
                    <PaymentMethodInformation user={auth.user}/>
                    <div className="d-grid gap-2 mt-2">
                    </div>
                  </>
                  :
                  <Elements stripe={stripePromise}>
                    <StripeSetupForm ref={stripeSetupFormRef} errors={errors}/>
                  </Elements>
              }
              {serverError && <p className="text-danger my-3">{t(`pages.payments.${serverError}`)}</p>}
            </div>
            <div className="d-grid">
              {
                source === 'buy-trip' && auth.user.payment_method ?
                  <LoadingButton
                    className="auth-btn text-uppercase fw-bold text-primary heading-text"
                    loading={loading}
                    variant="dark"
                    size="lg"
                    onSubmit={buyTripPlan}
                    titleTranslationKey={t('pages.payments.buttons.buy_trip_plans', {
                      price: TripPlanPrice.toFixed(2),
                      currency: auth.user.currency.symbol
                    })}
                    type="button"
                  />
                  :
                  <LoadingButton
                    className="auth-btn text-uppercase fw-bold text-primary heading-text"
                    loading={loading}
                    variant="dark"
                    titleTranslationKey={
                      source === 'buy-trip' ?
                        t('pages.payments.buttons.buy_trip_plans', {
                          price: TripPlanPrice.toFixed(2),
                          currency: auth.user.currency.symbol
                        })
                        :
                        t('pages.payments.buttons.save_payment_method')
                    }
                    type="submit"
                  />
              }
            </div>
          </Card.Body>

        </Form>
      </Card>
    </div>
  )
}

export default PaymentMethodForm