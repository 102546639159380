const steps = [
  {
    name: 'budget',
    title: 'pages.trips.steps.budget.title',
    bg: 'request-trip-1.jpg',
    bg_sm: 'request-trip-1.jpg',
  },
  {
    name: 'holiday-type',
    title: 'pages.trips.steps.holiday_type.title',
    bg: null,
    bg_sm: 'bg-sm-2.jpg',
  },
  {
    name: 'where-from',
    title: 'pages.trips.steps.where_from.title',
    bg: 'request-trip-2.jpeg',
    bg_sm: 'request-trip-2.jpeg',
  },
  {
    name: 'where-to',
    title: 'pages.trips.steps.where_to.title',
    bg: 'request-trip-3.jpeg',
    bg_sm: 'request-trip-3.jpeg',
  },
  {
    name: 'who-is-going',
    title: 'pages.trips.steps.who_is_going.title',
    bg: 'request-trip-4.jpeg',
    bg_sm: 'request-trip-4.jpeg',
  },
  {
    name: 'when',
    title: 'pages.trips.steps.when.title',
    bg: 'request-trip-5.jpeg',
    bg_sm: 'request-trip-5.jpeg',
  },
  {
    name: 'summary',
    title: 'pages.trips.steps.summary.title',
    bg: 'request-trip-6.jpeg',
    bg_sm: 'request-trip-6.jpeg',
  },
  // {
  //   name: 'what-will-make-it-special',
  //   title: 'pages.trips.steps.what_will_make_it_special.title',
  //   bg: 'request-trip-12.jpeg',
  //   bg_sm: 'request-trip-12.jpeg',
  // },
  // {
  //   name: 'flight_options',
  //   title: 'pages.trips.steps.flight_options.title',
  //   bg: 'request-trip-6.jpeg',
  //   bg_sm: 'bg-sm-2.jpg',
  // },
]

export default steps