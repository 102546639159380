import {useAuth} from "../../../services/Auth";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import TripRequestsFilter from "./components/TripRequestsFilter";
import LOCATION from "../../../constants/Location";
import {Helmet} from "react-helmet";
import AdminPageHeader from "../../../layouts/components/AdminPageHeader";
import TripRequestsTable from "../../../shared/Tables/TripRequests";
import useGet from "../../../hooks/useGet";

function TripRequests() {
  const auth = useAuth()
  const history = useHistory()
  const route = useLocation()
  const {t} = useTranslation()


  const [query, setQuery] = useState(route.state && route.state.query || {
    page: 0,
    skip: 0,
    limit: 15,
    hours: 24,
    api_calls: 0,
    user: 1,
    deals: 1,
    deal_type_id: null,
  })

  const [searching, setSearching] = useState(false)

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    )
  }, [query])

  return (
    <div className="py-2 px-4">
      <Helmet>
        <title>{t('pages.trip_requests.title')} - {t('common.app')}</title>
      </Helmet>
      <div className="my-4 d-flex justify-content-between">
        <AdminPageHeader
          mainTitle={t('pages.trip_requests.title')}
        />
      </div>
      <div className="my-4 bg-white">
        <TripRequestsFilter
          searching={searching}
          values={query}
          loadDeals={setQuery}
        />
      </div>
      <div className="my-4 bg-white">
        <TripRequestsTable
          query={query}
          showPagination={true}
          url={LOCATION.TRIPS.REQUEST_API.path}
          onData={_ => setSearching(!_)}/>
      </div>
    </div>
  )
}

export default TripRequests;