const TripRequests = {
  title: 'Requests',
  deals_found: 'Deals Found',
  hotels_api_calls: 'Hotels API Calls',
  flights_api_calls: 'Flights API Calls',
  table: {
    labels: {
      id: 'ID',
      user: 'User',
      deals_found: '# Deals',
      api_calls: 'API Calls',
      type: 'Type',
      status: 'Status',
      payload: 'Payload',
    },
    on_queue: 'Queued, done {{estimatedTime}}'
  }
}

export default TripRequests;