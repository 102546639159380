import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Row } from 'react-bootstrap'
import FullDealCard from './components/FullDealCard'
import PageHeader from '@/layouts/components/PageHeader'
import OnPageNotification from '@/shared/components/OnPageNotification'
import LoadingCover from '@/shared/components/LoadingCover'
import { Link } from 'react-router-dom'

function Top15Deals () {

  const { t } = useTranslation()
  const auth = useAuth()

  const [loading, setLoading] = useState(true)
  const [deals, setDeals] = useState(null)

  const getDeals = () => {
    return auth.getRequest(`${LOCATION.DEALS_WEEK.API.path}/top-15`)
      .then(response => {
        setDeals(response.data?.data?.deals)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getSignUpLink = () => {
    if(auth.user){
      return `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0`
    }
    return `${LOCATION.AUTH.SIGN_UP.path}/1`
  }

  useEffect(() => {
    let request = getDeals()

    return () => request?.abort && request.abort()
  }, [])

  if (loading) return <LoadingCover/>

  return (
    <>
      <PageHeader
        title={t('pages.deals.top_15')}
        bgColor="primary"
      />
      {
        (!deals || deals?.length === 0) ? <OnPageNotification
            notificationTitle={t('pages.deals.notifications.no_top_15_deals_found.title')}
            notificationDescription={t('pages.deals.notifications.no_top_15_deals_found.body')}
            notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
            notificationLink={LOCATION.ROOT.path}
          />
          :
          <div className="container">

            <div className="text-center my-4">

              <p className="w-100 text-center">{t('pages.deals.travel_max_member_statement_for_top_15_deals')}</p>
              {
                auth?.user?.user_type === 'travel_max' ?
                  null
                  :
                  <>
                    <p
                        className="w-100 text-center">{t('pages.deals.travel_lite_member_statement_for_top_15_deals')}</p>
                    <Link to={getSignUpLink()}
                          className="btn btn-primary heading-text p-5">{t('common.travel_max_feature.buttons.sign_up_travel_max')}</Link>
                  </>
              }
            </div>
            <Row className="my-4">
              {
                deals.map(deal => {
                  return (
                    <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                      <FullDealCard
                        deal={deal}
                        showCustomMessage={true}
                        hideDescription={true}
                      />
                    </Col>
                  )
                })
              }
            </Row>
          </div>
      }
    </>
  )
}

export default Top15Deals