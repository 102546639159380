import { Button, Card, Form, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function SubLocationForm ({ index, subLocation, register, errors, namePrefix, remove }) {

  const { t } = useTranslation()

  return (
    <Card className="my-2">
      <Card.Body>
        <input
          type="hidden"
          name="id"
          {...register(`${namePrefix}.id`)}
          value={subLocation.sub_location_id}/>
        <Row className="mb-2">
          <Form.Group className="col col-12 col-md-6">
            <Form.Label>
              {t('pages.locations.form.labels.sub_location_name')}
              <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              className="mb-3 mb-md-1"
              placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.sub_location_name') })}
              {...register(`${namePrefix}.name`)}
              isInvalid={!!errors.radius}
            />

            <Form.Control.Feedback type="invalid">
              {errors.name && errors.name.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col col-12 col-md-6">
            <Form.Label>
              {t('pages.locations.form.labels.radius')}
              <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              className="mb-3 mb-md-1"
              placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.radius') })}
              {...register(`${namePrefix}.radius`)}
              isInvalid={!!errors.radius}
            />

            <Form.Control.Feedback type="invalid">
              {errors.radius && errors.radius.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="my-2">
          <Form.Group className="col col-12 col-md-6">
            <Form.Label>
              {t('pages.locations.form.labels.longitude')}
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="mb-3 mb-md-1"
              placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.longitude') })}
              {...register(`${namePrefix}.longitude`)}
              isInvalid={!!errors.longitude}
            />

            <Form.Control.Feedback type="invalid">
              {errors.longitude && errors.longitude.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col col-12 col-md-6">
            <Form.Label>
              {t('pages.locations.form.labels.latitude')}
              <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              className="mb-3 mb-md-1"
              placeholder={t('common.form.placeholder_enter', { attribute: t('pages.locations.form.labels.latitude') })}
              {...register(`${namePrefix}.latitude`)}
              isInvalid={!!errors.latitude}
            />

            <Form.Control.Feedback type="invalid">
              {errors.latitude && errors.latitude.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-4 mb-2">
          <Form.Group className="col col-12">
            <Button
              variant="link"
              className="text-danger"
              onClick={() => remove(subLocation, index)}
            >
              {t('common.buttons.remove')}
            </Button>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  )

}

export default SubLocationForm