import LOCATION from "@/constants/Location";
import Profile from '@/pages/Profile'
import Preferences from '@/pages/Profile/Preferences'
import EditProfile from '@/pages/Profile/EditProfile'

const Trips = [
  {
    path: LOCATION.PROFILE.EDIT.path,
    component: Profile,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.PROFILE.PREFERENCES.path,
    component: Preferences,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.PROFILE.EDIT_ACCOUNT_DETAILS.path,
    component: EditProfile,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.PROFILE.EDIT_ACCOUNT_DETAILS_VALIDATE.path,
    component: EditProfile,
    exact: true,
    main: false,
  },
]

export default Trips;