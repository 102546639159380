function DownloadIcon({color, size}) {
  return (
    <svg width={`${size ?? 35}`} height={`${size ?? 38}`} viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0576 12.7161H23.7394V0.23877H11.262V12.7161H2.94381L17.5007 29.3526L32.0576 12.7161ZM0.864258 33.5117H34.1372V37.6708H0.864258V33.5117Z"
        fill={color ?? 'white'}/>
    </svg>
  )
}

export default DownloadIcon;