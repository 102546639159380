import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FlightsForm from './FlightsForm'
import { Button, Form, Row } from 'react-bootstrap'
import AirportSearch from '../../Users/components/AirportSearch'
import LocationSearch from './LocationSearch'
import HotelForm from './HotelForm'
import TravelerSelection from '@Pages/Trips/Request/components/TravelerSelection'

function DealForm ({ register, unregister, errors, namePrefix, setValue, index, watch, holidayTypes, removeCustomDeal, deal }) {

  const { t } = useTranslation()

  useEffect(() => {
    register(`${namePrefix}airport_id`);
    register(`${namePrefix}location_id`);
  }, [])

  return (
    <>
      <p>{t('pages.top_15_deals.headers.deal_form_header')}</p>
      <input
        type="hidden"
        {...register(`${namePrefix}id`)}
        value={deal?.id}
      />
      <input
        type="hidden"
        {...register(`${namePrefix}trip_request_id`)}
        value={deal?.trip_request_id}
      />
      <Row className="">
        <Form.Group className="mb-4 col col-12" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.trips.steps.holiday_type.title')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            placeholder={t('common.form.placeholder_choose', { attribute: t('pages.trips.steps.holiday_type.title') })}
            {...register(`${namePrefix}holiday_type_id`)}
            isInvalid={!!(errors && errors.holiday_type_id)}
          >
            {
              holidayTypes?.map(holidayType => <option value={holidayType.id} key={holidayType.id}>{holidayType.name}</option>)
            }
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            {errors && errors.holiday_type_id && errors.holiday_type_id.message}
          </Form.Control.Feedback>
          {
            (errors && errors[index] && errors[index].airport_id && errors[index].airport_id.message) &&
            <div className="invalid-feedback d-block">{errors[index].airport_id.message}</div>
          }
        </Form.Group>
      </Row>
      <TravelerSelection
        className="mb-4"
        labelClass="heading-text"
        errors={errors && errors[index]}
        register={register}
        unregister={unregister}
        watch={watch}
        namePrefix={namePrefix}
      />
      <Row className="">
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('common.airport')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <AirportSearch
            setValue={setValue}
            fieldName={`${namePrefix}airport_id`}
            prefix={t('common.search')}
            airport={deal?.airport}
          />
          {
            (errors && errors[index] && errors[index].airport_id && errors[index].airport_id.message) &&
            <div className="invalid-feedback d-block">{errors[index].airport_id.message}</div>
          }
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.locations.location')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <LocationSearch
            setValue={setValue}
            fieldName={`${namePrefix}location_id`}
            prefix={t('common.search')}
            location={deal?.location}
          />
          {
            (errors && errors[index] && errors[index].airport_id && errors[index].airport_id.message) &&
            <div className="invalid-feedback d-block">{errors[index].airport_id.message}</div>
          }
        </Form.Group>
      </Row>
      <Row className="">
        <Form.Group className="mb-4 col col-12" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('common.form.custom_message')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.custom_message') })}
            {...register(`${namePrefix}custom_message`)}
            isInvalid={!!(errors && errors.custom_message)}
          />
          <Form.Control.Feedback type="invalid">
            {errors && errors.custom_message && errors.custom_message.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <FlightsForm
        register={register}
        errors={errors && errors[index]?.flights}
        namePrefix={`${namePrefix}flights.`}
        setValue={setValue}
        index={index}
        flight={deal?.flight}
      />
      <HotelForm
        watch={watch}
        register={register}
        errors={errors && errors[index]?.hotel}
        namePrefix={`${namePrefix}hotel.`}
        setValue={setValue}
        index={index}
        hotel={deal?.hotel}
      />

      <Form.Group className="col col-12 my-2" controlId="formBasicName">
        <Button variant="danger" className="heading-text text-white" onClick={() => removeCustomDeal(index)}>{t('common.buttons.remove_hotel')}</Button>
      </Form.Group>
    </>
  )

}

export default DealForm