import { useAuth } from '@/services/Auth'
import { useRef, useState } from 'react'
import LOCATION from '@/constants/Location'
import AsyncSelect from 'react-select/async'

function UserSeartch ({ setValue, placeHolder, airport }) {
  const auth = useAuth()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [selectedUser, setSelectedUser] = useState(airport ? { value: airport.id, label: airport.name } : null)

  const geLocations = (search) => {

    requests.locations && requests?.locations?.abort && requests.locations.abort()

    return new Promise((resolve, reject) => {
      requests.locations = auth.getRequest(LOCATION.USERS.API.path, { search })

      requests.locations.then(response => {
        resolve(response.data?.data?.map(_ => {
          return { value: _.id, label: _.name }
        }))
      })
        .catch(error => reject(error))
    })
  }

  return (
    <>
      <AsyncSelect
        className="w-100 react-select"
        isClearable={true}
        value={selectedUser}
        onChange={(e) => {
          setSelectedUser(e)
          setValue('user_id', e?.value)
        }}
        placeholder={placeHolder}
        defaultOptions={true}
        loadOptions={geLocations}/>
    </>
  )

}

export default UserSeartch