function FlightConnectionIcon ({ size, color }) {

  return (
    <svg width={size ?? '22'} height={size ?? '22'} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9226 14.5753C14.9226 15.1706 14.4356 15.6577 13.8403 15.6577H9.51092C8.91563 15.6577 8.42857 15.1706 8.42857 14.5753C8.42857 13.98 8.91563 13.493 9.51092 13.493H10.5933C10.0846 8.93633 6.38295 5.32131 1.80464 4.87755C1.5406 4.85049 1.29631 4.72544 1.11997 4.52706C0.94364 4.32869 0.848086 4.07141 0.852177 3.80603C0.852177 3.16744 1.41499 2.65874 2.05358 2.72368C6.29636 3.1458 9.91138 5.77589 11.6756 9.48832C12.8986 6.85823 14.9118 4.88837 16.8492 3.51379L14.7711 1.4357C14.4248 1.08935 14.6629 0.504883 15.1499 0.504883H20.8755C21.1786 0.504883 21.4167 0.742998 21.4167 1.04605V6.77164C21.4167 7.2587 20.8322 7.49681 20.4967 7.15046L18.3969 5.05072C15.9292 6.76082 13.245 9.48832 12.7579 13.493H13.8403C14.4356 13.493 14.9226 13.98 14.9226 14.5753Z"
        fill={color ?? "#F2BD1E"}/>
    </svg>
  )
}

export default FlightConnectionIcon