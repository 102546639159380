import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LOCATION from '@/constants/Location'
import AuthLayout from './AuthLayout'
import { Form } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import { getValidationErrors } from '../../utility'

function ResetPasswordForm (props) {
  const { t } = useTranslation()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])
  const [passwordReset, setPasswordReset] = useState(false)

  const { email, token } = useParams()

  let passwordMinLength = 6

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.email_address_is_required')),
    password: yup.string()
      .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', { length: passwordMinLength }))
      .required(t('common.form_validation.password_is_required')),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
      .required(t('common.form_validation.password_confirm_required')),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const resetPassword = (values) => {
    values.token = token
    setServerError('')
    setLoading(true)
    auth.postRequest(`${LOCATION.AUTH.FORGOT_PASSWORD.path}/reset`, values)
      .then(response => {
        setLoading(false)
        if (response.data?.status === 'OK') {
          setPasswordReset(true)
          props.history.push(`${LOCATION.AUTH.SIGN_IN.path}/${email}`);
        }
      })
      .catch(error => {
        setLoading(false)
        setServerError(error.response?.data?.message);
        setServerErrors(getValidationErrors(error));
      })
  }
  useEffect(() => {
    setValue('email', email)
  }, [email])
  useEffect(() => {
    setValue('token', token)
  }, [token])

  return (
    <AuthLayout
      title={t('pages.reset_password.title')}
      subtitle={t('pages.reset_password.instructions')}
      showNotification={passwordReset}
      notificationTitle={t('pages.reset_password.success.title')}
      notificationDescription={t('pages.reset_password.success.description')}
      notificationLink={LOCATION.AUTH.SIGN_IN.path}
      notificationLinkText={t('pages.login.title')}
    >

      <Form noValidate onSubmit={handleSubmit(resetPassword)}>

        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Label>{t('common.form.email')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.email') })}
            {...register('email')}
            isInvalid={!!errors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Label>{t('common.form.verification_code')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.verification_code') })}
            {...register('token')}
            isInvalid={!!errors.token}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" controlId="formBasicPassword">
          <Form.Label>{t('common.form.password')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('common.form.password')}
            {...register('password')}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password && errors.password.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4" controlId="formBasicPassword">
          <Form.Label>{t('common.form.re_enter_password')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('common.form.re_enter_password')}
            {...register('password_confirmation')}
            isInvalid={!!errors.password_confirmation}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password_confirmation && errors.password_confirmation.message}
          </Form.Control.Feedback>
        </Form.Group>

        {
          serverError !== '' &&
          <div className="form-group mb-2">
            <p className="text-danger py-1 mb-0">{t(serverError)}</p>
          </div>
        }
        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-2">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
            }
          </div>
        }
        <Form.Group className="">
          <div className="d-grid gap-2">
            <LoadingButton
              className="auth-btn text-white px-5 heading-text"
              loading={loading}
              variant="primary"
              titleTranslationKey="pages.forgot_password.reset"
              type="submit"
            />
          </div>
        </Form.Group>

      </Form>
    </AuthLayout>
  )
}

export default ResetPasswordForm