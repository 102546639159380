import {Button, Card, Col, Row} from 'react-bootstrap'
import { getNumberOfTravelers, getPricePerPerson } from '@/utility'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlaneDeparture, faUserGroup} from '@fortawesome/free-solid-svg-icons'
import FlightLeg from './FlightLeg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import AppModal from '@/shared/components/AppModal'
import LoadingButton from '@/shared/components/LoadingButton'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";

function FlightCard ({
  flight,
  deal,
  selectFlight,
  fullCard,
  chooseFlights,
  noChangeDeal,
  selected,
  disableBook,
  confirmLivePrice,
  updatingPrice,
  trip,
}) {
  const { t } = useTranslation()
  const auth = useAuth()

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [booking, setBooking] = useState(false)
  const [booked, setBooked] = useState(flight?.booked)

  const airlineLogoLink = process.env.REACT_APP_AIRLINE_LOGO_API_URL

  const getAirlines = (flight) => {
    let airlines = []
    airlines.push({
      code: flight.outbound_flight.airline_code,
      name: flight.outbound_flight.code,
    })

    if (flight.inbound_flight.airline_code !== flight.outbound_flight.airline_code) {

      airlines.push({
        code: flight.inbound_flight.airline_code,
        name: flight.inbound_flight.code,
      })
    }

    return airlines
  }

  const markAsBookedRequest = () => {
    if (!booked) {
      setModalTitle(t('pages.trips.notifications.book_flight_confirm.title'))
      setModalBody(t('pages.trips.notifications.book_flight_confirm.body'))
      setConfirmText(t('common.dialogs.actions.yes'))
      setCancelText(t('common.dialogs.actions.cancel'))
      setShowModal(true)
    } else {
      setModalTitle(t('pages.trips.notifications.remove_from_booked_confirm.title'))
      setModalBody(t('pages.trips.notifications.remove_from_booked_confirm.body'))
      setConfirmText(t('common.dialogs.actions.yes'))
      setCancelText(t('common.dialogs.actions.cancel'))
      setShowModal(true)
    }
  }

  const handleConfirm = () => {

    setShowModal(false)
    bookFlight()
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const bookFlight = () => {
    setBooking(true)
    auth.postRequest(`${LOCATION.FLIGHTS.API.path}/${flight.id}/book`)
      .then(response => {
        setBooked(!booked)
        setBooking(false)
      })
      .catch(error => {
        setBooking(false)
      })
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Card className={`${fullCard ? 'card-layout h-100' : 'card-layout h-100'}`}>
       <div className="float-left w-100 block image-slider">
          {
            getAirlines(flight).map((airline, index) => {
              return (
                
                  <img className="airline-logo deal-card" src={`${airlineLogoLink}/${airline.code}.png`} alt="airline-logo"/>
               
              )
            })
          }
        </div>
        <Card.Body className="d-flex flex-column justify-content-start align-items-start">
		
		  <div className="float-left w-100 location-top">
		  	<h5 className="heading-text text-primary">{t('common.flights')}</h5>
			
			<div className="float-left w-100 flex justify-content-start align-items-end">
				<div className="pricing">
				  <span>From</span> <strong>{flight.currency_symbol}{getPricePerPerson(flight.price, deal)} pp</strong> <small>({flight.currency_symbol}{flight.price})</small>
				</div>
			</div>
			
		  </div>
		  	
	
			<Col>
				<div className="d-flex travel-meta border-top-flights">
					<div className="d-flex align-items-center col">
					 <FontAwesomeIcon className="text-primary" icon={faUserGroup}/>
              			{t('pages.deals.flights_for', { count: getNumberOfTravelers(deal) })}
					</div>
				</div>
			</Col>	
       		
			
         	<Col className="flights col d-flex flights-deal-meta">
          	
            <Col md={6}>
              <FlightLeg
                  deal={deal}
                  className="mb-2"
                  shortCard={true}
                  flight={flight}
                  outBoundFlight={flight.outbound_flight}
                  inBoundFlight={flight.inbound_flight}
                  split={true}
              />
            </Col>
            <Col md={6}>
              <FlightLeg
                  deal={deal}
                  shortCard={true}
                  flight={flight}
                  outBoundFlight={flight.inbound_flight}
                  inBoundFlight={flight.outbound_flight}
                  split={true}
              />
            </Col>
      		
			</Col>
			 
          <div className="d-flex column-buttons w-100 flights-deal-btn mt-auto">
            {
              !flight.sold_out && !flight.expired &&
              <>
                {
                  (flight.link && flight.live_price && (moment().diff(moment(flight?.created_at), 'days') < 1) || trip) ?

                    disableBook ?
                      <a className="btn btn-dark heading-text" href={flight.link} target="_blank" rel="noreferrer">
                        {t('pages.deals.buttons.more_information')}
                      </a>
                      :
                      <a className="btn btn-primary heading-text" href={flight.link} target="_blank" rel="noreferrer">
                        <div className="d-flex justify-content-between align-items-center">
                          {t('pages.deals.buttons.book_now')}
                          <FontAwesomeIcon icon={faPlaneDeparture}/>
                        </div>
                      </a> : confirmLivePrice ? <LoadingButton
                          variant="primary"
                          onSubmit={confirmLivePrice}
                          loading={updatingPrice}
                          titleTranslationKey={t('pages.deals.buttons.conform_price')}
                          className="heading-text"
                          buttonTextElement={
                            <div className="d-flex justify-content-between align-items-center">
                              {t('pages.deals.buttons.conform_price')}
                              <FontAwesomeIcon icon={faArrowRight}/>
                            </div>}
                      /> : null
                }
              </>
            }
            {
              fullCard ?

                <Button disabled={selected} className="heading-text text-white" onClick={() => selectFlight(flight)}>
                  {t('pages.deals.buttons.select_flights')}
                </Button>
                :
                <>
                  {
                    auth.user?.client &&
                    <>
                      <hr/>
                      {
                        !noChangeDeal &&
                        <Link to={`${LOCATION.DEALS.LIST.path}/${deal.id}/flights`}
                              className={"btn btn-dark text-white heading-text" + (!flight?.live_price ? ' disabled' : '')}
                              disabled={!flight?.live_price}
                        >
                          {t('pages.deals.buttons.choose_flights_again')}
                        </Link>
                      }
                      {
                        flight.expired ?
                          <>
                            <Button variant="danger" disabled={true}
                                    className="text-white heading-text">{t('pages.trips.buttons.expired')}</Button>
                          </>
                          :
                          flight.sold_out ?
                            <>
                              <Button variant="danger" disabled={true}
                                      className="text-white heading-text">{t('pages.trips.buttons.sold_out')}</Button>
                            </>
                            :
                            null
                      }
                      <LoadingButton
                        loading={booking}
                        type="button"
                        variant="outline-dark"
                        className="heading-text"
                        onSubmit={markAsBookedRequest}
                        titleTranslationKey={`${booked ? 'pages.trips.buttons.booked' : 'pages.deals.buttons.mark_flight_as_booked'}`}
                      />
                    </>
                  }

                </>
            }
          </div>

        </Card.Body>
      </Card>
    </>
  )
}

export default FlightCard