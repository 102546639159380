import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/shared/components/LoadingButton'
import useGet from '@/hooks/useGet'
import CountrySearch from './CountrySearch'

function UserFilters ({
  searching,
  values,
  loadLocations,
}) {

  const { t } = useTranslation()
  const auth = useAuth()

  const {data: holidayTypes, loading} = useGet(LOCATION.HOLIDAY_TYPES.API.path);

  const {
    resetField,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...values
    }
  })

  const onSubmit = (values) => {
    loadLocations(values)
  }

  useEffect(() => {
    register('country_id')
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Form.Group className="col col-sm-6 col-md-3 col-12 mb-2 mb-md-1">
          <Form.Label>
            {t('common.search')}
          </Form.Label>
          <Form.Control
            {...register('search')}
            placeholder={t('common.search_for_a_locations')}
            name="search"
          />
        </Form.Group>

        <Form.Group className="col col-sm-6 col-md-3 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('common.form.country')}
            </Form.Label>

            <CountrySearch
              placeHolder={t('common.search')}
              setValue={setValue}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="col col-sm-6 col-md-3 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('common.form.holiday_types')}
            </Form.Label>

            <Form.Control
              as="select"
              name="status"
              {...register('type')}
            >
              <option value="" hidden>{t('common.choose')}</option>
              {holidayTypes?.map(_ => <option value={_.id} key={_.id}>{_.name}</option>)}
            </Form.Control>
          </Form.Group>
        </Form.Group>
        <Col className="col-sm-6 col-md-3 col-12 d-flex align-items-end">
          <div className="d-grid gap-2 w-100">
            <LoadingButton
              type="submit"
              onlySpinner={true}
              loading={searching}
              className="text-white mt-3 mt-md-0 heading-text"
              titleTranslationKey="common.apply"
            />
          </div>
        </Col>
      </Row>
    </form>
  )

}

export default UserFilters