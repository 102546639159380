const Users = {
  title: 'Users',
  travel_max_users: 'Travel Max Users',
  travel_lite_users: 'Travel Lite Users',
  travel_max_users_with_airports: 'Max with specified airports',
  travel_max_users_with_destinations: 'Users with airports & destinations',
  form: {
    labels: {
      user_type: 'User Type',
      sort_by: 'Sort By',
      start_end_date: 'Start/End Date',
      payment_type: 'Payment Type',
    }
  },
  sort_by: {
    expensive_first: 'Expensive First',
    cheapest_first: 'Cheapest First',
    expire_first: 'Expire First',
    latest_first: 'Latest First',
  },
  user_types: {
    travel_max: 'Travel Max',
    travel_lite: 'Travel Lite',
    admin: 'Admin',
  },
  payment_types: {
    auto_renew: 'Auto Renew',
    manual: 'Manual Renew',
  },
  buttons: {
    edit_details: 'Edit Details',
    cancel_subscription: 'Cancel Subscription',
    delete_subscription: 'Delete Subscription',
    refund_subscription: 'Refund Subscription',
    create_user: 'Create User',
    create_admin: 'Create Admin',
    refresh_tags: 'Refresh Campaign Tags',
    upgrade_travel_max: 'Upgrade to Travel Max',
    delete_user: 'Delete User',
  },
  notifications: {
    admin_updated: {
      title: 'Admin Updated'
    },
    request_tag_refresh: {
      title: 'Refresh Campaign Tags',
      body: 'This process will refresh campaign tags for all users on based on their preferences and subscriptions'
    },
    request_tag_refresh_requested: {
      title: 'Tag Refresh process started',
      body: 'The process is started and a while to fishing'
    },
    delete_user_request: {
      title: 'Delete Current User?',
      body: 'User and their related information will be deleted from the records',
    },
    delete_user_request_second_request: {
      title: 'Are You Sure?',
    },
    user_deleted: {
      title: 'User Deleted!',
    },
    user_updated: {
      title: 'User Updated'
    },
    user_created: {
      title: 'User Created'
    },
    admin_created: {
      title: 'Admin Created'
    }
  }
}

export default Users