import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Paginate from "../../../../shared/Paginate";
import LOCATION from "../../../../constants/Location";
import {useAuth} from "../../../../services/Auth";


function useColumns() {
  const {t} = useTranslation()

  let columns = [
    {
      dataField: 'id',
      text: t('pages.trip_requests.table.labels.id'),
    },
    {
      dataField: 'status',
      text: t('pages.trip_requests.table.labels.status'),
      formatter: (cell, row) => {
        return <div>
          {
            cell === 200 ?
              <span className="text-success me-1">{cell}</span>
              :
              <span className="text-danger me-1">{cell}</span>
          }
        </div>
      },
    },
    {
      dataField: 'payload',
      text: t('pages.trip_requests.table.labels.payload'),
      formatter: (cell, row) => <span>{cell}</span>,
    },
  ]

  return columns
}

function ApiRequestsTable({data: recordsProp, paginate, url, query, ...props}) {
  let auth = useAuth()

  const {t} = useTranslation()

  const perPage = query.limit || 20
  const exclude = props.exclude;
  const showPagination = paginate == null ? true : paginate

  const request = useRef(null)
  const [page, setPage] = useState(0)
  const [totalRecords, setTotal] = useState(false)
  const [recordsDownloaded, setRecords] = useState(false)

  const getRecords = () => {
    request?.current?.abort && request.current.abort()

    const critera = {api_calls: 1, ...(query || {})}

    critera.take = perPage
    critera.skip = page * perPage

    request.current = auth.getRequest(url || LOCATION.TRIPS.REQUEST_API.path, critera)

    setRecords(false)

    request.current
      .then(response => {
        setRecords(response.data.data)
        setTotal(response.data.length)
      })
  }

  const columns = useColumns()

  const changePage = (event) => {
    setPage(event.selected)
  }

  const records = recordsProp || recordsDownloaded

  useEffect(() => {
    if (!recordsProp) {
      getRecords()
    }

    return () => request?.current?.abort && request.current.abort()
  }, [page, query, url])

  useEffect(() => {
    props.onData && props.onData(records)
  }, [records])

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords)
  }, [totalRecords])

  return (
    <div className="list-table requests-table px-2">
      <BootstrapTable
        keyField="id"
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('common.no_data') : t('common.loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{changePage, totalRecords, page, perPage}} />
        || ''
      }
    </div>
  )
}

export default ApiRequestsTable;

export {
  useColumns
}