const Locations = {
  title: 'Locations',
  destination_specific_deal_settings_title: 'Destination Specific Deal Settings for Landing Pages',
  location: 'Location',
  create_location: 'Create Location',
  buttons: {
    new_location: 'New Location',
    create_location: 'Create Location',
    update_location: 'Update Location',
    delete_location: 'Delete Location',
    add_sub_location: 'Add Sub Location/Town',
  },
  destination_specific_deal_settings: {
    form: {
      labels: {
        duration: 'Duration To Find Deals For',
        months: 'Months To Find Deals For',
      }
    }
  },
  form: {
    labels: {
      name: 'Location Name',
      page: 'Page',
      location_definition: 'How is this location defined?',
      location_keyword: 'Location',
      region: 'Region',
      airports: 'Airports',
      months: 'Months Active',
      longitude: 'Longitude',
      latitude: 'Latitude',
      radius: 'Radius (km)',
      featured_image: 'Featured Image',
      features: 'Features (Maximum 3)',
      features_message: 'Location Features',
      holiday_types: 'Holiday Types',
      direct_flights_only: 'Direct Flights only',
      sub_locations: 'Sub Locations/Towns',
      sub_location_name: 'Sub Location/Town name',
      define_one_sub_location: 'Define at least one sub location/Town',
      region_ids_are_defined_by_airports: 'Region IDs are defined by airports below',
      min_budget: 'Minimum Budget',
      max_budget: 'Maximum Budget',
    }
  },
  location_definitions: {
    gps: 'GPS within Radius - e.g. the Coordinates of The London Eye',
    keyword_search: 'Location Keyword Search - e.g. London Heathrow' + '',
    region_id: 'Region ID - e.g. London',
    sub_locations: 'Sub Locations/Towns',
  },
  notifications: {
    location_created: {
      title: 'Location Created',
    },
    location_updated: {
      title: 'Location Updated',
    },
    location_deleted: {
      title: 'Location Deleted',
    },
    delete_location_request: {
      title: 'Delete Location?',
      body: 'Deleting this location will also delete deals and trips to this location',
    },
    remove_sub_location: {
      title: 'Are you Sure?',
      body: 'Do you want to delete the sub location?',
    }
  }
}
export default Locations