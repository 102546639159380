import React, {useEffect, useState} from "react";
import {useAuth} from "../../../services/Auth";
import {useTranslation} from "react-i18next";
import LoadingCover from "../../../shared/components/LoadingCover";
import LOCATION from "../../../constants/Location";
import {Helmet} from "react-helmet";
import {Breadcrumb, Card, Col, Row} from "react-bootstrap";
import HourlyCallsGraph from "./components/HourlyCallsGraph";
import CallComparisonPie from "./components/CallComparisonPie";
import UsersSummary from "./components/UsersSummary";
import DealsSummary from "./components/DealsSummary";

function Dashboard() {

  const auth = useAuth();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);

  const [stats, setStats] = useState();


  const getStats = () => {
    auth.getRequest(`${LOCATION.DASHBOARD.API.path}`)
      .then(response => {
        setStats(response.data);
        setLoading(false);

      })
      .catch(error => {
        setLoading(false);
      })
  }
  useEffect(() => {
    getStats();
  }, []);

  if (loading) return <LoadingCover/>

  return (
    <div className="m-2">
      <Helmet>
        <title>{t('pages.dashboard.title')} - {t('common.app')}</title>
      </Helmet>
      <div className="my-1 d-flex align-items-center justify-content-between flex-column flex-md-row">
        <Breadcrumb>
          <Breadcrumb.Item active className="heading-text text-dark">{t('pages.dashboard.title')}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="">
        <DealsSummary/>
        <UsersSummary/>
        <Row>
          <Col className="col-12">
            <HourlyCallsGraph stats={stats}/>
          </Col>
          <Col className="col-12 col-md-4">
            <CallComparisonPie stats={stats}/>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Dashboard;