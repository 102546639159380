import PageHeader from '@/layouts/components/PageHeader'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import React from 'react'
import ManageUserAvatar from './components/ManageUserAvatar'
import ManageUserPaymentDetails from './components/ManageUserPaymentDetails'
import ManageUserSubscription from './components/ManageUserSubscription'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import ManageAccountDetails from './components/ManageAccountDetails'

function Profile () {

  const { t } = useTranslation()

  const auth = useAuth()
  let baseUrl = auth.api.defaults.baseURL

  const [userProfileLink, setUserProfileLink] = useState(auth?.user?.avatar)

  const getDefaultImage = (e) => {
    e.target.src = `/assets/images/user-placeholder.png`
  }

  const getUser = () => {
    return auth.getRequest(`${LOCATION.USERS.API.path}/${auth.user.id}`)
      .then(response => {
        auth.updateUser(response.data?.data)
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    let request = getUser()
    return () => request?.abort && request.abort()
  }, [])

  return (
    <>
      <PageHeader
        title={t('pages.profile.edit_profile')}
      />
      <div className="container">
        <p
          className="my-4 w-100 text-center">{t('pages.profile.edit_your_profile_payment_details_subscription_here')}</p>
        <Row className="">
          <Col className="col-12 col-md-6 p-md-5 p-2 d-flex flex-column align-items-center justify-content-center">
            <img
              src={`${userProfileLink}`}
              className="rounded-circle user-profile mb-2"
              alt="Avatar"
              onError={(e) => getDefaultImage(e)}
            />
            <p className="my-3 heading-text">{auth.user.name}</p>
            <div className="d-grid gap-2 w-75 mt-3">
              <ManageUserAvatar
                userProfileLink={userProfileLink}
                setUserProfileLink={setUserProfileLink}
                baseUrl={baseUrl}
              />
            </div>
          </Col>
          <Col className="col-12 col-md-6 p-md-5 p-5">
            <ManageAccountDetails/>
            <ManageUserPaymentDetails/>
            <ManageUserSubscription/>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Profile