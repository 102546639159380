function CheckCircleIcon () {
  return (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M29.5001 0.166687C13.3001 0.166687 0.166748 13.3 0.166748 29.5C0.166748 45.7 13.3001 58.8333 29.5001 58.8333C45.7001 58.8333 58.8334 45.7 58.8334 29.5C58.8334 13.3 45.7001 0.166687 29.5001 0.166687ZM42.2147 24.54C42.4489 24.2724 42.6271 23.9607 42.739 23.6231C42.8508 23.2856 42.8941 22.9291 42.8661 22.5746C42.8382 22.2202 42.7396 21.8749 42.5763 21.559C42.4129 21.2432 42.188 20.9632 41.9149 20.7356C41.6417 20.508 41.3258 20.3372 40.9857 20.2335C40.6456 20.1298 40.2881 20.0951 39.9344 20.1316C39.5807 20.168 39.2379 20.2748 38.9261 20.4457C38.6142 20.6166 38.3398 20.8481 38.1187 21.1267L26.6521 34.884L20.7187 28.948C20.2158 28.4623 19.5422 28.1935 18.843 28.1996C18.1438 28.2056 17.475 28.4861 16.9806 28.9805C16.4861 29.4749 16.2057 30.1438 16.1996 30.8429C16.1935 31.5421 16.4623 32.2157 16.9481 32.7187L24.9481 40.7187C25.2101 40.9805 25.5238 41.1849 25.8692 41.3188C26.2146 41.4527 26.5841 41.5132 26.9541 41.4964C27.3242 41.4796 27.6867 41.3859 28.0185 41.2212C28.3503 41.0565 28.6442 40.8245 28.8814 40.54L42.2147 24.54Z"
            fill="#F2BD1E"/>
    </svg>
  )
}

export default CheckCircleIcon;