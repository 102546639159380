import { Col, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PaymentMethodInformation ({ user }) {
  const { t } = useTranslation()
  return (
    <Row className="my-2">
      <Col className="col-6">
        <h6 className="my-3">{t('common.card')}</h6>
        <h5 className="fw-bold">{t('pages.payments.card', {
          brand: user?.payment_method.brand,
          cardNumber: user?.payment_method.card_number
        })}</h5>
      </Col>
      <Col className="col-6">
        <h6 className="my-3">{t('common.expires')}</h6>
        <h5 className="fw-bold">{user?.payment_method.expire_date}</h5>
      </Col>
    </Row>
  )
}

export default PaymentMethodInformation