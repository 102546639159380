import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import Paginate from '../Paginate'
import LOCATION from '@/constants/Location'

function useColumns (filter) {
  const { t } = useTranslation()

  let columns = [
    {
      dataField: 'name',
      text: t('common.form.city'),
      formatter: (cell, row) => {
        return <Link to={`${LOCATION.DESTINATIONS.LIST.path}/${row?.id}/edit`} className="table-links text-nowrap">{cell}</Link>
      },
    },
    {
      dataField: 'country.name',
      text: t('common.form.country'),
      formatter: (cell, row) => {
        return <span className="text-nowrap">{cell}</span>
      }
    },
    {
      dataField: 'holiday_types',
      text: t('common.form.holiday_types'),
      formatter: (cell, row) => {
        return cell.map(_ => _.name).join(',')
      }
    },
    {
      dataField: 'description',
      text: t('common.form.description'),
    },
  ]

  return filter && columns.filter(filter) || columns
}

function Locations ({ data: recordsProp, paginate, url, query,  ...props }) {
  let auth = useAuth()
  const { t } = useTranslation()

  const perPage = query.limit || 20
  const exclude = props.exclude || []
  const showPagination = paginate == null ? true : paginate

  const request = useRef(null)
  const [page, setPage] = useState(query.page)
  const [totalRecords, setTotal] = useState(false)
  const [recordsDownloaded, setRecords] = useState(false)

  const getRecords = () => {
    request?.current?.abort && request.current.abort()

    const critera = { ...(query || {}) }

    critera.take = perPage
    critera.skip = page * perPage

    request.current = auth.getRequest(url || LOCATION.DESTINATIONS.API.path, critera)

    setRecords(false)

    request.current
      .then(response => {
        setRecords(response.data.data)
        setTotal(response.data.length)
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name))

  const changePage = (event) => {
    setPage(event.selected)
  }

  const records = recordsProp || recordsDownloaded

  useEffect(() => {
    if (!recordsProp) {
      getRecords()
    }

    return () => request?.current?.abort && request.current.abort()
  }, [page, query, url])

  useEffect(() => {
    props.onData && props.onData(records)
  }, [records])

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords)
  }, [totalRecords])

  useEffect(() => {
    setPage(0);
  }, [query])

  return (
    <div className="list-table">
      <BootstrapTable
        keyField="id"
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('common.no_data') : t('common.loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{ changePage, totalRecords, page, perPage }} />
        || ''
      }
    </div>
  )
}

export default Locations

export {
  useColumns
}
