import {useEffect, useState} from "react";
import LOCATION from "../../../../constants/Location";
import {useAuth} from "../../../../services/Auth";
import {Card, Col} from "react-bootstrap";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import BarGraphAsProgress from "./BarGraphAsProgress";
import LoadingCover from "../../../../shared/components/LoadingCover";

function DealsSummary() {

  const auth = useAuth();

  const {t} = useTranslation();

  const [dealStats, setDealStats] = useState(null);


  const getDealStats = () => {
    return auth.getRequest(`${LOCATION.DEALS.API.path}/stats`)
      .then(response => {
        setDealStats(response.data);
      })
      .catch(error => {

      })
  }

  const getSize = (call, dealStat) => {
    return ((call.count / dealStat.calls.total) * 100).toFixed(0)
  }

  useEffect(() => {
    let request = getDealStats();

    return () => request?.abort && request.abort();
  }, [])
  if (!dealStats) return <LoadingCover/>;

  return (
    <div className="my-1 row">
      {
        dealStats.map(dealStat => {
          return <Col className="col-12 col-sm-6 col-lg-3 mb-3">
            <Card>
              <Card.Body>
                <h5>{t(`pages.deals.types.${dealStat.deal_type_id}`)}</h5>
                <div className="d-flex">
                  <span>{t('pages.deals.stats.labels.deals_created')}:</span>&nbsp;&nbsp;
                  <span className="fw-semibold">{dealStat.deals}</span>
                </div>
                <div className="d-flex">
                  <span>{t('pages.deals.stats.labels.number_of_users')}:</span>&nbsp;&nbsp;
                  <span className="fw-semibold">{dealStat.users}</span>
                </div>
                <div className="d-flex">
                  <span>{t('pages.deals.stats.labels.users_received_deals')}:</span>&nbsp;&nbsp;
                  <span className="fw-semibold">{dealStat.deal_found_for_users}</span>
                </div>
                <div className="d-flex">
                  <span>{t('pages.deals.stats.labels.users_who_can_receive_emails')}:</span>&nbsp;&nbsp;
                  <span className="fw-semibold">{dealStat.users_can_receive_email}</span>
                </div>
                <div className="d-flex">
                  <span>{t('pages.deals.stats.labels.total_emails_sent')}</span>:&nbsp;&nbsp;
                  <span className="fw-semibold">{dealStat.emails_sent}</span>
                </div>
                <div className="d-flex">
                  <span>{t('pages.deals.stats.labels.last_run_date')}</span>:&nbsp;&nbsp;
                  <span
                    className="fw-semibold">{moment(dealStat.run_date).format(t('common.formats.short_date'))}</span>
                </div>
                <div className="w-100">
                  <BarGraphAsProgress callStatus={dealStat.calls.status}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        })
      }
    </div>
  )

}

export default DealsSummary