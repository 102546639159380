import { useAuth } from '@/services/Auth'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@/shared/components/LoadingButton'
import UserAvatar from '@/shared/components/UserAvatar'
import LOCATION from "../../constants/Location";

function UserProfileDropDown () {
  const { t } = useTranslation()
  const auth = useAuth()

  const [loading, setLoading] = useState(false)

  const logout = () => {
    setLoading(true)
    auth.logout()
      .then(response => {
        setLoading(false)
        window.location.href = '/login'
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const profileAvatar = React.forwardRef(({ children, onClick }, ref) => (
    <UserAvatar
      avatarLink={auth.user?.avatar}
      size="30px"
      refData={ref}
      clickAction={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    />
  ))

  return (
    <>
      <Dropdown align="end" className="me-2">
        <Dropdown.Toggle as={profileAvatar}>
          <i className="bi bi-globe text-white"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            auth.user?.client &&
            <>
              <Dropdown.Item className="heading-text h6 py-2" href="/profile">
                {t('pages.profile.title')}
              </Dropdown.Item>
              {
                auth.user.user_type === 'travel_max' &&
                <Dropdown.Item className="heading-text h6 py-2" href="/profile/preferences">
                  {t('pages.profile.preferences')}
                </Dropdown.Item>
              }
                <Dropdown.Item className="heading-text h6 py-2" href={LOCATION.TRIPS.MY_SAVED_TRIPS.path}>{t('pages.trips.saved_trips')}</Dropdown.Item>
            </>
          }
          <Dropdown.Item className="d-grid gap-2" href="#">
            <LoadingButton
              loading={loading}
              onSubmit={logout}
              className="nav-link py-1 text-white heading-text btn-logout"
              titleTranslationKey="pages.login.logout"
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default UserProfileDropDown