import PageHeader from '@/layouts/components/PageHeader'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import FullDealCard from './components/FullDealCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import OnPageNotification from '@/shared/components/OnPageNotification'
import TravelMaxFeature from '../Banners/TravelMaxFeature'
import TravelMaxPremium from '../Banners/TravelMaxPremium'
import moment from 'moment'

function TopDeals () {

  const { t } = useTranslation()
  const auth = useAuth()
  const { type } = useParams()

  const [loading, setLoading] = useState(true)
  const [deals, setDeals] = useState(null)
  const [tripRequest, setTripRequest] = useState(null)
  const [title, setTitle] = useState(t('pages.deals.latest_custom_deals'))
  const [description, setDescription] = useState(t('pages.deals.top_deals_statement'))
  const [dealNextDay, setDealNextDay] = useState(null)
  const [subscribed, setSubscribed] = useState(false)

  const getDeals = () => {
    return auth.getRequest(`${LOCATION.DEALS.API.path}`, { deal_type: type === 'latest-deals' ? 'top-deals' : type, })
      .then(response => {
        setDeals(response.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    let request = getDeals()
    return () => request?.abort && request.abort()
  }, [])

  useEffect(() => {
    if (type === 'deal-by-budget') {
      setTitle(t('pages.trips.under_price', { underPrice: '£200' }))
      setDescription(t('pages.deals.deal_by_budget_statement', { underPrice: '£200' }))
      setSubscribed(auth.user.preferences?.deal_emails_to_receive?.includes(2));
    } else if (type === 'weekend-trip') {
      setTitle(t('pages.trips.weekend_trip'))
      setDescription(t('pages.deals.weekend_trip_statement'))
      setSubscribed(auth.user.preferences?.deal_emails_to_receive?.includes(3));
    } else if (type === 'long-haul') {
      setTitle(t('pages.trips.long_haul'))
      setDescription(t('pages.deals.long_haul_statement'))
      setSubscribed(auth.user.preferences?.deal_emails_to_receive?.includes(8) && auth.user.preferences?.long_haul_airports?.length > 0);
    } else if (type === 'beach-deals') {
      setTitle(t('pages.trips.beach_deals'))
      setDescription(t('pages.deals.beach_deals_statement'))
      setSubscribed(auth.user.preferences?.deal_emails_to_receive?.includes(6));
    }
  }, [])

  useEffect(() => {
    if (deals && deals.length !== 0) {
      let deal = deals[0];
      setTripRequest(deals[0].trip_request)
      setDealNextDay(deal?.deal_next_days?.find(_ => _.type_id === deal?.deal_type_id))
    }
  }, [deals])

  if (auth.user?.user_type !== 'travel_max') {
    return <TravelMaxPremium/>
  }

  if (loading) return <LoadingCover/>

  if (!subscribed) {
    return <>
      <PageHeader
          title={title}
      />
      <OnPageNotification
          notificationTitle={t('pages.deals.notifications.deal_type_not_subscribed.title', {type: title})}
          notificationDescription={t('pages.deals.notifications.deal_type_not_subscribed.text', {additional: type === 'long-haul' ? " Additionally you need to select at least one long haul airport" : ""})}
          notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
          notificationLink={LOCATION.PROFILE.PREFERENCES.path}
          notificationLinkText={t('pages.profile.preferences')}
      />
    </>
  }

  return (
    <>
      <PageHeader
        title={title}
      />
      {
        deals.length === 0 ? <OnPageNotification
            notificationTitle={t('pages.deals.notifications.no_top_deals_found.title')}
            notificationDescription={description}
            notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
            notificationLink={LOCATION.PROFILE.PREFERENCES.path}
            notificationLinkText={t('pages.profile.preferences')}
          />
          :
          <div className="container">
            <p className="my-4 w-100 text-center">{description}</p>
            {
              tripRequest &&
              <p className="my-4 w-100 text-center">{t('pages.deals.top_deals_update_times', {
                createdAt: moment(tripRequest.created_at).format(t('common.formats.short_date')),
                nextUpdate: moment(dealNextDay?.next_update).format(t('common.formats.short_date'))
              })}</p>
            }
            <Row>
              {
                deals.map(deal => {
                  return (
                    <Col className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
                      <FullDealCard
                        deal={deal}
                      />
                    </Col>
                  )
                })
              }
            </Row>
          </div>
      }
    </>
  )
}

export default TopDeals