import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import ChooseCity from './DealSelectionProcess/Steps/ChooseCity'
import LoadingCover from '@/shared/components/LoadingCover'
import OnPageNotification from '@/shared/components/OnPageNotification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import CityCard from './components/CityCard'
import { getCheapestPrice } from '../../utility'

function DealSelectionProcess () {
  const { t } = useTranslation()
  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { trip_request_id, location_id } = useParams()

  const [loading, setLoading] = useState(true)
  const [tripRequest, setTripRequest] = useState(null)
  const [deals, setDeals] = useState(null)
  const [totalRows, setTotalRows] = useState(0)
  const [query, setQuery] = useState(route.state && route.state.query || {
    page: 0,
    skip: 0,
    take: 100,
    limit: 100,
    trip_request_id: trip_request_id,
    location_id: location_id,
  })

  const getDeals = () => {
    return auth.getRequest(`${LOCATION.DEALS.API.path}`, {
      ...query,
      sort_by: trip_request_id ? 'cheapest_first' : 0
    })
      .then(response => {
        setDeals(response.data?.data)
        setTotalRows(response.data?.length)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getTripRequest = () => {
    return auth.getRequest(`${LOCATION.TRIPS.REQUEST_API.path}/${trip_request_id}`,)
      .then(response => {
        setTripRequest(response.data?.data)
      })
      .catch(error => {
      })
  }

  const loadMore = () => {
    let updateQuery = { ...query }
    updateQuery.take = query.take + 3
    setQuery(updateQuery)
  }

  const getLocations = () => {
    let locations = []

    let dealLocations = deals.map(_ => _.location_id)

    let uniqueLocations = [...new Set(dealLocations)]

    uniqueLocations.forEach(locationId => {
      let locationDeals = deals.filter(_ => _.location_id === locationId)
      let prices = locationDeals.map(_ => _.total_amount)

      if (locationDeals.length !== 0) {

        locations.push({
          id: locationId,
          total_amount: Math.min.apply(Math, prices),
          currency_symbol: auth.user.currency.symbol,
          location: locationDeals[0]?.location,
          date: locationDeals[0]?.date,
          trip_request: tripRequest,
        })
      }
    })

    return locations
  }

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query])

  useEffect(() => {
    let request = getDeals()
    return () => request?.abort && request.abort()
  }, [query])

  useEffect(() => {
    let request = getTripRequest()
    return () => request?.abort && request.abort()
  }, [query])

  if (loading || !tripRequest) return <LoadingCover/>

  if (!deals || deals?.length === 0) {
    return <OnPageNotification
      notificationTitle={t('pages.deals.notifications.no_deal_found.title')}
      notificationDescription={t('pages.deals.notifications.no_deal_found.body')}
      notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
      notificationLinkText={t('pages.trips.start_your_journey')}
      notificationLink={LOCATION.TRIPS.START_YOUR_JOURNEY.path}
    />
  }

  return (
    <>
      {
        location_id ?
          <ChooseCity
            deals={deals}
            totalRows={totalRows}
            showDates={true}
            loadMoreDeals={loadMore}
            defaultImage={true}
          /> :

          <div className="container p-md-2 p-0">
            <Row className="mt-md-4 mt-0 heading-space">
              <Col className="col-md-6 col-12">
                <div className="button-label primary heading-text">
                  {t('pages.deals.choose_destination')}
                </div>
              </Col>
              <Col className="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
                <div className="button-label light shadow heading-text">
                  {t('common.total_price_from', {
                    price: getCheapestPrice(getLocations()),
                    currency: auth.user?.currency?.symbol
                  })}
                </div>
              </Col>
            </Row>
            <div className="p-4 p-md-0">
              <p
                className="w-100 text-md-start text-center my-4">{t('pages.deals.we_found_deals_on_following_destination')}</p>
            </div>
            <Row className="mt-4 px-4 px-md-0">
              {
                getLocations().map(deal => {
                  return (
                    <Col className="col-xl-4 col-lg-6 col-md-6 col-12 mb-4" key={deal.id}>
                      <CityCard
                        deal={deal}
                        showDates={false}
                        fullCard={true}
                        goToOptions={true}
                      />
                    </Col>
                  )
                })
              }
            </Row>
          </div>
      }
    </>
  )

}

export default DealSelectionProcess