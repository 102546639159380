import React, {useEffect, useState} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Room({ index, data, removeRoom, setValue, register, errors, namePrefix, watch }) {
    const { t } = useTranslation();

    const room = watch(`${namePrefix ? namePrefix + '.' :  ''}rooms[${index}]`, data);

    return (
        <div className="room-selection">
            <h4>{t('pages.trips.form.labels.room_selection.room', { room: index + 1 })}</h4>
            <Row>
                <Col md={4}>
                    <Form.Group>
                        <Form.Label>{t('pages.trips.form.labels.room_selection.adults')}</Form.Label>
                        <Form.Control
                            as="select"
                            {...register(`${namePrefix ? namePrefix + '.' :  ''}rooms[${index}].adults`)}
                            isInvalid={!!(errors && errors.rooms && errors.rooms[index] && errors.rooms[index].adults)}
                        >
                            <option value="" disabled selected>
                                {t('common.choose')}
                            </option>
                            {
                                [...Array(10)].map((number, index) => {
                                    return <option value={index + 1} key={index}>{index + 1}</option>
                                })
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors && errors.rooms && errors.rooms[index] && errors.rooms[index].adults && errors.rooms[index].adults.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group>
                        <Form.Label>{t('pages.trips.form.labels.room_selection.children')}</Form.Label>
                        <Form.Control
                            as="select"
                            {...register(`${namePrefix ? namePrefix + '.' :  ''}rooms[${index}].children`)}
                        >
                            <option value={0}>{t('common.none')}</option>
                            {
                                [...Array(10)].map((number, index) => {
                                    return <option value={index + 1} key={index}>{index + 1}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={4} className="btn-remove">
                    {index >= 1 && (
                        <Button variant="danger" onClick={() => removeRoom(index)}>
                            {t('pages.trips.form.labels.room_selection.remove_room')}
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zm80 80V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                        </Button>
                    )}
                </Col>
            </Row>
            <Row className="child-row">
                {room.children > 0 && [...Array(parseInt(room.children))].map((number, childIndex) => {
                    return (
                        <Col md={4}  className="child-col">
                            <Form.Group className="my-4" key={childIndex}>
                                <Form.Label>
                                    {t('pages.trips.form.labels.who_is_traveling_adults.children_label_count', { count: (childIndex + 1) })}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register(`${namePrefix ? namePrefix + '.' : ''}rooms[${index}].children_ages.[${childIndex}]`)}
                                    // isInvalid={}
                                >
                                    <option value="" disabled selected>
                                        {t('common.choose')}
                                    </option>
                                    {
                                        [...Array(16)].map((number, index) => {
                                            return <option value={index} key={index}>{index}</option>
                                        })
                                    }
                                </Form.Control>

                                <Form.Control.Feedback type="invalid">
                                    {/*{errors && errors.children_ages && errors.children_ages[index] && errors.children_ages[index].message}*/}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
}

function RoomSelection({ setValue, register, errors, watch, unregister, namePrefix, rooms: data }) {
  const { t } = useTranslation();

  const rooms = watch(`${namePrefix ? namePrefix + '.' :  ''}rooms`, data ?? [
      {
        adults: '',
        children: 0,
        children_ages: []
      }
  ]);

  const addRoom = () => {
    setValue(`${namePrefix ? namePrefix + '.' :  ''}rooms`, [...rooms, { adults: '', children: 0, children_ages: [] }]);
  };

  const removeRoom = (index) => {
    setValue(`${namePrefix ? namePrefix + '.' :  ''}rooms`, rooms.filter((room, i) => i !== index));
  };

  return (
      <>
      <p>{t('pages.trips.form.labels.room_selection.description')}</p>
        {rooms?.map((room, index) => (
            <Room
                key={index}
                index={index}
                data={room}
                removeRoom={removeRoom}
                setValue={setValue}
                register={register}
                errors={errors}
                namePrefix={namePrefix}
                watch={watch}
            />
        ))}
		<div className="w-100 d-flex justify-content-end add-room-btn">
        <Button variant="primary" onClick={addRoom}>
          {t('pages.trips.form.labels.room_selection.add_room')}
        </Button>
		</div>
      </>
  );
}

export default RoomSelection;