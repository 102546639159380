function StarIcon({color, size}) {
  return (
    <svg width={`${size ?? 43}`} height={`${size ?? 40}`} viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.8053 14.0744L28.4303 12.2759L22.8984 1.06094C22.7473 0.753883 22.4987 0.505311 22.1916 0.354218C21.4216 -0.0259508 20.4858 0.290857 20.1007 1.06094L14.5688 12.2759L2.19379 14.0744C1.85261 14.1232 1.54068 14.284 1.30185 14.5277C1.01313 14.8245 0.854029 15.2237 0.859512 15.6377C0.864996 16.0517 1.03461 16.4466 1.3311 16.7356L10.2846 25.4649L8.16926 37.7911C8.11966 38.0778 8.15139 38.3727 8.26085 38.6424C8.37032 38.912 8.55314 39.1455 8.78859 39.3165C9.02404 39.4875 9.30269 39.5891 9.59295 39.6098C9.8832 39.6305 10.1735 39.5695 10.4308 39.4336L21.4995 33.6141L32.5683 39.4336C32.8705 39.5945 33.2214 39.6481 33.5577 39.5896C34.4058 39.4434 34.976 38.6392 34.8298 37.7911L32.7145 25.4649L41.668 16.7356C41.9117 16.4968 42.0725 16.1848 42.1213 15.8437C42.2529 14.9907 41.6582 14.2011 40.8053 14.0744Z"
        fill={color ?? 'white'}/>
    </svg>

  )
}

export default StarIcon;