import { Button, Card, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import TripSummary from '../../Trips/components/TripSummary'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'

function NotificationCard ({ notification, dismissNotification }) {

  const { t } = useTranslation()

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Card.Title className="heading-text text-primary">
            {
              notification.has_deals ?

                t('pages.deals.notifications.deals_found.title')
                :
                notification.canceled ?
                t('pages.deals.notifications.no_deal_found_on_request_limit_reached.title')
                  :
                t('pages.deals.notifications.no_deal_found_on_request.title')
            }
          </Card.Title>
          <h6 className="heading-text mt-4">{t('pages.deals.notifications.deals_found.your_trip_summary')}</h6>
          <TripSummary
            tripRequest={notification.tripRequest}
            short={true}
          />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end gap-2">
          <Button
            variant="secondary"
                  onClick={() => dismissNotification(notification)}>{t('common.buttons.dismiss')}</Button>
          {
            notification.has_deals &&
            <Link to={`${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/results/${notification.tripRequest.id}`}
                  className="btn btn-primary text-white">{t('common.dialogs.actions.take_me_there')}</Link>
          }
        </Card.Footer>
      </Card>
    </>

  )

}

export default NotificationCard