import { Button, Modal } from 'react-bootstrap'
import React from 'react'

function AppModal ({
  show,
  title,
  body,
  coloredText,
  textColor,
  cancelText,
  confirmText,
  handleCancel,
  handleConfirm,
}) {
  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title className="heading-text">{title}</Modal.Title>
      </Modal.Header>
      {body && <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: body }}/>
        {
          coloredText &&
          <div className={`mt-2 ${textColor ?? 'text-danger'}`} dangerouslySetInnerHTML={{ __html: coloredText }}/>
        }
      </Modal.Body>}

      {
        ((cancelText && handleCancel) || (confirmText && handleConfirm))
        &&
        <Modal.Footer>
          {cancelText && handleCancel &&
            <Button variant="secondary" onClick={() => handleCancel()}>{cancelText}</Button>}
          {
            confirmText && handleConfirm &&
            <Button variant="primary" className="text-white" onClick={() => handleConfirm()}>
              {confirmText}
            </Button>
          }
        </Modal.Footer>
      }
    </Modal>
  )
}

export default AppModal