import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import UserForm from '../Users/components/UserForm'
import AppModal from '@/shared/components/AppModal'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import LOCATION from '@/constants/Location'
import { getValidationErrors } from '@/utility'
import LoadingCover from '@/shared/components/LoadingCover'
import HolidayForm from './components/HolidayForm'
import { useAuth } from '@/services/Auth'
import { Breadcrumb } from 'react-bootstrap'

function Edit (props) {
  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const [savingHoliday, setSavingHoliday] = useState(false)
  const [holiday, setHoliday] = useState({
    name: '',
    locations: [],
    show_on_trip_request_form: false,
  })

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [confirmText, setConfirmText] = useState(null);

  const [serverErrors, setServerErrors] = useState([])

  const createHoliday = (values) => {
    setServerErrors([])
    setSavingHoliday(true)

    values.locations = values.locations?.map(_ => _.value);

    auth.postRequest(`${LOCATION.HOLIDAY_TYPES.LIST.path}`, values)
      .then(response => {
        setSavingHoliday(false)
        setModalTitle(t('pages.holidays.notifications.package_created.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {
        setSavingHoliday(false)
        setServerErrors(getValidationErrors(error))
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    props.history.push(`${LOCATION.HOLIDAY_TYPES.LIST.path}`)
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />
      <Helmet>
        <title>{t('pages.holidays.create_package')} - {t('pages.deals.holidays')} - {t('common.app')}</title>
      </Helmet>

      <div className="p-4">
        <Breadcrumb className="text-dark heading-text">
          <Breadcrumb.Item className="">
            <Link to={LOCATION.HOLIDAY_TYPES.LIST.path}>{t('pages.deals.holidays')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('pages.holidays.edit_package')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{holiday.name}</Breadcrumb.Item>
        </Breadcrumb>
        <HolidayForm
          holiday={holiday}
          updateHoliday={createHoliday}
          savingHoliday={savingHoliday}
          serverErrors={serverErrors}
          submitLabel="pages.holidays.buttons.create_package"
        />
      </div>
    </>
  )
}

export default Edit