import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { useEffect, useState } from 'react'
import LOCATION from '@/constants/Location'
import { Form, Image } from 'react-bootstrap'
import LoadingCover from '@/shared/components/LoadingCover'
import { getValidationErrors, isValidHttpUrl } from '@/utility'

function ImageUpload ({ fieldName, setValue, errors, register, holidayType }) {
    const auth = useAuth()
    const baseUrl = auth.api.defaults.baseURL

    const { t } = useTranslation()

    const [imageUrl, setImageUrl] = useState(holidayType && holidayType[fieldName] ? isValidHttpUrl(holidayType[fieldName]) ? holidayType[fieldName] : `${baseUrl}/${holidayType[fieldName]}` : null)

    const [uploadingImage, setUploadingImage] = useState(false)

    const [serverErrors, setServerErrors] = useState([]);

    useEffect(() => {
        register(fieldName)
    }, [])

    const uploadImage = (image) => {
        setUploadingImage(true)
        setServerErrors([])
        let imageData = image.target.files[0]
        let form = new FormData()
        form.append('image', imageData)

        auth.postRequest(`${LOCATION.HOLIDAY_TYPES.API.path}/upload-image`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                setValue(fieldName, response.data)
                setImageUrl(`${baseUrl}/${response.data}`)
                setUploadingImage(false)
            })
            .catch(error => {
                setUploadingImage(false)
                setServerErrors(getValidationErrors(error))
            })
    }

    return (
        <>
            <Form.Control
                isInvalid={!!(errors && errors[fieldName])}
                className="mb-2"
                name={fieldName}
                onChange={(e) => uploadImage(e)}
                type="file"
                accept="image/*,.jpg,.png,.jpeg"
            />
            <Form.Control.Feedback type="invalid">
                {errors && errors[fieldName] && errors[fieldName].message}
            </Form.Control.Feedback>
            {
                (serverErrors.length !== 0) &&
                <div className="form-group mt-4">
                    {
                        serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{error}</p>)
                    }
                </div>
            }
            {
                uploadingImage &&
                <div className="w-100 mt-2 mb-2" style={{ height: '200px' }}>
                    <LoadingCover/>
                </div>
            }
            <div className="w-100 mt-2 mb-2">
                {
                    (imageUrl && !uploadingImage) &&
                    <>
                        <Image className="w-100" src={imageUrl}/>
                    </>
                }
            </div>
        </>
    )
}

export default ImageUpload