import Chart from "react-apexcharts";

function BarGraphAsProgress({callStatus}) {

  const series = [{
    name: 'Calls',
    data: callStatus.data
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ['#28A745', '#FFC107', '#DC3545'],
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      xaxis: {
        lines: {
          show: false,
        }
      },
      yaxis: {
        lines: {
          show: false,
        }
      }
    },
    yaxis: {
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
      floating: true,
      position: 'bottom',
    },
    xaxis: {
      categories: callStatus.labels,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {show: false,}
    }
  };

  return (
    <Chart className="progress-bars chart-container" options={options} series={series} type="bar" height={100}/>
  );

}

export default BarGraphAsProgress;