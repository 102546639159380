const Holidays = {
  edit_package: 'Edit Package',
  create_package: 'Create Package',
  buttons: {
    update_package: 'Update Package',
    delete_holiday: 'Delete Package',
    create_package: 'Create Package',
  },
  notifications: {
    package_updated: {
      title: 'Package Updated!',
    },
    package_deleted: {
      title: 'Package Deleted!',
    },
    package_created: {
      title: 'Package Created!',
    },
  },
  form: {
    package_name: 'Package Name',
    filters: 'Filters',
    airports: 'Airports',
    validation_messages:{
      choose_one_locations: 'Please choose at least one Location',
    },
    show_on_trip_request_form: 'Show on Trip Request Form?',
  },

}
export default Holidays