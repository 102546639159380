import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import React, { useEffect, useState } from 'react'
import LoadingCover from '@/shared/components/LoadingCover'
import { Button, Col, Row } from 'react-bootstrap'
import CityCard from '../Deals/components/CityCard'
import HotelCard from '../Deals/components/HotelCard'
import FlightCard from '../Deals/components/FlightCard'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import LoadingButton from '@/shared/components/LoadingButton'
import AppModal from '@/shared/components/AppModal'
import TripSummary from './components/TripSummary'

function Trip () {
  const auth = useAuth()
  const { t } = useTranslation()

  const { id } = useParams()

  const [trip, setTrip] = useState(null)
  const [updatingPrice, setUpdatingPrice] = useState(false)
  const [currentView, setCurrentView] = useState('trip_overview')

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(t('common.buttons.cancel'))

  const updatePrice = () => {
    setUpdatingPrice(true)

    auth.getRequest(`${LOCATION.TRIPS.API.path}/${id}/update-price`)
      .then(response => {
        setUpdatingPrice(false)
        setModalTitle(t('pages.deals.notifications.price_refreshed.title'))
        setModalBody(t('pages.deals.notifications.price_refreshed.body'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {
        setUpdatingPrice(false)
      })
  }

  const getTrip = () => {
    setTrip(null)
    return auth.getRequest(`${LOCATION.TRIPS.API.path}/${id}`)
      .then(response => {
        setTrip(response.data?.data)
      })
      .catch(error => {

      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    getTrip()
  }

  useEffect(() => {
    let request = getTrip()
    return () => request.abort && request.abort()
  }, [])

  if (!trip) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />
      <div className={`${auth.user.client ? 'container pt-3' : ''} mb-5`}>
        <Row className="h-100">
          <Col className={`col-12 col-md-9 ${auth.user.admin ? 'p-4' : ''}`}>
            <div className="mb-4">
              {
                auth.user.client ?
                  <Row className="mt-0 heading-space">
                    <Col className="col-md-6 col-12">
                      <div className="button-label primary heading-text">
                        {trip.location.destination}
                      </div>
                    </Col>
                    <Col className="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
                      <div className="button-label light shadow heading-text">
                        {t('common.total_price', {
                          price: trip.total_amount,
                          currency: auth.user?.currency?.symbol
                        })}
                      </div>
                    </Col>
                  </Row>
                  :
                  <AdminPageHeader
                    mainTitle={t('pages.users.title')}
                    title={trip?.user.name}
                    subtitle={trip?.location.destination}
                  />
              }
            </div>
            <Row>
              <Col className="col-12 d-grid pb-3">
                {
                  trip.hotel.expired && trip.flight.expired ?
                    <Button variant="danger" disabled={true}
                            className="text-white heading-text">{t('pages.trips.buttons.trip_is_expired')}</Button>
                    :
                    <LoadingButton
                      className="heading-text text-white"
                      titleTranslationKey={t('pages.trips.buttons.refresh_prices')}
                      loading={updatingPrice}
                      onSubmit={updatePrice}
                    />
                }
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-6 col-lg-4">
                <CityCard
                  deal={trip}
                />
              </Col>
              <Col className="col-12 col-sm-6 col-lg-4">
                <FlightCard
                  flight={trip.flight}
                  deal={trip}
                  fullCard={false}
                  noChangeDeal={true}
                  trip={true}
                  chooseFlights={() => setCurrentView('choose_flight')}
                />
              </Col>
              <Col className="col-12 col-sm-6 col-lg-4">
                <HotelCard
                  deal={trip}
                  hotel={trip.hotel}
                  gotToHotels={() => setCurrentView('choose_accommodation')}
                  noChangeDeal={true}
                  fullCard={false}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-md-3 user-info-panel p-4 mt-4 mt-md-0 ">
            {
              auth.user.admin &&
              <>
                <p className="fw-bold my-2">{trip.user?.name}</p>
                <p className="fw-bold my-2">{trip.user?.email}</p>
                <hr/>
              </>
            }
            <TripSummary
              tripRequest={trip.trip_request}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Trip