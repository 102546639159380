import React, { useState } from 'react'
import { useAuth } from '@/services/Auth'

function UserAvatar({clickAction, refData, size, avatarLink}){

  const auth = useAuth();

  const [userProfileLink, setUserProfileLink] = useState(`${avatarLink}`)
  const getDefaultImage = (e) => {
    e.target.src = `/assets/images/user-placeholder.png`
  }

  return (
    <img
      onClick={clickAction}
      ref={refData}
      src={`${userProfileLink}`}
      style={{width:size, height:size,}}
      className="rounded-circle user-profile"
      alt="Avatar"
      onError={(e) => getDefaultImage(e)}
    />
  )
}
export default UserAvatar;

