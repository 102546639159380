import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../services/Auth";
import LOCATION from "../../../constants/Location";
import LoadingCover from "../../../shared/components/LoadingCover";
import {Helmet} from "react-helmet";
import {Breadcrumb, Card, Col, Row} from "react-bootstrap";
import AdminDeals from "../Deals";
import ApiRequestsTable from "./components/ApiRequestsTable";
import TripSummary from "../../Trips/components/TripSummary";

function ShowRequest() {

  const {t} = useTranslation()
  const auth = useAuth()

  const {id} = useParams()

  const [tripRequest, setTripRequest] = useState(null)


  const getRequest = () => {
    return auth.getRequest(`${LOCATION.TRIPS.REQUEST_API.path}/${id}`, {deals: 1})
      .then(response => {
        setTripRequest(response.data.data);
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    let request = getRequest();
    return request?.abort && request.abort();
  }, []);

  if (!tripRequest) return <LoadingCover/>

  return (
    <>
      <Helmet>
        <title>{t('pages.trip_requests.title')} - {t('common.app')}</title>
      </Helmet>

      <Row className="">
        <Col className="col-12 col-md-9 p-4">
          <div className="d-flex justify-content-between">
            <Breadcrumb className="text-dark heading-text">
              <Breadcrumb.Item className="">
                <Link to={LOCATION.TRIPS.REQUESTS.path}>{t('pages.trip_requests.title')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{tripRequest.id}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
      </Row>
      <Row className="">
        <Col className="col-12">
          <Card className="m-2">
            <Card.Body>
              <TripSummary tripRequest={tripRequest}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="col-12">
          <h5 className="heading-text mx-3">{t('pages.trip_requests.deals_found')}</h5>
          <AdminDeals trip_request_id_prop={tripRequest?.id}/>
        </Col>
      </Row>

      <Row className="">
        <Col className="col-12">
          <h5 className="heading-text mx-3">{t('pages.trip_requests.hotels_api_calls')}</h5>
          <ApiRequestsTable url={LOCATION.TRIPS.API_REQUEST_API.path} query={{type: 1, trip_request_id: tripRequest.id}}/>
        </Col>
      </Row>

      <Row className="">
        <Col className="col-12">
          <h5 className="heading-text mx-3">{t('pages.trip_requests.flights_api_calls')}</h5>
          <ApiRequestsTable url={LOCATION.TRIPS.API_REQUEST_API.path} query={{type:2, trip_request_id: tripRequest.id }}/>
        </Col>
      </Row>
    </>
  )
}

export default ShowRequest;