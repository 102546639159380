import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import React, { useState } from 'react'
import AppModal from '@/shared/components/AppModal'
import LoadingButton from '@/shared/components/LoadingButton'

function ManageUserPaymentDetails () {
  const { t } = useTranslation()
  const auth = useAuth()

  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const [removingPaymentMethod, setRemovingPaymentMethod] = useState(false)

  const removeTripRequest = () => {

    setModalTitle(t('pages.payments.notifications.delete_payment_method.title'))
    setConfirmText(t('common.dialogs.actions.delete'))
    setCancelText(t('common.dialogs.actions.cancel'))
    setShowModal(true)
  }

  const handleConfirm = () => {
    setShowModal(false)
    if( removingPaymentMethod ){
      setRemovingPaymentMethod(false)
    }
    else{
      removePaymentMethod()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const removePaymentMethod = () => {
    setRemovingPaymentMethod(true)
    auth.deleteRequest(`${LOCATION.USERS.API.path}/${auth.user.id}/payment-method`)
      .then(response => {
        auth.updateUser(response.data.data);
        setModalTitle(t('pages.payments.notifications.payment_method_deleted.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null)
        setShowModal(true)
      })
      .catch(error => {

      })
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <h4 className="heading-text">{t('pages.profile.payment_details')}</h4>
      {
        auth.user?.payment_method ?
          <>
            <Row className="mb-2">
              <Col className="col-6">
                <h6 className="my-3">{t('common.card')}</h6>
                <h5 className="fw-bold">{t('pages.payments.card', {
                  brand: auth.user?.payment_method.brand,
                  cardNumber: auth.user?.payment_method.card_number
                })}</h5>
              </Col>
              <Col className="col-6">
                <h6 className="my-3">{t('common.expires')}</h6>
                <h5 className="fw-bold">{auth.user?.payment_method.expire_date}</h5>
              </Col>
            </Row>
            <Row className="">
              <Col className="col-12 d-grid gap-2">
                <Link to={LOCATION.PAYMENT_METHODS.CREATE.path} className="btn btn-dark heading-text">
                  {t('common.buttons.edit')}
                </Link>
                <LoadingButton
                  onSubmit={removeTripRequest}
                  className="auth-btn text-white px-5 heading-text"
                  loading={removingPaymentMethod}
                  variant="danger"
                  titleTranslationKey="common.buttons.delete"
                  type="button"
                />
              </Col>
            </Row>
          </>
          :
          <>
            <p className="text-danger my-4">{t('common.no_payment_method_stored')}</p>
            <Row className="my-4">
              <Col className="col-12 d-grid gap-2">
                <Link to={LOCATION.PAYMENT_METHODS.CREATE.path} className="btn btn-primary heading-text">
                  {t('common.buttons.add_payment_method')}
                </Link>
              </Col>
            </Row>
          </>
      }
    </>
  )
}

export default ManageUserPaymentDetails