const Payments = {
  payment_method: 'Payment Method',
  subscribe: 'Subscribe',
  subscribe_to_travel_max: 'Subscribe to Travel Max',
  credit_card: 'Credit Card',
  paypal: 'PayPal',
  paypal_statement: 'You will be redirected to PayPal to complete payment',
  buy_trip_plans: 'Buy Trip Plans',
  payment_method_form: 'Fill your information below to save your payment method',
  trip_method_form: 'Fill your information below to purchase a trip plan',
  subscription_form: 'Fill your payment information below to subscribe to Travel Max',
  card: '{{brand}} ending in {{cardNumber}}',
  canceled_at: 'Canceled on {{date}}',
  canceled: 'Canceled',
  subscription_price_per_year: '{{currency}}{{price}} per year',
  subscription_price_per_year_trail: '14 days trial then {{currency}}{{price}} per year',
  subscription_price_per_month: '{{currency}}{{price}} every 3 months',
  payment_services_in_testing_mode: 'Payments service is in testing mode, Please use a testing card',
  payment_services_error: 'Sorry!, We have encountered an error with payment service',
  card_declined: 'Your Card was declined',
  payment_declined: 'Your payment was declined',
  security_code_is_not_correct: 'Your card\'s security code is incorrect',
  buttons: {
    save_payment_method: 'Save Payment Method',
    pay_and_subscribe: 'Subscribe for {{currency}}{{price}}',
    subscribe_for_a_trial: 'Subscribe for a 14 days trial',
    buy_trip_plans: 'Buy One trip plan for {{currency}}{{price}}',
    checkout_with_paypal: 'Checkout',
  },
  form: {
    card_number: 'Card Number',
    expire: 'Expire Date',
    ccv: 'CCV',
  },
  labels: {
    choose_subscription_duration: 'Choose Subscription duration',
    yearly_subscription: 'Yearly subscription at {{currency}}{{price}}',
    every_three_months_subscription: '3 months subscription at {{currency}}{{price}}',
  },
  notifications: {
    payment_method_saved: {
      title: 'Payment Method Updated',
    },
    delete_payment_method: {
      title: 'Delete Payment method?',
    },
    payment_method_deleted: {
      title: 'Payment method deleted',
    },
    cancel_subscription: {
      title: 'Delete Subscription?',
      body: 'Are you sure you want to delete Travel Max Subscription?',
    },
    refund_subscription: {
      title: 'Refund Subscription?',
      body: 'Are you sure you want to refund Travel Max Subscription?',
    },
    subscription_canceled: {
      title: 'Subscription Canceled',
    },
    subscription_refunded: {
      title: 'Subscription refunded',
    },
    trip_play_purchased: {
      title: 'Trip Plans Purchased',
      body: 'Thank you for your purchase, You have received 1 more trip plans to use any time',
    },
    subscription_error: {
      title: 'Initialization Failed!',
      body: 'We could\t finishing the process right now. Please try again later or use Credit Card',
    },
    you_have_active_subscription: {
      title: 'Travel Max Member',
      body: 'Thank your interest, but you have an active Travel Max subscription and you cant subscribe again until it expires',
    },
    you_have_claimed_subscription_trial: {
      title: 'No more subscription trial',
      body: 'Thank your interest in Travel Max Subscription, But you have already used your trial period, You cant try again',
      buttons: {
        subscribe_for_full_plan: 'Subscribe for a Full Plan',
      }
    },
    upgrade_travel_max: {
      title: 'Upgrade to travel max?',
      body: 'Do you want give a free travel max subscription access to current user?',
    },
    upgraded_travel_max: {
      title: 'User upgraded',
    },
    payment_canceled: {
      title: 'Payment Failed',
      body: 'You have canceled the payment',
    },
    payment_successful: {
      title: 'Your payment was successful',
      body: 'You have canceled the payment',
    },
    payment_processing_error: {
      title: 'Processing Failed',
      body: 'We have encountered an error processing your subscription. We will update subscription if your payment is successful',
    },
  }
}

export default Payments