import { Badge, Button, Col, Row } from 'react-bootstrap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import AppModal from '@/shared/components/AppModal'
import TravelMaxFeatures from './components/TravelMaxFeatures'

function TravelMaxPremium ({ setContinueWithDeal }) {
  const { t } = useTranslation()
  const auth = useAuth()

  const history = useHistory()

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(t('common.buttons.cancel'))

  const TravelMaxPrice = 24

  const conformMyBeLater = () => {

    if( auth?.user ){
      window.location.href = `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe`
    }
    else{
      setModalTitle(t('common.notifications.skip_login_sign_up_on_search.title'))
      setModalBody(t('common.notifications.skip_login_sign_up_on_search.body'))
      setConfirmText(t('common.travel_max_feature.buttons.sing_up_now'))
      setCancelText(t('common.travel_max_feature.buttons.maybe_later'))
      setShowModal(true)
    }

  }
  const handleDialogConfirm = () => {
    window.location.href = `${LOCATION.AUTH.SIGN_UP.path}`
  }

  const handleDialogCancel = async () => {
    await  localStorage.setItem('trip_values', '')
    history.replace(LOCATION.TRIPS.START_YOUR_JOURNEY.path, {})
    // window.location.href = LOCATION.TRIPS.START_YOUR_JOURNEY.path
  }

  const UpsellActions = () => {
    return (
      <div className="d-grid gap-2">
        <Link className="btn btn-lg btn-dark text-primary heading-text"
              to={auth?.user ? `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0` : `${LOCATION.AUTH.SIGN_UP.path}`}>
          {t('common.travel_max_feature.buttons.sing_up_now')}
        </Link>
        {
          auth.user ?
            setContinueWithDeal ?
              <Button className="text-dark heading-text"
                      onClick={() => setContinueWithDeal(true)}> {t('common.travel_max_feature.buttons.maybe_later')}</Button>
              :
              <Link
                className="btn btn-lg btn-primary text-dark heading-text"
                to={LOCATION.ROOT.path}>
                {t('common.travel_max_feature.buttons.maybe_later')}
              </Link>
            :
            <Button className="text-dark heading-text"
                    onClick={() => conformMyBeLater()}> {t('common.travel_max_feature.buttons.maybe_later')}</Button>
        }
      </div>
    )
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleDialogConfirm}
        handleCancel={handleDialogCancel}
      />
      <div className="container p-0 mb-3">
        <Row className="my-0 my-md-4 ">
          <Col className="col-12 col-md-6 px-0 px-md-2 px-xl-5">
            <div className="travel-area-splash pt-4 px-4 position-relative">

            </div>
            <div className="d-none d-md-block mt-4">
              {UpsellActions()}
            </div>
          </Col>
          <Col className="col-12 col-md-6 pt-4 pt-md-0 px-4 px-md-4 px-xl-5">
            <h4 className="heading-text my-3 mt-md-0 mb-md-3 text-primary">
              {t('common.travel_max_feature.heading_statement')}
            </h4>
            <p className="fw-semibold">{t('common.travel_max_feature.price_statement')}</p>
            <TravelMaxFeatures/>
            <div className="d-block d-md-none">
              {UpsellActions()}
            </div>

          </Col>
        </Row>
      </div>
    </>
  )
}

export default TravelMaxPremium