import LOCATION from "../../../constants/Location";
import Dashboard from "../../../pages/Admin/Dashboard/Dashboard";

const Stats = [
  {
    path: LOCATION.DASHBOARD.STATS.path,
    component: Dashboard,
    exact: true,
    main: false,
    gate: 'dashboard',
  },
];

export default Stats;