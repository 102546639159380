import { Button, Form } from 'react-bootstrap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import LoadingButton from '@/shared/components/LoadingButton'
import AppModal from '@/shared/components/AppModal'

function ManageUserAvatar ({ setUserProfileLink, baseUrl, userProfileLink }) {
  const { t } = useTranslation()
  const auth = useAuth()

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)

  const [uploadingImage, setUploadingImage] = useState(false)

  const uploadImage = (image) => {
    setUploadingImage(true)
    let imageData = image.target.files[0]
    let form = new FormData()
    form.append('image', imageData)

    auth.postRequest(`${LOCATION.USERS.API.path}/upload-image`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        auth.updateUser(response.data?.data)
        setUserProfileLink(`${baseUrl}/${response.data?.data?.avatar}`)
        setUploadingImage(false)
      })
      .catch(error => {
      })
  }

  const removeAvatarRequest = () => {
    setModalTitle(t('pages.profile.notifications.remove_image.title'))
    setModalBody(t('pages.profile.notifications.remove_image.body'))
    setConfirmText(t('common.dialogs.actions.remove'))
    setCancelText(t('common.dialogs.actions.cancel'))
    setShowModal(true)
  }

  const removeAvatar = () => {
    auth.postRequest(`${LOCATION.USERS.API.path}/remove-image`, {})
      .then(response => {
        auth.updateUser(response.data?.data)
        setUserProfileLink(null)
        setUploadingImage(false)
      })
      .catch(error => {
      })
  }

  const handleConfirm = () => {
    setShowModal(false);
    removeAvatar();
  }

  const handleCancel = () => {
    setShowModal(false);
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="d-grid gap-2">
        <Form noValidate className="position-relative">
          <div className="d-grid gap-2">
            <LoadingButton
              loading={uploadingImage}
              variant="dark"
              className="text-white mb-2 heading-text"
              titleTranslationKey="pages.profile.buttons.upload_new_image"
            />
          </div>
          <Form.Control
            className="avatar-upload-input"
            onChange={(e) => uploadImage(e)}
            type="file"
            accept="image/*,.jpg,.png,.jpeg"
          />
        </Form>
        {
          userProfileLink &&
          <Button variant="danger" className="text-white mb-2 heading-text" onClick={() => removeAvatarRequest()}>
            {t('common.buttons.remove')}
          </Button>
        }
      </div>
    </>
  )
}

export default ManageUserAvatar