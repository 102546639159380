import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Form } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import LOCATION from '@/constants/Location'
import AsyncSelect from 'react-select/async'
import { useAuth } from '@/services/Auth'
import { formatRecord } from '@/utility'
import ImageUpload from "./ImageUpload";
import holidayType from "../../../Trips/JourneySteps/HolidayType";

function HolidayForm ({ holiday, updateHoliday, serverErrors, savingHoliday, submitLabel, deleting, deleteHolidayRequest }) {
  const { t } = useTranslation()
  const auth = useAuth()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [selectedLocations, setSelectedLocations] = useState(holiday?.locations?.map(formatRecord) || [])
  const [selectedFilters, setSelectedFilters] = useState(holiday?.filters?.map(formatRecord) || [])
  const [selectedAirports, setSelectedAirports] = useState(holiday?.airports?.map(formatRecord) || [])

  const schema = yup.object().shape({
    name: yup.string()
      .required(t('pages.holidays.form.package_name')),
    locations: yup.array()
      .min(1, t('pages.holidays.form.validation_messages.validation_messages'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('common.form_validation.is_required', { attribute: t('pages.locations.title') })),
        label: yup.string(),
      }))
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        name: holiday?.name,
        locations: holiday?.locations?.map(formatRecord),
        airports: holiday?.airports?.map(formatRecord),
        filters: holiday?.filters?.map(formatRecord),
        deal_type: holiday?.deal_type,
        show_on_trip_request_form: holiday?.show_on_trip_request_form,
        thumbnail: holiday?.thumbnail,
        featured_image: holiday?.featured_image,
      }
    })(),
    resolver: yupResolver(schema),
  })

  const getLocations = (search) => {

    requests.locations && requests?.locations?.abort && requests.locations.abort()

    return new Promise((resolve, reject) => {
      requests.locations = auth.getRequest(LOCATION.DESTINATIONS.API.path, { search })

      requests.locations.then(response => {
        resolve(response.data?.data?.map(formatRecord))
      })
        .catch(error => reject(error))
    })
  }

  const getAirports = (search) => {

    requests.airports && requests?.airports?.abort && requests.airports.abort()

    return new Promise((resolve, reject) => {
      requests.airports = auth.getRequest(LOCATION.AIRPORTS.API.path, { search })

      requests.airports.then(response => {
        resolve(response.data?.data?.map(formatRecord))
      })
        .catch(error => reject(error))
    })
  }

  const getHotelFilters = (search) => {

    requests.filters && requests?.filters?.abort && requests.filters.abort()

    return new Promise((resolve, reject) => {
      requests.filters = auth.getRequest(LOCATION.HOTEL_FILTERS.API.path, { search })

      requests.filters.then(response => {
        resolve(response.data?.data?.map(formatRecord))
      })
        .catch(error => reject(error))
    });
  }

  const saveHoliday = (values) => {
    updateHoliday(values);
  }

  return (
    <>
      <Form className="location-form" noValidate onSubmit={handleSubmit(saveHoliday)}>
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.holidays.form.package_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.holidays.form.package_name') })}
            {...register('name')}
            isInvalid={!!errors.name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('pages.locations.title')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <AsyncSelect
            isMulti
            className="w-100 react-select"
            isClearable={true}
            value={selectedLocations}
            onChange={(e) => {
              setSelectedLocations(e)
              setValue('locations', e)
            }}
            placeholder={t('common.search_for_a_locations')}
            defaultOptions={true}
            loadOptions={getLocations}/>
          {
            (errors.locations && errors.locations.message) &&
            <div className="invalid-feedback d-block">{errors.locations.message}</div>
          }
        </Form.Group>
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('pages.holidays.form.filters')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <AsyncSelect
              isMulti
              className="w-100 react-select"
              isClearable={true}
              value={selectedFilters}
              onChange={(e) => {
                setSelectedFilters(e)
                setValue('filters', e)
              }}
              placeholder={t('common.search_for_a_filters')}
              defaultOptions={true}
              loadOptions={getHotelFilters}/>
          {
              (errors.filters && errors.filters.message) &&
              <div className="invalid-feedback d-block">{errors.filters.message}</div>
          }
        </Form.Group>
        <Form.Group className="my-4" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('pages.holidays.form.airports')}
          </Form.Label>
          <AsyncSelect
              isMulti
              className="w-100 react-select"
              isClearable={true}
              value={selectedAirports}
              onChange={(e) => {
                setSelectedAirports(e)
                setValue('airports', e)
              }}
              placeholder={t('common.search_for_airports')}
              defaultOptions={true}
              loadOptions={getAirports}/>
          {
              (errors.airports && errors.airports.message) &&
              <div className="invalid-feedback d-block">{errors.airports.message}</div>
          }
        </Form.Group>
        <Form.Group controlId="formFile" className="">
          <Form.Label>
            {t('common.form.featured_image')}
          </Form.Label>

          <ImageUpload
              errors={errors}
              setValue={setValue}
              register={register}
              fieldName="featured_image"
              holidayType={holiday}
          />
        </Form.Group>
        <Form.Group controlId="formFile" className="">
          <Form.Label>
            {t('common.form.thumbnail')}
          </Form.Label>

          <ImageUpload
              errors={errors}
              setValue={setValue}
              register={register}
              fieldName="thumbnail"
              holidayType={holiday}
          />
        </Form.Group>
        <Form.Group controlId="formFile" className="">
          <Form.Label>
            {t('pages.holidays.form.show_on_trip_request_form')}
          </Form.Label>

          <Form.Check
              label={t('common.yes')}
              name="show_on_trip_request_form"
              value={1}
              type="checkbox"
              {...register('show_on_trip_request_form')}
          />
        </Form.Group>
        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-4">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold"
                   key={index}>{t(error)}</p>)
            }
          </div>
        }
        <Form.Group className="mt-5">
          {
              holiday?.id !== undefined && (holiday?.deal_type == null || holiday?.deal_type == undefined) &&
              <LoadingButton
                  loading={deleting}
                  type="button"
                  variant="danger"
                  onSubmit={deleteHolidayRequest}
                  className="text-white me-2 px-5 heading-text"
                  titleTranslationKey="pages.holidays.buttons.delete_holiday"
              />
          }
          <LoadingButton
            className="auth-btn text-white px-5 heading-text text-uppercase"
            loading={savingHoliday}
            variant="primary"
            titleTranslationKey={submitLabel}
            type="submit"
          />
        </Form.Group>
      </Form>
    </>
  )
}

export default HolidayForm