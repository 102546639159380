import Table, { useColumns } from '@/shared/Tables/Holidays'
import React, { useEffect, useState } from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { Col, Form, Row } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import { useForm } from 'react-hook-form'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import LOCATION from '@/constants/Location'

function Holidays () {

  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()
  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15, latest: 1 })

  const [searching, setSearching] = useState(false)
  const [loading, setLoading] = useState(true)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...query
    }
  })

  const setQueryData = (values) => {
    setQuery(values)
  }

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    )
  }, [query])

  return (
    <>
      <div className="py-3 px-4">
        <div className="d-flex justify-content-between">
          <AdminPageHeader
              mainTitle={t('pages.deals.holidays')}
          />
          <div className="d-flex">
            <Link className="btn btn-primary heading-text" to={`${LOCATION.HOLIDAY_TYPES.CREATE.path}`}>
              {t('pages.holidays.create_package')}
            </Link>
          </div>
        </div>
        <form className="mt-3" onSubmit={handleSubmit(setQueryData)}>
          <Row>
            <Form.Group className="col col-sm-6 col-md-3 col-12 mb-2 mb-md-1">
              <Form.Label>
                {t('common.search')}
              </Form.Label>
              <Form.Control
                  {...register('search')}
                  placeholder={t('common.search_for_anything')}
                  name="search"
              />
            </Form.Group>
            <Col className="col-sm-6 col-md-3 col-12 d-flex align-items-end">
              <div className="d-grid gap-2 w-100">
                <LoadingButton
                    type="submit"
                    onlySpinner={true}
                    loading={searching}
                    className="text-white mt-3 mt-md-0 heading-text"
                    titleTranslationKey="common.apply"
                />
              </div>
            </Col>
          </Row>
        </form>

        <div className="my-4 bg-white">
          <Table query={query} showPagination={true} onData={_ => setSearching(!_)}/>
        </div>
      </div>
    </>
  )
}

export default Holidays