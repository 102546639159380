import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import React from 'react'

function MonthsSelection({months, register, errors, favouriteMonths}){
  const {t} = useTranslation();
  return (
    <div className="mt-4">
      <Form.Label>{t('pages.profile.form.labels.favourite_months')}</Form.Label>
      <Row>
        {
          months.map((month, index) => {
            return (
              <Col className="col-lg-3 col-sm-4 col-6 my-2" key={index}>
                <Form.Check
                  inline
                  label={month}
                  id={`month-${index}`}
                  name="favourite_months"
                  value={index}
                  {...register('favourite_months')}
                />
              </Col>
            )
          })
        }
      </Row>
      {
        (errors.favourite_months && errors.favourite_months.message) &&
        <div className="invalid-feedback d-block">{errors.favourite_months.message}</div>
      }
    </div>
  )
}
export default MonthsSelection