import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faCalendar, faClock, faPlane } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import FlightConnectionIcon from '@/shared/components/Icons/FlightConnectionIcon'

function FlightLeg ({ outBoundFlight, inBoundFlight, shortCard, className, flight, deal, split }) {

  const { t } = useTranslation()

  return (
    <div className={`flight-leg ${className}`}>
      {
        split === true ?
            (
                <>
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faPlane}/>
                  <span className="me-4">{outBoundFlight.departure_airport.code}</span>
                  <FontAwesomeIcon size="sm" className="me-2 text-dark" icon={faArrowRightLong}/>
                  <span className="me-4">{inBoundFlight.departure_airport.code}</span>
                </div>
                  <div className="d-flex align-items-center">

                  {
                    (flight?.live_price && shortCard)  || deal?.custom_deal ?
                        <>
                          <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faClock}/>
                          <span className="me-4">{moment(outBoundFlight.departure_time).format(t('common.formats.short_time'))}</span>
                        </>
                        :
                        null
                  }
                  <span className="me-2">
          <FlightConnectionIcon size="18"/>
        </span>
                  <span className="me-4">
          {
            shortCard ?
                <>{outBoundFlight.total_stops}</> :
                <>{outBoundFlight.total_stops !== 0 ? outBoundFlight.total_stops : t('pages.deals.direct_flight')}</>
          }
          </span>
                </div>
                </>
            )
            :
            (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faPlane}/>
                  <span className="me-4">{outBoundFlight.departure_airport.code}</span>
                  <FontAwesomeIcon size="sm" className="me-2 text-dark" icon={faArrowRightLong}/>
                  <span className="me-4">{inBoundFlight.departure_airport.code}</span>

                  {
                    (flight?.live_price && shortCard)  || deal?.custom_deal ?
                        <>
                          <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faClock}/>
                          <span className="me-4">{moment(outBoundFlight.departure_time).format(t('common.formats.short_time'))}</span>
                        </>
                        :
                        null
                  }
                  <span className="me-2">
          <FlightConnectionIcon size="18"/>
        </span>
                  <span className="me-4">
          {
            shortCard ?
                <>{outBoundFlight.total_stops}</> :
                <>{outBoundFlight.total_stops !== 0 ? outBoundFlight.total_stops : t('pages.deals.direct_flight')}</>
          }
          </span>
                </div>
            )
      }

      {
        !shortCard &&
        <div className="d-flex align-items-center mt-2">
          <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faCalendar}/>
          <span
            className="me-3">{moment(outBoundFlight.departure_time).format(t('common.formats.short_year_date'))}</span>
          {
            flight?.live_price ?
            <>
              <FontAwesomeIcon size="sm" className="me-2 text-primary" icon={faClock}/>
              <span
                className="me-3">{moment(outBoundFlight.departure_time).format(t('common.formats.short_time'))}</span>
            </>
              :null
          }
        </div>
      }
    </div>
  )
}

export default FlightLeg