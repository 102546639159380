const errors = {
    error_401_message                                      : "401 | UNAUTHORIZED REQUEST",
    error_404_message                                      : "404 | PAGE NOT FOUND",
    error_0_message                                        : "No internet",
    page_error                                             : "PAGE ERROR!",
    sorry_an_error_has_occurred_to_this_page               : "Sorry, this page could not be loaded",
    you_are_not_authorized_to_access_the_page_you_requested: "Sorry, you are not authorized to access the page you requested",
    page_not_available                                     : "Sorry, the page you are looking for does not exist, have been removed, name changed or is temporary unavailable"
}
export default errors;
