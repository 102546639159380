import React, { useState } from 'react'
import LoadingButton from '../../../../shared/components/LoadingButton'
import AppModal from '../../../../shared/components/AppModal'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../services/Auth'
import LOCATION from '../../../../constants/Location'

function ManageUser ({ user }) {

  const { t } = useTranslation()

  const auth = useAuth()

  const [deletingUser, setDeletingUser] = useState(false)
  const [userDeleted, setUserDeleted] = useState(false)
  const [secondRequest, setSecondRequest] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)

  const deleteUserRequest = () => {

    setModalTitle(t('pages.users.notifications.delete_user_request.title'))
    setModalBody(t('pages.users.notifications.delete_user_request.body'))
    setConfirmText(t('common.dialogs.actions.yes'))
    setCancelText(t('common.dialogs.actions.no'))
    setShowModal(true)
  }

  const deleteUserRequestSecondRequest = () => {

    setModalTitle(t('pages.users.notifications.delete_user_request_second_request.title'))
    setModalBody(null)
    setConfirmText(t('common.dialogs.actions.yes'))
    setCancelText(t('common.dialogs.actions.no'))
    setShowModal(true)
  }

  const deleteUser = () => {
    setDeletingUser(true)
    auth.deleteRequest(`${LOCATION.USERS.API.path}/${user.id}`)
      .then(response => {
        setUserDeleted(true)
        setModalTitle(t('pages.users.notifications.user_deleted.title'))
        setModalBody(null)
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)

      })
      .catch(error => {
        setDeletingUser(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    if (userDeleted) {
      window.location.href = LOCATION.USERS.LIST.path
    } else if (!secondRequest) {
      setSecondRequest(true)
      deleteUserRequestSecondRequest()
    } else {
      deleteUser()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="d-grid">
        <LoadingButton
          type="button"
          onSubmit={deleteUserRequest}
          loading={deletingUser}
          variant="danger"
          className="heading-text text-white my-1"
          titleTranslationKey="pages.users.buttons.delete_user"
        />
      </div>
    </>
  )
}

export default ManageUser