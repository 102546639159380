import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import '@/assets/scss/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals'
import {QueryClient, QueryClientProvider} from "react-query";

import './i18n'

import LoadingCover from "@/shared/components/LoadingCover";

import * as Sentry from "@sentry/react";

import {PostHogProvider} from 'posthog-js/react'

const postHogOptions = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    maskAllInputs: true,
    maskInputFn: (text, element) => {
        if (element?.dataset?.type === 'nin' || element?.getAttribute('name') === 'ni_number') {
            return '*'.repeat(text.length)
        }

        return text;
    },
    persistence: 'localStorage+cookie',
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://02183b5ccdbd6849213abee01d320f5c@o4506428370321408.ingest.us.sentry.io/4507016738570240",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/api\.thetravelmum\.com/,
            /^https:\/\/app\.thetravelmum\.com/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

var pushState = window.history.pushState;

window.history.pushState = function () {
    var event = new Event('pushState');

    event.state = arguments[0];
    event.title = arguments[1];
    event.url = arguments[2];

    var result = pushState.apply(this, arguments);

    window.dispatchEvent(event);

    return result;
};

const root = createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
    <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={postHogOptions}
    >
        <Suspense fallback={<LoadingCover/>}>
            <QueryClientProvider client={queryClient}>
                <App/>
            </QueryClientProvider>
        </Suspense>
    </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
