const LOCATION = {
  ROOT: { path: '/' },

  AUTH: {
    SIGN_IN: { path: '/login' },
    UNSUBSCRIBE: { path: '/unsubscribe/:email' },
    SIGN_IN_WITH_EMAIL: { path: '/login/:email' },
    SIGN_OUT: { path: '/sign-out' },
    SIGN_UP: { path: '/sign-up' },
    SIGN_UP_TRAVEL_MAX: { path: '/sign-up/:max' },
    SIGN_UP_TRAVEL_MAX_TRIAL: { path: '/sign-up/:max' },
    INVITED: { path: '/r/:profile' },
    FORGOT_PASSWORD: { path: '/password/forgot' },
    RESET_PASSWORD: { path: '/password/reset/:email/:token' },
  },
  PROFILE: {
    EDIT: { path: '/profile' },
    EDIT_ACCOUNT_DETAILS: { path: '/profile/edit-account-details' },
    EDIT_ACCOUNT_DETAILS_VALIDATE: { path: '/profile/edit-account-details/:email/:code' },
    PREFERENCES: { path: '/profile/preferences' },
    PREFERENCES_API: { path: '/users/preferences' },
    SHOW: { path: '/profile/:id' },
    API: { path: '/users' },
  },
  BANNERS: {
    TRAVEL_MAX_UPSELL: { path: '/travel-max' },
  },
  NOTIFICATIONS: {
    LIST: { path: '/notifications' },
    API: { path: '/notifications' },
  },
  DEALS: {
    DEAL_TYPES: { path: '/deal-types' },
    LIST: { path: '/deals' },
    SEARCH: { path: '/deals/search' },
    SEARCH_RESULTS: { path: '/deals/search/:trip_request_id' },
    TOP_15: { path: '/deals/top-15' },
    CUSTOM_DEALS: { path: '/deals/custom-deals' },
    SHOW: { path: '/deals/:id' },
    TOP_DEALS: { path: '/deals/list/:type' },
    CUSTOM_DEALS_BY_REQUEST: { path: '/deals/custom-deals/:tripRequestId' },
    EDIT: { path: '/deals/:id/edit' },
    SHOW_DEAL_WEEK: { path: '/deals/:id/:week_id' },
    DEAL_FLIGHTS: { path: '/deals/:id/flights' },
    DEAL_HOTELS: { path: '/deals/:id/hotels' },
    API: {
      path: '/deals',
      PRESERVE: (id) => `/deals/${id}/preserve`,
      SELECT: (id) => `/deals/${id}/select`,
      DEAL: (id) => `/deals/${id}`,
      DELETE: (id) => `/deals/${id}`,
    },
  },
  TRIPS: {
    LIST: { path: '/trips' },
    START_YOUR_JOURNEY: { path: '/trips/start-your-journey' },
    START_YOUR_JOURNEY_STEP: { path: '/trips/start-your-journey/:step' },
    START_YOUR_JOURNEY_RESULTS: { path: '/trips/start-your-journey/results/:trip_request_id' },
    START_YOUR_JOURNEY_LOCATION_RESULTS: { path: '/trips/start-your-journey/results/:trip_request_id/:location_id' },
    REQUEST_A_TRIP: { path: '/trips/request-a-trip' },
    REQUEST_A_TRIP_CONTINUE: { path: '/trips/request-a-trip/:saved_request' },
    MY_SAVED_TRIPS: { path: '/trips/my-saved-trips' },
    WEEKEND_TRIPS: { path: '/trips/weekend-trips' },
    LONG_HAUL: { path: '/trips/long-haul' },
    TRIP_BY_DEFINED_PRICE_RANGE: { path: '/trips/trip-by-range' },
    SHOW: { path: '/trips/:id' },
    API: { path: '/trips' },
    REQUEST_API: { path: '/trip-requests' },
    API_REQUEST_API: { path: '/api-requests' },
    REQUESTS: { path: '/requests' },
    REQUEST_SHOW: { path: '/requests/:id' },
    HOLIDAY_REQUEST: { path: '/holiday-requests' },
  },
  DOWNLOADS: {
    DOWNLOAD: { path: '/downloads/:id' },
    API: { path: '/downloads' },
  },
  USERS: {
    LIST: { path: '/users' },
    SHOW: { path: '/users/:id' },
    CREATE: { path: '/users/create' },
    EDIT: { path: '/users/:id/edit' },
    API: { path: '/users' },
  },
  HOLIDAY_TYPES: {
    LIST: { path: '/holiday-types' },
    EDIT: { path: '/holiday-types/:id/edit' },
    CREATE: { path: '/holiday-types/create' },
    API: { path: '/holiday-types' },
  },
  HOTEL_FILTERS: {
    API: { path: '/hotel-filters' },
  },
  LOCATION_FEATURES: {
    API: { path: '/location-features' },
  },
  AIRPORTS: {
    LIST: { path: '/airports' },
    SHOW: { path: '/airports/:id' },
    API: { path: '/airports' },
  },
  DESTINATIONS: {
    LIST: { path: '/locations' },
    SHOW: { path: '/locations/:id' },
    EDIT: { path: '/locations/:id/edit' },
    CREATE: { path: '/locations/create' },
    API: { path: '/locations' },
  },
  SUB_LOCATIONS: {
    API: { path: '/sub-locations' },
  },
  COUNTRIES: {
    API: { path: '/countries' },
  },
  IMAGES: {
    API: { path: '/images' },
  },
  FLIGHTS: {
    API: { path: '/flights' },
  },
  HOTELS: {
    API: { path: '/hotels' },
  },
  ACCOMMODATIONS: {
    API: { path: '/accommodations' },
  },
  ROLES: {
    API: { path: '/roles' },
  },
  DEALS_WEEK: {
    SHOW_DEAL_WEEK: { path: '/deal-weeks/:id' },
    EDIT_DEAL_WEEK: { path: '/deal-weeks/:id/edit' },
    CREATE_DEAL_WEEK: { path: '/deal-weeks/create' },
    LIST: { path: '/deal-weeks' },
    API: { path: '/deal-weeks' },
  },
  DESTINATION_SPECIFIC_DEALS: {
    LIST: { path: '/destination-specific-deals' },
  },
  SUBSCRIPTIONS: {
    API: { path: '/subscriptions' },
  },
  TRAVEL_RESOURCES: {
    DOWNLOAD: { path: '/download-resources' },
    API: { path: '/travel-resources' },
  },
  TRANSACTIONS: {
    API: { path: '/transactions' },
  },
  PAYMENT_METHODS: {
    PAYMENT_SCREEN: { path: '/payment-screen' },
    CREATE: { path: '/payments/create' },
    CALLBACK: { path: '/payments-callback/:product/:response_type' },
    CREATE_AND_PAY: { path: '/payments/create/:source/:trial' },
    API: { path: '/payment-methods' },
  },
  MONTHS: {
    API: { path: '/months' },
  },
  DASHBOARD: {
    STATS: { path: '/stats' },
    API: { path: '/stats' },
  },
}

export default LOCATION