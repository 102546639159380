import LOCATION from '@/constants/Location'
import Deals from '@/pages/Deals'
import Deal from '@/pages/Deals/Deal'
import EditDeal from '@/pages/Admin/Deals/Edit';
import TopDeals from '@/pages/Deals/TopDeals'
import AdminDeals from '@/pages/Admin/Deals'
import SearchDeals from '@/pages/Admin/Deals/SearchDeals'
import DealFlights from '@/pages/Deals/DealFlights'
import DealHotels from '@/pages/Deals/DealHotels'

const Trips = [
  {
    path: LOCATION.DEALS.LIST.path,
    component: AdminDeals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.CUSTOM_DEALS.path,
    component: Deals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.SEARCH.path,
    component: SearchDeals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.SEARCH_RESULTS.path,
    component: AdminDeals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.DEAL_FLIGHTS.path,
    component: DealFlights,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.DEAL_HOTELS.path,
    component: DealHotels,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.SHOW.path,
    component: Deal,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.EDIT.path,
    component: EditDeal,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.TOP_DEALS.path,
    component: TopDeals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.DEALS.SHOW_DEAL_WEEK.path,
    component: Deal,
    exact: true,
    main: false,
  },
]

export default Trips