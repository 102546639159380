import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function TravelMaxFeatures () {
  const { t } = useTranslation()

  return (
    <ul className="list-group list-group-flush benefits-list mt-3">
      {/*<li className="list-group-item d-flex ps-0">*/}
      {/*  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>*/}
      {/*  <p className="mb-2 ">{t('common.travel_max_feature.features.see_the_results_of_deal_searches')}</p>*/}
      {/*</li>*/}
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p className="mb-2">{t('common.travel_max_feature.features.save_deals')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p className="mb-2">{t('common.travel_max_feature.features.deals_sent_to_inbox')}</p>
      </li>
      {/*<li className="list-group-item d-flex ps-0">*/}
      {/*  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>*/}
      {/*  <p className="mb-2">{t('common.travel_max_feature.features.view_to_15_deals')}</p>*/}
      {/*</li>*/}
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p className="mb-2">{t('common.travel_max_feature.features.access_to_members_area')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p
          className="mb-2">{t('common.travel_max_feature.features.set_preferences_to_receive_tailored_deals_without_the_need_to_search')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p
          className="mb-2">{t('common.travel_max_feature.features.browse_4_and_5_deals_from_your_local_airport')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p
          className="mb-2">{t('common.travel_max_feature.features.browse_weekend_trips_from_your_local_airport')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p
          className="mb-2">{t('common.travel_max_feature.features.browse_trips_under_200_from_your_local_airport')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p className="mb-2">{t('common.travel_max_feature.features.downloadable_resources')}</p>
      </li>
      <li className="list-group-item d-flex ps-0">
        <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
        <p
          className="mb-2">{t('common.travel_max_feature.features.our_personal_help_with_any_travel_query')}</p>
      </li>
    </ul>
  )
}

export default TravelMaxFeatures