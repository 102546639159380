import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/async'
import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { formatRecord } from '@/utility'

function LocationSelection ({ errors, register, unregister, reset, setValue, selectedLocations, label, inputName, exclude, holidayType, defaultOptions }) {

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const inputValidName = inputName ?? 'where_to';

  const { t } = useTranslation()
  const auth = useAuth()
  const [destinations, setDestinations] = useState(selectedLocations ?? [])
  const [selectedDestination, setSelectedDestination] = useState(null)

  const removeDestination = (indexToRemove) => {
    let destinationList = destinations.filter((_, index) => index !== indexToRemove)
    setDestinations([].concat(destinationList))
    setValue(`${inputValidName}`, destinationList)
    unregister(`${inputValidName}.[${indexToRemove}]`)
  }

  const addDestination = (destination) => {
    let destinationList = destinations
    destinationList.push(destination)
    setDestinations([].concat(destinationList))

    destinationList.forEach((_, index) => {
      setValue(`${inputValidName}.[${index}]`, _)
    })
    setSelectedDestination(null)
  }

  const getLocations = (search) => {

    requests.locations && requests?.locations?.abort && requests.locations.abort()

    return new Promise((resolve, reject) => {
      requests.locations = auth.getRequest(LOCATION.DESTINATIONS.API.path, { search, location_type: 1, holiday_type_id: holidayType?.id })

      requests.locations.then(response => {
        let destinationList = response.data?.data.filter(_ => !(destinations.map(_ => _.value).includes(_.id)) )

        if( exclude && exclude.length !== 0 ){
          destinationList = destinationList.filter(_ => !(exclude.map(_ => _.value).includes(_.id)));
        }
        resolve(destinationList.map(formatRecord))
      })
        .catch(error => reject(error))
    })
  }

  useEffect(() => {
    register(inputValidName)
  }, [])

  return (
    <>
      {label ? <Form.Label>{label}</Form.Label> : null}
      {
        destinations?.map((destination, index) => {
          return (
            <Form.Group className="mb-3 d-flex align-items-center" key={index}>
              <Form.Control
                readOnly={true}
                as="select"
                value={destination}
                isInvalid={!!(errors.where_to && errors.where_to[index])}
              >
                <option value={destination}>{destination.label}</option>
              </Form.Control>
              <FontAwesomeIcon
                className="remove-icon text-danger mx-2"
                role="button"
                onClick={() => removeDestination(index)}
                icon={faXmarkCircle}
              />
            </Form.Group>
          )
        })
      }
      <div className="d-flex align-items-center mb-3">
        <AsyncSelect
          className="w-100"
          isClearable={true}
          value={selectedDestination}
          onChange={(e) => {
            setSelectedDestination(e)
            if (e) {
              addDestination(e)
            }
          }}
          placeholder={t('common.search')}
          defaultOptions={defaultOptions != null ? defaultOptions : true}
          loadOptions={getLocations}/>
        <FontAwesomeIcon
          className={`remove-icon text-dark mx-2 ${!selectedDestination ? 'disabled' : ''}`}
          role="button"
          onClick={(e) => {
            e.preventDefault()
            if (selectedDestination) {
              addDestination(selectedDestination)
            }
          }}
          icon={faPlusCircle}
        />
      </div>

      {
        (errors && errors[inputValidName] && errors[inputValidName].message) &&
        <div className="invalid-feedback d-block">{errors[inputValidName].message}</div>
      }
    </>
  )
}

export default LocationSelection