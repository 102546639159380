import Budget from './Budget'
import HolidayType from './HolidayType'
import WhereFrom from './WhereFrom'
import WhereTo from './WhereTo'
import When from './When'
import WhoIsGoing from './WhoIsGoing'
import React from 'react'
import Summary from './Summary'
import WhatWillMakeItSpecial from "./WhatWillMakeItSpecial";

function StepControl ({ step, holidayTypes, modelEditor, showTravelMaxBanner, continueWithDeal, requestComplete, saveActionRef, handleConfirm, handleCancel }) {
  return (
    <>
      <>
        {(step === 'budget' || !step) &&
          <Budget
            modelEditor={modelEditor}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        }
        {(step === 'holiday-type') &&
          <HolidayType
            holidayTypes={holidayTypes}
            modelEditor={modelEditor}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        }
        {(step === 'where-from') &&
          <WhereFrom
            modelEditor={modelEditor}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        }
        {(step === 'where-to') &&
          <WhereTo
            holidayTypes={holidayTypes}
            modelEditor={modelEditor}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        }
        {(step === 'when') &&
          <When
            modelEditor={modelEditor}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        }
        {(step === 'who-is-going') &&
          <WhoIsGoing
            modelEditor={modelEditor}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        }
        {/*{(step === 'what-will-make-it-special') &&*/}
        {/*  <WhatWillMakeItSpecial*/}
        {/*    modelEditor={modelEditor}*/}
        {/*    handleConfirm={handleConfirm}*/}
        {/*    handleCancel={handleCancel}*/}
        {/*    holidayTypes={holidayTypes}*/}
        {/*  />*/}
        {/*}*/}
        {(step === 'summary') &&
          <Summary
            showTravelMaxBanner={showTravelMaxBanner}
            holidayTypes={holidayTypes}
            continueWithDeal={continueWithDeal}
            requestComplete={requestComplete}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />}
      </>
    </>
  )

}

export default StepControl