import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import LocationSearch from "../../Deals/components/LocationSearch";
import UserSeach from "../../Deals/components/UserSeach";
import LoadingButton from "../../../../shared/components/LoadingButton";
import AirportSearch from "../../Users/components/AirportSearch";
import useGet from "../../../../hooks/useGet";
import LOCATION from "../../../../constants/Location";
import Select from "react-select";
import {useState} from "react";

function TripRequestsFilter({ searching,values,loadDeals,}){

  const { t } = useTranslation()
  const {
    resetField,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...values
    }
  })


  const { data: dealTypes, loading } = useGet(LOCATION.DEALS.DEAL_TYPES.path)

  const [dealType, setDealType] = useState(values?.deal_type_id);
  const onSubmit = (values) => {
    loadDeals(values)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Label>
            {t('common.search')}
          </Form.Label>
          <Form.Control
            {...register('search')}
            placeholder={t('common.search_for_anything')}
            name="search"
          />
        </Form.Group>


        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('common.airport')}
            </Form.Label>
            <AirportSearch
              setValue={setValue}
              placeHolder={t('common.search')}
            />
          </Form.Group>
        </Form.Group>


        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('pages.locations.location')}
            </Form.Label>
            <LocationSearch
              setValue={setValue}
              placeHolder={t('common.search')}
            />
          </Form.Group>
        </Form.Group>


        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('common.user')}
            </Form.Label>
            <UserSeach
              setValue={setValue}
              placeHolder={t('common.search')}
            />
          </Form.Group>
        </Form.Group>


        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('common.deal_type')}
            </Form.Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isLoading={loading}
              isClearable={true}
              isSearchable={true}

              value={dealType}
              onChange={(e) => {
                setDealType(e)
                setValue('deal_type_id', e?.value)
              }}
              options={dealTypes?.map(_ => {
                return { value: _.id, label: _.name }
              })}
            />
          </Form.Group>
        </Form.Group>

        <Col className="col-sm-6 col-md-2 col-12 mb-2 mb-md-1 d-flex align-items-end">
          <div className="d-grid gap-2 w-100">
            <LoadingButton
              type="submit"
              onlySpinner={true}
              loading={searching}
              className="text-white mt-3 mt-md-0 heading-text"
              titleTranslationKey="common.apply"
            />
          </div>
        </Col>
      </Row>
    </form>
  )
}

export default TripRequestsFilter