import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Paginate from "../Paginate";
import {Link} from "react-router-dom";
import {Badge} from "react-bootstrap";
import LOCATION from "../../constants/Location";
import {useAuth} from "../../services/Auth";
import {getDates, getRequestProgressStatus} from "../../utility";
import moment from "moment/moment";


function useColumns(filter) {
  const {t} = useTranslation()

  let columns = [
    {
      dataField: 'id',
      text: t('pages.trip_requests.table.labels.id'),
      formatter: (cell, row) => {
        return <Link to={`${LOCATION.TRIPS.REQUESTS.path}/${row?.id}`}
                     className="table-links text-nowrap">{cell}</Link>
      },
    },
    {
      dataField: 'user',
      text: t('pages.trip_requests.table.labels.user'),
      formatter: (cell, row) => {
        return <Link to={`${LOCATION.USERS.LIST.path}/${cell?.id}`}
                     className="table-links text-nowrap">{cell?.name}</Link>
      },
    },
    {
      dataField: 'Budget',
      text: t('pages.trips.steps.summary.step_headings.budget'),
      formatter: (cell, row) => {
        return <div>
          {t('pages.trips.steps.summary.values.budget_summary', {
            min: row?.min_budget,
            max: row?.max_budget
          })}
        </div>
      },
    },
    {
      dataField: 'holiday_type',
      text: t('pages.trips.steps.summary.step_headings.holiday_type'),
      formatter: (cell, row) => {
        return <div>
          {row?.surprise_holiday_type ?
            <span>{t('pages.trips.steps.holiday_type.types.surprise')}</span>
            :
            <span>{row?.holiday_type?.name}</span>
          }
        </div>
      },
    },
    {
      dataField: 'holiday_type',
      text: t('pages.trips.steps.summary.step_headings.where_from'),
      formatter: (cell, row) => {
        return <span>
          {row?.airports?.map(_ => _.region_name).join(', ')}
        </span>
      },
    },
    {
      dataField: 'holiday_type',
      text: t('pages.trips.steps.summary.step_headings.where_to'),
      formatter: (cell, row) => {
        return <div>

          {
            (row?.holiday_type_id && (row?.any_location_holiday || row?.locations?.length === 0)) ?
              <span>{`Any ${row?.holiday_type?.name} Break`}</span> :
              row?.surprise_holiday_type ?
                <span>{t('pages.trips.steps.holiday_type.types.surprise')}</span>
                :
                <span>{row?.locations?.map(_ => _.destination).join(', ')}</span>
          }
        </div>
      },
    },
    {
      dataField: 'holiday_type',
      text: t('pages.trips.steps.summary.step_headings.who_is_going'),
      formatter: (cell, row) => {
        return <div>
          {t('pages.trips.steps.summary.values.adults', {count: row.adults})}
          {
            (row?.children && row?.children?.length !== 0) ?
              <div>
                {t('pages.trips.steps.summary.values.children', {count: row?.children})},&nbsp;
                ({t('pages.trips.steps.summary.values.children_ages', {count: row?.children_ages?.join(', ')})})
              </div>
              : null
          }
        </div>
      },
    },
    {
      dataField: 'holiday_type',
      text: t('pages.trips.steps.summary.step_headings.when'),
      formatter: (cell, row) => {
        return <div>
          {getDates(row, t)},&nbsp;{t('pages.trips.form.labels.when.night', {count: row?.how_long})}
        </div>
      },
    },
    {
      dataField: 'deals',
      text: t('pages.trip_requests.table.labels.deals_found'),
      formatter: (cell, row) => {
        return cell?.length;
      },
    },
    {
      dataField: 'progress_status',
      text: t('common.status'),
      formatter: (cell, row) => {
        return getRequestProgressStatus(row, t);
      },
    },
    // {
    //   dataField: 'api_calls',
    //   text: t('pages.trip_requests.table.labels.api_calls'),
    //   formatter: (cell, row) => {
    //     let hotelCalls = cell?.filter(_ => _.type === 1);
    //     return (
    //       <>
    //         <span className="text-success me-1">{ hotelCalls.filter(_ => _.status === 200)?.length }</span>-
    //         <span className="text-danger ms-1">{ hotelCalls.filter(_ => _.status !== 200)?.length }</span>
    //       </>
    //     )
    //   },
    // },
  ]

  return filter && columns.filter(filter) || columns
}

function TripRequests({data: recordsProp, paginate, url, query, ...props}) {
  let auth = useAuth()

  const {t} = useTranslation()

  const perPage = query.limit || 20
  const exclude = props.exclude || [{dataField: auth.user.id}]
  const showPagination = paginate == null ? true : paginate

  const request = useRef(null)
  const [page, setPage] = useState(0)
  const [totalRecords, setTotal] = useState(false)
  const [recordsDownloaded, setRecords] = useState(false)

  const getRecords = () => {
    request?.current?.abort && request.current.abort()

    const critera = {...(query || {})}

    critera.take = perPage
    critera.skip = page * perPage

    request.current = auth.getRequest(url || LOCATION.TRIPS.REQUEST_API.path, critera)

    setRecords(false)

    request.current
      .then(response => {
        setRecords(response.data.data)
        setTotal(response.data.length)
      })
  }

  const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name))

  const changePage = (event) => {
    setPage(event.selected)
  }

  const records = recordsProp || recordsDownloaded

  useEffect(() => {
    if (!recordsProp) {
      getRecords()
    }

    return () => request?.current?.abort && request.current.abort()
  }, [page, query, url])

  useEffect(() => {
    props.onData && props.onData(records)
  }, [records])

  useEffect(() => {
    props.onTotal && props.onTotal(totalRecords)
  }, [totalRecords])

  return (
    <div className="list-table requests-table">
      <BootstrapTable
        keyField="id"
        responsive
        hover
        columns={columns || []}
        data={records || []}
        noDataIndication={records instanceof Array ? t('common.no_data') : t('common.loading')}
        {...props}
      />
      {
        showPagination && records && totalRecords &&
        <Paginate {...{changePage, totalRecords, page, perPage}} />
        || ''
      }
    </div>
  )
}

export default TripRequests;

export {
  useColumns
}