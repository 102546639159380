import { Card } from 'react-bootstrap'
import LOCATION from '@/constants/Location'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import OnPageNotification from "../../shared/components/OnPageNotification";
import React from "react";

function Error404(){
  const {t} = useTranslation();


  return (
    <OnPageNotification
      notificationTitle={t('common.errors.titles.error404')}
      notificationDescription={t('common.errors.body.error404')}
      notificationLinkText={t('common.go_home')}
      notificationAction={() => window.location.href = LOCATION.ROOT.path}
    />
  )
}
export default Error404;