import React, { createContext, createRef, useContext, useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import { useLocation, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from '@/layouts/Navbar'
import ScrollToTop from '@/shared/components/ScrollToTop'
// import { RollbarContext } from '@rollbar/react'
import routes from '@/router'
import Error401 from '@/pages/errors/Error403'
import LoadingCover from '@/shared/components/LoadingCover'
import Aside from './Aside'
import Error500 from '@/pages/errors/Error500'
import Error404 from '@/pages/errors/Error404'
import Error403 from '@/pages/errors/Error403'
import ConnectionError from '@/pages/errors/ConnectionError'
import Footer from './Footer'
import LOCATION from '../constants/Location'
import ReactGA from "react-ga4";

ReactGA.initialize('G-JBE4L2JTJ5');

const mainContext = createContext()

export function ProvideMain ({ children }) {
  const ref = useProvideMain()

  return <mainContext.Provider value={ref}>{children}</mainContext.Provider>
}

function useProvideMain () {
  const ref = createRef()

  return {
    ref,
    scrollTop: () => {
      ref?.current?.scrollTo && ref.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
  }
}

export const useMain = () => {
  return useContext(mainContext)
}

function Main (props) {

  let auth = useAuth()
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(true)

  const [collapsed, setCollapsed] = useState(false)
  const [toggled, setToggled] = useState(false)

  const handleToggleSidebar = (value) => {
    setToggled(value)
  }

  const main = useMain()

  const checkSearchStorage = () => {

    if (!pathname.includes(LOCATION.TRIPS.START_YOUR_JOURNEY.path)) {
      localStorage.removeItem('trip_values')
    }
  }

  useEffect(() => {
    auth.updateErrorPage(false)
    main.scrollTop()
    checkSearchStorage()

      try {
          ReactGA.send({ hitType: "pageview", page: pathname });
      } catch (error) {
          console.error(error);
      }
  }, [pathname])

  useEffect(() => {
    const tokenCheck = auth.checkToken()
    tokenCheck
      .then(response => {
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })

    return () => tokenCheck?.abort && tokenCheck.abort()
  }, [])

  return loading ? <LoadingCover/> :
    <>
      {(auth.user && auth.user.admin) ?
        <Aside
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        /> : null
      }
      <main ref={main.ref}>
        <Navbar
          handleToggleSidebar={handleToggleSidebar}
          collapsed={collapsed}
          toggled={toggled}
        />

        <div className={`main-container mb-3 ${auth.user?.admin ? 'admin' : ''}`}>
          {
            (auth?.errorPage === false || auth?.errorPage === 401) &&
            <ScrollToTop>
              <Switch>
                {routes.map((route, i) => (
                  route.public ? <PublicRoute key={i} {...route}/> : <PrivateRoute key={i} {...route}/>
                ))}
              </Switch>
            </ScrollToTop>
          }

          {auth?.errorPage === 500 ? <Error500/> : null}
          {auth?.errorPage === 404 ? <Error404/> : null}
          {auth?.errorPage === 403 ? <Error403/> : null}
          {auth?.errorPage === 0 ? <ConnectionError/> : null}
        </div>

        <Footer/>
      </main>
    </>
}

function PublicRoute (route) {
  let auth = useAuth()
  return (
    <Route
      path={route.path}
      render={props => (
        auth?.user && !route.authVisit ?
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
          :
          // <RollbarContext context={route.path}>
            <route.component {...props} routes={route.routes}/>
          // </RollbarContext>
      )}
    />
  )
}

function PrivateRoute (route) {
  let auth = useAuth()
  return (
    <Route
      path={route.path}
      render={props =>
        (auth.user || auth?.errorPage === 401) ? (
          (route.gate === undefined || auth.user.abilities[route.gate]) ? (
            // <RollbarContext context={route.path}>
              <route.component {...props} routes={route.routes}/>
            // </RollbarContext>
          ) : (
            <Error401/>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

function Content (props) {
  return <ProvideMain><Main {...props} /></ProvideMain>
}

export default Content
