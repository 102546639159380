import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {useEffect} from "react";
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React from 'react'
import LOCATION from '@/constants/Location'
import OnPageNotification from "../../shared/components/OnPageNotification";

function Error500() {
    const {t} = useTranslation();
    let auth = useAuth();

    useEffect(() => {
        auth?.errorPage || (auth?.updateErrorPage && auth.updateErrorPage(true));

        return () => {
            auth?.updateErrorPage && auth.updateErrorPage(false);
        }
    }, []);

    return (
      <div className="container d-flex align-items-center justify-content-center h-100">
          <OnPageNotification
            notificationTitle={t('common.errors.titles.error500')}
            notificationDescription={t('common.errors.body.error500')}
            notificationLinkText={t('common.go_home')}
            notificationAction={() => window.location.href = LOCATION.ROOT.path}
            // notificationDescriptionColored={auth?.errorMessage}
          />
      </div>
    )
}
export  default Error500;
