import LOCATION from '@/constants/Location'
import PaymentsPage from '@/pages/Payments/Payments'
import PaymentScreen from '@/screens/PaymentScreen'
import PaymentCallback from "@/pages/Payments/PaymentCallback";

const Payments = [
  {
    path: LOCATION.PAYMENT_METHODS.CREATE.path,
    component: PaymentsPage,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.PAYMENT_METHODS.CREATE_AND_PAY.path,
    component: PaymentsPage,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.PAYMENT_METHODS.PAYMENT_SCREEN.path,
    component: PaymentScreen,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.PAYMENT_METHODS.CALLBACK.path,
    component: PaymentCallback,
    exact: true,
    main: false,
  },
]
export default Payments