import { Col, Row } from 'react-bootstrap'

function PageHeader({
  title,
  bgColor,
  textColor,
  suffixElements,
  prefixElements,
  className,
}) {

  return (
    <Row className={`bg-${bgColor ?? 'dark'} text-${textColor ?? 'white'} ${className ?? ''} py-2`}>
      <Col className="col-4 d-flex justify-content-start d-none d-sm-flex">
        {
          (prefixElements && prefixElements.length !== 0) ?
            prefixElements.map((_, index) => {
              return <div key={index}>{_}</div>
            })
            : <span>&nbsp;</span>
        }
      </Col>
      <Col className="col-sm-4 col-12  d-flex justify-content-center align-items-center">
        <h4 className="page-title text-uppercase heading-text my-2 w-100 text-center">{title}</h4>
      </Col>
      <Col className="col-4 d-flex justify-content-end  d-none d-sm-flex">
        {
          (suffixElements && suffixElements.length !== 0) ?
            suffixElements.map((_, index) => {
              return <div key={index}>{_}</div>
            })
            : <span>&nbsp;</span>
        }
      </Col>
    </Row>
  )
}

export default PageHeader;