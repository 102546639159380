import { useTranslation } from 'react-i18next'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import LocationForm from './components/LocationForm'
import React, { useState } from 'react'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import AppModal from '@/shared/components/AppModal'
import { getValidationErrors } from '@/utility'
import { Helmet } from 'react-helmet'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Create (props) {
  const { t } = useTranslation()
  const auth = useAuth()

  const [savingLocation, setSavingLocation] = useState(false)
  const [location, setLocation] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [serverErrors, setServerErrors] = useState([])

  const saveLocation = (values) => {

    let data = values;
    data.airports = values.airports?.map(_ => _.value);

    setSavingLocation(true)

    auth.postRequest(`${LOCATION.DESTINATIONS.API.path}`, data)
      .then(response => {
        setLocation(response.data?.data)
        setSavingLocation(false)
        setModalTitle(t('pages.locations.notifications.location_created.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {
        setSavingLocation(false)
        setServerErrors(getValidationErrors(error))
      })
  }
  const handleConfirm = () => {
    setShowModal(false)
    props.history.push(`${LOCATION.DESTINATIONS.LIST.path}/${location?.id}/edit`)
  }
  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />

      <Helmet>
        <title>{t('pages.locations.create_location')} - {t('pages.locations.title')} - {t('common.app')}</title>
      </Helmet>

      <div className="p-4">
        <Breadcrumb className="text-dark heading-text">
          <Breadcrumb.Item className="">
            <Link to={LOCATION.DESTINATIONS.LIST.path}>{t('pages.locations.title')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('pages.locations.create_location')}</Breadcrumb.Item>
        </Breadcrumb>
        <LocationForm
          saveLocation={saveLocation}
          submitting={savingLocation}
          serverErrors={serverErrors}
          submitLabel={t('pages.locations.buttons.create_location')}
        />
      </div>
    </>
  )
}

export default Create