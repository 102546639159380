import moment from "moment-timezone";

export function formatRecord (record) {
  const object = { ...record }

  object.value = record.id
  object.label = record.name
  if (record.country && record.country?.name) {
    if (record.location_type) {
      object.label = `${record.destination}`
    } else {
      object.label = `${record.name}, ${record.country?.name}`
    }
  }

  return object
}

export function getArrayElement (list) {
  return list[Math.floor((Math.random() * list.length))]
}

export function getNumberOfTravelers (deal) {

  let adults = deal?.trip_request?.adults ?? 1
  let children = deal?.trip_request?.children
  if (deal?.deal_type_id && deal?.user?.preferences) {

    if (deal.adults) {
      adults = deal?.adults
      children = deal?.children
    } else {
      adults = deal?.user?.preferences?.adults
      children = deal?.user?.preferences?.children
    }

  }

  let numberOfTravelers = adults
  if (children) {
    numberOfTravelers += children
  }

  return numberOfTravelers
}

export function getPricePerPerson (totalPrice, deal) {

  let numberOfTravelers = getNumberOfTravelers(deal)

  return (totalPrice / numberOfTravelers).toFixed(2)
}

export function isValidHttpUrl (string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function getValidationErrors (error) {
  let validationErrors = []
  if (error.response.status === 422) {
    if (error.response.data.errors) {
      for (const key in error.response.data.errors) {
        validationErrors.push(
          error.response.data.errors[key][0]
        )
      }
    } else {
      validationErrors.push(error.response.data.message)
    }
  }
  return validationErrors
}

export function getCheapestPrice (deals) {
  let prices = deals.map(_ => _.total_amount)

  return Math.min.apply(Math, prices)
}

export function setTripLink (path, values, history, route) {
  history.replace(
    path,
    {
      ...route.state,
      tripValues: values
    }
  )
}

export function isValidData (tripValues) {
  return tripValues?.budget?.length !== 0 &&
    tripValues?.holiday_type &&
    tripValues?.who?.adults &&
    tripValues?.when?.how_long &&
    (
      (tripValues?.when?.by_specific_date && tripValues?.when?.request_dates) ||
      tripValues?.when?.months
    )
}

export function getDates(tripRequest, t){
  let dates = []
  if (tripRequest?.trip_dates && tripRequest?.trip_dates.length !== 0) {
    tripRequest?.trip_dates?.forEach((dateRanges) => {
      if (dateRanges) {
        dates.push([moment(dateRanges[0])?.format(t('common.formats.short_date')), moment(dateRanges[1])?.format(t('common.formats.short_date'))].join( ' - ' ))
      }
    })
  } else {
    tripRequest?.months.forEach(month => {
      if (month) {
        dates.push(month)
      }
    })
  }

  return dates.join(', ')
}

export function getRequestProgressStatus(tripRequest, t){
  let currentTime = moment.tz(moment(), "Europe/London").format('YYYY-MM-DD HH:mm:ss');

  let duration  =  moment.duration(moment(tripRequest?.estimated_complete_time).diff(moment(currentTime)));

  if( duration.asMinutes() <= -15 ){
    if((tripRequest?.search_completed || !tripRequest?.estimated_complete_time) && tripRequest?.progress_status === 'On Queue'){
      return t('common.complete')
    }
    if( tripRequest?.search_completed && tripRequest?.progress_status !== 'On Queue' ){

      if( tripRequest.deals?.length == 0 && tripRequest.hotel_api_limit_reached ){
        return `${tripRequest?.progress_status} - ${t('common.limit_reached')}`;
      }

      return  tripRequest?.progress_status;
    }
    return t('common.incomplete')
  }

  if( tripRequest?.progress_status === 'On Queue' ){
    let estimatedTime = moment(tripRequest?.estimated_complete_time).fromNow()

    if (estimatedTime.includes('ago')) {
      estimatedTime = ' in a few seconds'
    }
    return t('pages.trip_requests.table.on_queue', {estimatedTime});
  }
  else{
    if( tripRequest.deals?.length == 0 && tripRequest.hotel_api_limit_reached ){
      return `${tripRequest?.progress_status} - ${t('common.limit_reached')}`;
    }
    return tripRequest?.progress_status;
  }
}