import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import StartYourJourney from '../../Trips/StartYourJourney'

function SearchDeals () {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('pages.top_15_deals.buttons.search_for_deals')} - {t('pages.top_15_deals.title')} - {t('common.app')}</title>
      </Helmet>

      <StartYourJourney/>
    </>
  )
}

export default SearchDeals