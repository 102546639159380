import LOCATION from '@/constants/Location'
import HolidaysPage from '@/pages/Admin/Holidays'
import Edit from '@/pages/Admin/Holidays/Edit'
import Create from '@/pages/Admin/Holidays/Create'

const Holidays = [
  {
    path: LOCATION.HOLIDAY_TYPES.LIST.path,
    component: HolidaysPage,
    exact: true,
    main: false,
    gate: 'holidays',
  },
  {
    path: LOCATION.HOLIDAY_TYPES.EDIT.path,
    component: Edit,
    exact: true,
    main: false,
    gate: 'holidays',
  },
  {
    path: LOCATION.HOLIDAY_TYPES.CREATE.path,
    component: Create,
    exact: true,
    main: false,
    gate: 'holidays',
  },
]

export default Holidays