import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { Button, Col, Row } from 'react-bootstrap'
import NotificationCard from './components/NotificationCard'
import React, { useEffect, useState } from 'react'
import LoadingCover from '@/shared/components/LoadingCover'
import AppModal from '@/shared/components/AppModal'
import LOCATION from '@/constants/Location'

function Notifications () {

  const { t } = useTranslation()
  const auth = useAuth()

  const notification = auth.newNotification

  const [notifications, setNotifications] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(t('common.buttons.cancel'))

  const [loading, setLoading] = useState(true)
  const [dismissAction, setDismissAction] = useState(null)
  const [dismissMessage, setDismissMessage] = useState(null)
  const [notificationToDismiss, setNotificationToDismiss] = useState(null)

  const confirmDismiss = (notification, type) => {
    setDismissAction(type)

    if (type === 'ALL') {
      setModalTitle(t('common.notifications.dismiss_all_notification.title'))
    } else {
      setModalTitle(t('common.notifications.dismiss_this_notification.title'))
    }

    setConfirmText(t('common.buttons.dismiss'))

    setShowModal(true)
    if (notification != null) {
      setNotificationToDismiss(notification)
    }
  }

  const dismissAllNotifications = () => {
    setLoading(true)
    handleCancel()
    auth.getRequest(`${LOCATION.NOTIFICATIONS.API.path}/dismiss`)
      .then(response => {
        getNotifications();
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const dismissNotifications = () => {
    setLoading(true)
    handleCancel()
    if (notificationToDismiss) {
      auth.deleteRequest(`${LOCATION.NOTIFICATIONS.API.path}/${notificationToDismiss.id}`)
        .then(response => {
          getNotifications();
        })
        .catch(error => {
          setLoading(false)
        })
    }
  }

  const handleConfirm = () => {
    setShowModal(false)
    if (notificationToDismiss && dismissAction !== 'ALL') {
      dismissNotifications()
    } else if (dismissAction === 'ALL') {
      dismissAllNotifications()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
    setNotificationToDismiss(null)
    setDismissAction(null)
  }

  const getNotifications = () => {
    return auth.getRequest(LOCATION.NOTIFICATIONS.API.path)
      .then(response => {

        setLoading(false)
        setNotifications(response.data?.filter(_ => !!_));

      })
      .catch(error => {

        setLoading(false)
      })
  }

  useEffect(() => {
    let request = getNotifications()
    return () => request?.abort && request.abort()
  }, [notification])


  if (loading) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="my-3">{t('pages.profile.your_latest_notifications')}</h5>

          {
            notifications.length > 1 &&
            <Button
              className="heading-text text-white"
              onClick={() => confirmDismiss(null, 'ALL')}
            >
              {t('common.buttons.dismiss_all')}
            </Button>
          }
        </div>
        <Row>
          {
            notifications.length === 0 ? <p className="text-danger">No new Notifications</p> :
              notifications.map(notification => {
                return (
                  <Col className="col col-12 col-md-6 col-lg-4 my-2" key={notification.id}>
                    <NotificationCard
                      notification={notification}
                      dismissNotification={confirmDismiss}
                    />
                  </Col>
                )
              })
          }
        </Row>
      </div>
    </>
  )
}

export default Notifications