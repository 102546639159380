import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import CityCard from '@Pages/Deals/components/CityCard'
import { useAuth } from '@/services/Auth'
import AppModal from '@/shared/components/AppModal'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import OnPageNotification from '@/shared/components/OnPageNotification'

function MySavedTrips () {

  const { t } = useTranslation()

  const auth = useAuth()
  const [processing, setProcessing] = useState(true)
  const [sortBy, setSortBy] = useState('price')

  const [showModal, setShowModal] = useState(false)
  const [tripToRemove, setTripToRemove] = useState(false)
  const [trips, setTrips] = useState([])

  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)

  const handleConfirm = () => {
    setShowModal(false)
    if (tripToRemove) {
      removeTrip()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
    setTripToRemove(null)
  }

  const goToBook = () => {

  }
  const removeTripRequest = (trip) => {
    setTripToRemove(trip)

    setModalTitle(t('pages.trips.notifications.remove_trip.title'))
    setModalBody(t('pages.trips.notifications.remove_trip.body'))
    setConfirmText(t('common.dialogs.actions.remove'))
    setCancelText(t('common.dialogs.actions.cancel'))
    setShowModal(true)
  }
  const removeTrip = () => {
    setProcessing(true)
    auth.deleteRequest(`${LOCATION.TRIPS.API.path}/${tripToRemove.id}`)
      .then(response => {
        auth.checkToken();
        setTripToRemove(null)
        setProcessing(false)
        getTrips()

      })
      .catch(error => {
        setProcessing(false)
      })
  }
  const getTrips = () => {
    setProcessing(true)
    return auth.getRequest(`${LOCATION.TRIPS.API.path}`, {
      sort_by: sortBy,
    })
      .then(response => {
        setTrips(response.data.data)
        setProcessing(false)
      })
      .catch(error => {
        setProcessing(false)
      })
  }

  useEffect(() => {
    let request = getTrips()
    return () => request?.abort && request.abort()
  }, [sortBy])

  if (processing) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="container p-md-2 p-4">
        {
          auth.user.user_type ==='travel_lite' &&
          <Row className="my-4">
            <p>{t('pages.trips.travel_light_member_trip_saving_statement')}</p>
          </Row>
        }
        <Row className="my-4">
          <Col className="col-md-4 col-12 d-grid gap-2">
            <Link to={LOCATION.TRIPS.START_YOUR_JOURNEY.path}
                  className="px-4 btn btn-primary text-dark heading-text">{t('pages.trips.buttons.start_your_journey')}</Link>
          </Col>
        </Row>
        {
          trips?.length === 0 ?
            <OnPageNotification
              notificationTitle={t('pages.trips.notifications.no_trips_found.title')}
              notificationDescription={t('pages.trips.notifications.no_trips_found.body')}
              notificationIcon={<FontAwesomeIcon icon={faXmarkCircle}/>}
            />
            :
            <>
              <Row className="my-4">
                <Col className="col-md-4 col-12">
                  <Form.Control
                    as="select"
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value)
                    }}
                  >
                    <option value="price">{t('pages.trips.select_options.sort_by_price')}</option>
                    <option value="date">{t('pages.trips.select_options.sort_by_date')}</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                {
                  trips?.map(trip => {
                    return (
                      <Col className="col-lg-4 col-md-6 col-12 mb-4" key={trip.id}>
                        <CityCard
                          deal={trip}
                          fullCard={false}
                          dealCard={true}
                          goToBook={goToBook}
                          removeTrip={removeTripRequest}
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            </>
        }

      </div>
    </>
  )

}

export default MySavedTrips