import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/async'
import React, { useEffect, useRef, useState } from 'react'
import { formatRecord } from '@/utility'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { useTranslation } from 'react-i18next'

function AirportSelection ({
  errors,
  register,
  unregister,
  reset,
  selectedAirports,
  setValue,
  label,
  fieldName,
  filter,
  controlled,
  smallTextLabel,
  holidayType,
  unregisterOnRemove = true,
  max = undefined
}) {

  const auth = useAuth()
  const { t } = useTranslation()

  const [userAirports, setUserAirports] = useState(selectedAirports ?? [])
  const [selectedAirport, setSelectedAirport] = useState(null)

  const field = fieldName ?? 'where_from'

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const removeAirport = (indexToRemove) => {
    let airports = userAirports.filter((_, index) => index !== indexToRemove);
    setUserAirports([].concat(airports))

    setValue(`${field}`, airports)
    if (unregisterOnRemove) {
      unregister(`${field}.[${indexToRemove}]`)
    }
  }

  const addAirport = (airport) => {
    let airports = userAirports
    airports.push(airport);
    if (max === undefined || userAirports.length <= max) {
      setUserAirports([].concat(airports));
    }

    airports.forEach((_, index) => {
      setValue(`${field}.[${index}]`, _)
    })
    setSelectedAirport(null)
  }

  const getAirports = (search) => {

    requests.airports && requests?.airports?.abort && requests.airports.abort()

    return new Promise((resolve, reject) => {
      requests.airports = auth.getRequest(LOCATION.AIRPORTS.API.path, {
        take: 100,
        search,
        filter,
        holiday_type_id: holidayType ?? null
      })

      requests.airports.then(response => {
        let airports = response.data?.data.filter(_ => !(userAirports.map(_ => _.value).includes(_.id)))
        resolve(airports.map(_ => {
          return { value: _.id, label: `${_.code} - ${_.name}` }
        }))
      })
        .catch(error => reject(error))
    })
  }

  useEffect(() => {
    register(field)
  }, [])

  return (
    <>

      {label ? <Form.Label>{label}</Form.Label> : null}
      {
        userAirports.map((airport, index) => {
          return (
            <>
              {
                controlled && index > 0 ?

                  smallTextLabel ?
                    <p
                      className="mb-1">{t('pages.trips.steps.where_from.from_alternative_airport')}</p>
                    :
                    <h6
                      className="heading-text fw-bold request-process-heading">{t('pages.trips.steps.where_from.from_alternative_airport')}</h6>
                  : null
              }
              <Form.Group className="mb-3 d-flex align-items-center" key={index}>
                <Form.Control
                  readOnly={true}
                  as="select"
                  value={airport}
                  isInvalid={!!(errors && errors[field] && errors[field][index])}
                >
                  <option value={airport}>{airport.label}</option>
                </Form.Control>
                <FontAwesomeIcon
                  className="remove-icon text-danger mx-2"
                  role="button"
                  onClick={() => removeAirport(index)}
                  icon={faXmarkCircle}
                />
              </Form.Group>
            </>
          )
        })
      }

      {
        (max === undefined || (max > 0 && userAirports.length < max)) && (userAirports.length < 2 || !controlled) ?
          <>
            {
              userAirports.length === 1 && controlled ?
                smallTextLabel ?
                  <p
                    className="mb-1">{t('pages.trips.steps.where_from.from_have_alternative_airport')}</p>
                  :
                  <h6
                    className="heading-text fw-bold request-process-heading">{t('pages.trips.steps.where_from.from_have_alternative_airport')}</h6>
                : null
            }
            <div className="d-flex align-items-center mb-3">
              <AsyncSelect
                className="w-100"
                isClearable={true}
                value={selectedAirport}
                onChange={(e) => {
                  setSelectedAirport(e)
                  if (e) {
                    addAirport(e)
                  }
                }}
                placeholder={t('common.search')}
                defaultOptions={true}
                loadOptions={getAirports}/>
              <FontAwesomeIcon
                className={`remove-icon text-dark mx-2 ${!selectedAirport ? 'disabled' : ''}`}
                role="button"
                onClick={(e) => {
                  e.preventDefault()
                  if (selectedAirport) {
                    addAirport(selectedAirport)
                  }
                }}
                icon={faPlusCircle}
              />
            </div>

            {
              (errors && errors[field] && errors[field].message) &&
              <div className="invalid-feedback d-block">{errors[field].message}</div>
            }
          </>
          :
          null
      }

    </>
  )

}

export default AirportSelection