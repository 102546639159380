import { useAuth } from '@/services/Auth'
import { useRef, useState } from 'react'
import LOCATION from '@/constants/Location'
import AsyncSelect from 'react-select/async'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function DealSelection ({ setValue, placeHolder, deal, saveDeals, deals }) {
  const auth = useAuth()
  const { t } = useTranslation()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [selectedDeal, setSelectedDeal] = useState(deal ? { value: deal?.id, label: deal?.name } : null)

  const getDeals = (search) => {

    requests.locations && requests?.locations?.abort && requests.locations.abort()

    return new Promise((resolve, reject) => {
      requests.locations = auth.getRequest(LOCATION.DEALS.API.path, { search })

      requests.locations.then(response => {
        resolve(response.data?.data?.filter((_ => !deals?.map(_ => _.id).includes(_.id))).map(_ => {
          return { ..._,
            value: _.id,
            label: `${t('pages.top_15_deals.deal_headline', {
              id: _.id,
              airportName: _.airport?.name,
              destination: _.location?.name,
              duration: _.trip_request?.how_long,
              totalAmount: _.total_amount,
              currencySymbol: _.currency_symbol,
              tripDate: moment(_.date).format(t('common.formats.short_date')),
            })}${
              t('common.total_price_from', {
                currency: _.currency_symbol,
                price: _.total_amount,
              })
            }`
          }
        }))
      })
        .catch(error => reject(error))
    })
  }

  return (
    <>
      <AsyncSelect
        className="w-100 react-select"
        isClearable={true}
        value={selectedDeal}
        onChange={(e) => {
          if (setValue) setValue('deal_id', e?.value)
          if (saveDeals) saveDeals(e)
        }}
        placeholder={placeHolder}
        loadOptions={getDeals}/>
    </>
  )

}

export default DealSelection