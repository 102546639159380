import LoadingButton from '@/shared/components/LoadingButton'
import { useAuth } from '@/services/Auth'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LOCATION from '@/constants/Location'
import AppModal from '@/shared/components/AppModal'

function ManageSubscription ({ user }) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)

  const auth = useAuth()
  const [cancelingSubscription, setCancelingSubscription] = useState(false)
  const [grantingSubscription, setGrantingSubscription] = useState(false)
  const [refunding, setRefunding] = useState(false)

  const requestCancelSubscription = () => {

    setCancelingSubscription(true)

    setModalTitle(t('pages.payments.notifications.cancel_subscription.title'))
    setModalBody(t('pages.payments.notifications.cancel_subscription.body'))
    setConfirmText(t('common.dialogs.actions.yes'))
    setCancelText(t('common.dialogs.actions.no'))
    setShowModal(true)
  }

  const requestRefundSubscription = () => {

    setModalTitle(t('pages.payments.notifications.refund_subscription.title'))
    setModalBody(t('pages.payments.notifications.refund_subscription.body'))
    setConfirmText(t('common.dialogs.actions.yes'))
    setCancelText(t('common.dialogs.actions.no'))
    setShowModal(true)

    setRefunding(true)
  }

  const cancelSubscription = () => {
    auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${user.id}/delete`)
      .then(response => {
        setCancelingSubscription(false)
        setModalTitle(t('pages.payments.notifications.subscription_canceled.title'))
        setModalBody(null);
        setCancelText(null);
        setShowModal(true)
      })
      .catch(error => {
        setCancelingSubscription(false)
      })
  }

  const refundSubscription = () => {

    auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${user.id}/refund`)
      .then(response => {
        setRefunding(false)
        setModalTitle(t('pages.payments.notifications.subscription_refunded.title'))
        setModalBody(null);
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null);
        setShowModal(true)
      })
      .catch(error => {
        setRefunding(false)
      })
  }

  const requestGrantSubscription = () => {
    setGrantingSubscription(true);
    setModalTitle(t('pages.payments.notifications.upgrade_travel_max.title'))
    setModalBody(t('pages.payments.notifications.upgrade_travel_max.body'))
    setConfirmText(t('common.dialogs.actions.yes'))
    setCancelText(t('common.dialogs.actions.no'))
    setShowModal(true)
  }

  const grantSubscription = () => {
    auth.postRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${user.id}/upgrade`)
      .then(response => {
        setGrantingSubscription(false);
        setModalTitle(t('pages.payments.notifications.upgraded_travel_max.title'))
        setModalBody(null);
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null);
        setShowModal(true)
      })
      .catch(error => {
        setGrantingSubscription(false);
      })
  }

  const handleConfirm = () => {
    setShowModal(false)

    if( cancelingSubscription ){
      cancelSubscription();
    }

    else if( refunding ){
      refundSubscription();
    }
    else if(grantingSubscription){
      grantSubscription();
    }
    else {
      window.location.reload();
    }

    setModalBody(null);
    setCancelText(null);
  }

  const handleCancel = () => {
    setShowModal(false)
    setCancelingSubscription(false);
    setGrantingSubscription(false);
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <div className="d-grid gap-2">
        {
          user.user_type === 'travel_max' ?
            <LoadingButton
              type="button"
              onSubmit={requestCancelSubscription}
              loading={cancelingSubscription}
              variant="danger"
              className="heading-text text-white my-1"
              titleTranslationKey="pages.users.buttons.delete_subscription"
            />
            :
            <LoadingButton
              type="button"
              onSubmit={requestGrantSubscription}
              loading={grantingSubscription}
              variant="warning"
              className="heading-text text-white my-1"
              titleTranslationKey="pages.users.buttons.upgrade_travel_max"
            />
        }

        {/*<LoadingButton*/}
        {/*  type="button"*/}
        {/*  loading={refunding}*/}
        {/*  onSubmit={requestRefundSubscription}*/}
        {/*  variant="dark"*/}
        {/*  className="heading-text text-white my-1"*/}
        {/*  titleTranslationKey="pages.users.buttons.refund_subscription"*/}
        {/*/>*/}
      </div>
    </>
  )
}

export default ManageSubscription