import { Form, Row } from 'react-bootstrap'
import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function TravelerSelection ({ register, errors, watch, unregister, className,labelClass, namePrefix, required, deal }) {

  const { t } = useTranslation()
  const [numberOfChildren, setNumberOfChildren] = useState(null)

  const children = watch(`${namePrefix ? namePrefix + '.' :  ''}children`)

  useEffect(() => {

    if (children < numberOfChildren) {
      for (let index = children; index <= numberOfChildren; index++) {
        unregister(`${namePrefix ? namePrefix + '.' : ''}children_ages.[${index}]`)
      }
    }
    setNumberOfChildren(children)

  }, [children])


  return (
    <>
      <Row className={`${className}`}>
        <Form.Group className="col col-6">
          <Form.Label className={`${labelClass}`}>
            {t('pages.trips.form.labels.who_is_traveling_adults.adults')}
            {required && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            as="select"
            {...register(`${namePrefix ? namePrefix + '.' :  ''}adults`)}
            isInvalid={!!(errors && errors.adults)}
            value={deal?.adults}
          >
            <option value="" disabled selected>
              {t('common.choose')}
            </option>
            {
              [...Array(10)].map((number, index) => {
                return <option value={index + 1} key={index}>{index + 1}</option>
              })
            }
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            {errors && errors.adults && errors.adults.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-6">
          <Form.Label className={`${labelClass}`}>
            {t('pages.trips.form.labels.who_is_traveling_adults.children')}
          </Form.Label>
          <Form.Control
            as="select"
            {...register(`${namePrefix ? namePrefix + '.' :  ''}children`)}
            value={deal?.children}
          >
            <option value="" disabled selected>
              {t('common.choose')}
            </option>
            <option value={0}>{t('common.none')}</option>
            {
              [...Array(10)].map((number, index) => {
                return <option value={index + 1} key={index}>{index + 1}</option>
              })
            }
          </Form.Control>
        </Form.Group>
      </Row>
      {
        (children && children != 0) ?
          [...Array(parseInt(children))].map((number, index) => {
            return (
              <Form.Group className="my-4">
                <Form.Label className={`${labelClass}`}>
                  {t('pages.trips.form.labels.who_is_traveling_adults.children_label_count', { count: (index + 1) })}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  {...register(`${namePrefix ? namePrefix + '.' : ''}children_ages.[${index}]`)}
                  isInvalid={!!(errors && errors.children_ages && errors.children_ages[index])}
                  value={deal?.children_ages[index]}
                >
                  <option value="" disabled selected>
                    {t('common.choose')}
                  </option>
                  {
                    [...Array(16)].map((number, index) => {
                      return <option value={index} key={index}>{index}</option>
                    })
                  }
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                  {errors && errors.children_ages && errors.children_ages[index] && errors.children_ages[index].message}
                </Form.Control.Feedback>
              </Form.Group>
            )
          }) : null
      }
    </>
  )
}

export default TravelerSelection