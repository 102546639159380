import React, { useEffect, useState } from 'react'
import OnPageNotification from '../../shared/components/OnPageNotification'
import LoadingCover from '../../shared/components/LoadingCover'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../services/Auth'
import LOCATION from '../../constants/Location'
import { useTranslation } from 'react-i18next'
import Error500 from '../errors/Error500'

function Unsubscribe () {

  const auth = useAuth()

  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const { email } = useParams()

  const unsubscribe = () => {
    return auth.postRequest(`${LOCATION.USERS.API.path}/unsubscribe`, {
      email
    })
      .then(response => {
        setLoading(false)
      })
      .catch(error => {

        setLoading(false)
        setLoading(true)
      })
  }

  useEffect(() => {
    if (email) {
      let requests = unsubscribe()

      return () => requests.abort && requests.abort()
    }
  }, [])

  if (loading) return <LoadingCover/>

  if(error) return <Error500/>

  return (
    <>
      <OnPageNotification
        notificationTitle={t('common.notifications.unsubscribed_to_deals_email.title')}
        notificationDescription={t('common.notifications.unsubscribed_to_deals_email.body')}
        notificationLinkText={t('common.okay')}
        notificationAction={() => window.location.href = LOCATION.ROOT.path}
      />
    </>
  )
}

export default Unsubscribe