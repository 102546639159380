import {Spinner} from "react-bootstrap";
import LoadingSpinner from './LoadingSpinner'

const LoadingCover = () => {
    return (
        <div className="w-100 d-flex justify-content-center align-items-center" style={{height: '100%'}}>
            <LoadingSpinner/>
        </div>
    )
}

export default LoadingCover;
