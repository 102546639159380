import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { getValidationErrors } from '@/utility'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import LoadingCover from '@/shared/components/LoadingCover'
import AppModal from '@/shared/components/AppModal'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import UserForm from './components/UserForm'
import { Breadcrumb } from 'react-bootstrap'

function Edit (props) {

  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [savingUser, setSavingUser] = useState(false)
  const [user, setUser] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [confirmText, setConfirmText] = useState(null)

  const [serverErrors, setServerErrors] = useState([])

  const updateUser = (values) => {
    setServerErrors([])
    setSavingUser(true)
    auth.putRequest(`${LOCATION.USERS.LIST.path}/${id}`, values)
      .then(response => {
        setSavingUser(false)
        setModalTitle(t('pages.users.notifications.admin_updated.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {
        setSavingUser(false)
        setServerErrors(getValidationErrors(error))
      })
  }

  const getUser = () => {
    return auth.getRequest(`${LOCATION.USERS.LIST.path}/${id}`)
      .then(response => {
        setUser(response?.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    props.history.push(`${LOCATION.USERS.LIST.path}/${id}`)
  }

  useEffect(() => {
    let result = getUser()
    return () => result?.abort && result.abort()
  }, [])

  if (loading) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />
      <Helmet>
        <title>{t('pages.users.buttons.edit_details')} - {t('pages.users.title')} - {t('common.app')}</title>
      </Helmet>

      <div className="p-4">
        <Breadcrumb className="text-dark heading-text">
          <Breadcrumb.Item className="">
            <Link to={LOCATION.USERS.LIST.path}>{t('pages.users.title')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('pages.users.buttons.edit_details')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{user.name}</Breadcrumb.Item>
        </Breadcrumb>
        <UserForm
          user={user}
          updateUser={updateUser}
          savingUser={savingUser}
          serverErrors={serverErrors}
          submitLabel="pages.users.buttons.edit_details"
        />
      </div>
    </>
  )
}

export default Edit