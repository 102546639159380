import LOCATION from '@/constants/Location'
import UsersPage from '@/pages/Admin/Users'
import Show from '@/pages/Admin/Users/Show'
import Edit from '@/pages/Admin/Users/Edit'
import Create from '@/pages/Admin/Users/Create'

const Users = [
  {
    path: LOCATION.USERS.LIST.path,
    component: UsersPage,
    exact: true,
    main: false,
    gate: 'users',
  },
  {
    path: LOCATION.USERS.CREATE.path,
    component: Create,
    exact: true,
    main: false,
    gate: 'users',
  },
  {
    path: LOCATION.USERS.SHOW.path,
    component: Show,
    exact: true,
    main: false,
    gate: 'users',
  },
  {
    path: LOCATION.USERS.EDIT.path,
    component: Edit,
    exact: true,
    main: false,
    gate: 'users',
  },
]

export default Users