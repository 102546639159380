import { useAuth } from '@/services/Auth'
import { useRef, useState } from 'react'
import LOCATION from '@/constants/Location'
import AsyncSelect from 'react-select/async'

function LocationSearch ({ setValue, placeHolder, location, fieldName }) {
  const auth = useAuth()

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const [selectedLocation, setSelectedLocation] = useState(location ? { value: location?.id, label: location?.name } : null)

  const geLocations = (search) => {

    requests.locations && requests?.locations?.abort && requests.locations.abort()

    return new Promise((resolve, reject) => {
      requests.locations = auth.getRequest(LOCATION.DESTINATIONS.API.path, { search })

      requests.locations.then(response => {
        resolve(response.data?.data?.map(_ => {
          return { value: _.id, label: _.name }
        }))
      })
        .catch(error => reject(error))
    })
  }

  return (
    <>
      <AsyncSelect
        className="w-100 react-select"
        isClearable={true}
        value={selectedLocation}
        onChange={(e) => {
          setSelectedLocation(e)
          setValue(fieldName ?? 'location_id', e?.value)
        }}
        placeholder={placeHolder}
        defaultOptions={true}
        loadOptions={geLocations}/>
    </>
  )

}

export default LocationSearch