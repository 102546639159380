import { Col, Row } from 'react-bootstrap'
import OnPageNotification from '@/shared/components/OnPageNotification'

function AuthLayout ({
  title,
  subtitle,
  showNotification,
  notificationTitle,
  notificationDescription,
  notificationLink,
  notificationLinkText,
  lite,
  trial,
  ...props
}) {
  return (
    <Row className="" style={{ height: '100%' }}>
      {
        trial ?
          <Col className="col-md-6 col-12 border-0 d-flex justify-content-end">
            <div className={`trial-container px-4 mt-3 mt-md-5 px-md-5 `}>
            {subtitle}
            </div>
          </Col>
          : null
      }
      <Col className={`col-md-6 col-12 border-0 d-flex ${trial ? 'justify-content-start' : 'align-items-center justify-content-center'}`}>
        <div className={`login-container p-4 p-md-5`}>
          {
            showNotification ?
              <OnPageNotification
                notificationTitle={notificationTitle}
                notificationDescription={notificationDescription}
                notificationLink={notificationLink}
                notificationLinkText={notificationLinkText}
              />
              :
              <>
                {
                  !trial ?
                    <>
                      <Row>
                        <h4 className="text-uppercase text-dark fw-bold heading-text auth-header my-2">{title}</h4>
                        {
                          typeof subtitle === 'string' ?
                            <p className="mt-2 text-muted">{subtitle}</p> :
                            subtitle
                        }
                      </Row>
                      <Row>
                        <Col>
                          <hr/>
                        </Col>
                      </Row>
                    </>
                    : null
                }

                <Row className="auth-form mt-3">
                  {props.children}
                </Row>
              </>
          }
        </div>
      </Col>
      {
        !trial ?
          <Col className={`col-12 col-md-6 login-background d-none d-sm-block ${lite ? 'travel-lite' : ''}`}></Col>
          : null
      }
    </Row>
  )
}

export default AuthLayout