import LOCATION from '@/constants/Location'
import DestinationSpecificDealsPage from '@/pages/Admin/DestinationSpecificDeals'
import CreateDealWeek from '@/pages/Admin/Top15Deals/CreateDealWeek'
import ShowDealWeek from '@/pages/Admin/Top15Deals/ShowDealWeek'
import EditDealWeek from '@/pages/Admin/Top15Deals/EditDealWeek'

const Top15Deals = [
  {
    path: LOCATION.DESTINATION_SPECIFIC_DEALS.LIST.path,
    component: DestinationSpecificDealsPage,
    exact: true,
    main: false,
    gate: 'destination-specific-deals',
  },
  // {
  //   path: LOCATION.DEALS_WEEK.CREATE_DEAL_WEEK.path,
  //   component: CreateDealWeek,
  //   exact: true,
  //   main: false,
  //   gate: 'deal-weeks',
  // },
  // {
  //   path: LOCATION.DEALS_WEEK.SHOW_DEAL_WEEK.path,
  //   component: ShowDealWeek,
  //   exact: true,
  //   main: false,
  //   gate: 'deal-weeks',
  // },
  // {
  //   path: LOCATION.DEALS_WEEK.EDIT_DEAL_WEEK.path,
  //   component: EditDealWeek,
  //   exact: true,
  //   main: false,
  //   gate: 'deal-weeks',
  // },
]

export default Top15Deals;