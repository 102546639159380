const ForgotPassword = {
  title: 'Forgot password?',
  instructions: 'Please provide your email and click on the button below to reset your password',
  reset: 'Reset Your Password',
  success: {
    title: 'Password Reset Link Sent',
    description: 'Please follow the instructions we send on your email to reset your password',
  },
}

export default ForgotPassword