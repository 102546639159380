import { Elements, useStripe } from '@stripe/react-stripe-js'
import { useEffect } from 'react'
import { useAuth } from '../../../services/Auth'
import LOCATION from '../../../constants/Location'

function ConfirmPayment ({ paymentIntentData, stripePromise, subscriptionConfirmed, setServerError, setLoading }) {
  return (
    <Elements stripe={stripePromise}>
      <ConfirmObject
        paymentIntentData={paymentIntentData}
        subscriptionConfirmed={subscriptionConfirmed}
        setServerError={setServerError}
        setLoading={setLoading}
      />
    </Elements>
  )
}

function ConfirmObject ({ paymentIntentData, subscriptionConfirmed, setServerError, setLoading }) {

  const stripe = useStripe()

  const auth = useAuth()

  const updateSubscriptionStatus = () => {
    auth.putRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${paymentIntentData.subscription_id}/confirmed`)
      .then(response => {
        subscriptionConfirmed()
      })
      .catch(error => {
        setLoading('payment_services_error')
      })
  }

  useEffect(() => {

    if (stripe) {

      stripe.confirmCardPayment(paymentIntentData.payment_intent, {})
        .then(function (result) {
          if (result.paymentIntent.status === 'succeeded') {
            updateSubscriptionStatus()
          } else {
            setServerError('payment_declined')
            setLoading(false)
          }
          // Handle result.error or result.paymentIntent
        })
        .catch(error => {
          setServerError('payment_declined')
          setLoading(false)

          setTimeout(() => {
            window.location.reload();
          }, 2000)
        })
    }
  }, [stripe])

  return <></>
}

export default ConfirmPayment
