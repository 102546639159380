import { useTranslation } from 'react-i18next'
import {Button, Form, Row} from 'react-bootstrap'
import React, { useState } from 'react'
import LoadingButton from '@/shared/components/LoadingButton'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'

function ImageUpload ({ register, errors, namePrefix, setValue, index, watch, removeImage, imageLink }) {
  const auth = useAuth();

  const baseUrl = auth.api.defaults.baseURL

  const [uploadingImage, setUploadingImage] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)
  const { t } = useTranslation()

  const uploadImage = (image) => {
    setUploadingImage(true)
    let imageData = image.target.files[0]
    let form = new FormData()
    form.append('image', imageData)
    form.append('type', 'hotel-images')

    auth.postRequest(`${LOCATION.DEALS.API.path}/upload-image`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          setUploadedImage(`${baseUrl}/${response.data}`)
          setValue(`${namePrefix}images.${index}`, `${baseUrl}/${response.data}`)
          setUploadingImage(false)
        })
        .catch(error => {
        })
  }

  return (
      <div className="image-repeater">
        <Form.Group className="image-input" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.images')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
              placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.images') })}
              {...register(`${namePrefix}images.${index}`)}
              isInvalid={!!(errors && errors.images && errors.images[index])}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.images[index] && errors.images[index].message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="btn-image-upload" controlId="formBasicName">
          <div className="position-relative w-100">
            <div className="d-grid gap-2">
              <LoadingButton
                  loading={uploadingImage}
                  variant="dark"
                  className="text-white mb-2 heading-text"
                  type="button"
                  titleTranslationKey="pages.top_15_deals.form.or_upload_image"
              />
            </div>
            <Form.Control
                className="avatar-upload-input"
                onChange={(e) => uploadImage(e)}
                type="file"
                accept="image/*,.jpg,.png,.jpeg"
            />
          </div>
        </Form.Group>
		

		
        
          {
              (imageLink || uploadedImage) &&
				<Form.Group className="image-full-preview" controlId="formBasicName">
              <img
                  src={uploadedImage ?? imageLink}
                  className="w-100"
                  alt="hotel_image"
              />
			   </Form.Group>
          }
       

		<div class="btn-remove-image">
		<Button variant="danger" className="heading-text text-white" onClick={() => removeImage(index)}>
          {t('pages.top_15_deals.form.remove_image')}
        </Button>
		</div>
        
      </div>
  );
}

function HotelForm ({ register, errors, namePrefix, setValue, index, watch, hotel }) {

  const { t } = useTranslation();

  const images = watch(`${namePrefix}images`, []);

  const addImage = () => {
    setValue(`${namePrefix}images`, [...images, null])
  }

  const removeImage = (index) => {
    setValue(`${namePrefix}images`, images.filter((image, i) => i !== index))
  }

  return (
    <>
      <hr/>
      <p>{t('pages.top_15_deals.headers.hotel_details')}</p>
      <Row className="">
        <input
          type="hidden"
          {...register(`${namePrefix}id`)}
          value={hotel?.id}
        />
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.hotel_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.hotel_name') })}
            {...register(`${namePrefix}name`)}
            isInvalid={!!(errors && errors.name)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.name && errors.name.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.booking_link')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.booking_link') })}
            {...register(`${namePrefix}link`)}
            isInvalid={!!(errors && errors.link)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.link && errors.link.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4 col col-12" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.hotel_description')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.hotel_description') })}
            {...register(`${namePrefix}hotel_teaser`)}
            isInvalid={!!(errors && errors.hotel_teaser)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.hotel_teaser && errors.hotel_teaser.message}
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.total_hotel_price')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            number="textarea"
            step={0.1}
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.total_hotel_price') })}
            {...register(`${namePrefix}price`)}
            isInvalid={!!(errors && errors.price)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.price && errors.price.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.hotel_amenities')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="string"
            placeholder={t('pages.top_15_deals.form.hotel_amenities_placeholder')}
            {...register(`${namePrefix}hotel_amenities`)}
            isInvalid={!!(errors && errors.hotel_amenities)}
          />

          <Form.Control.Feedback type="invalid">
            {errors && errors.hotel_amenities && errors.hotel_amenities.message}
          </Form.Control.Feedback>
        </Form.Group>



        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.star_rating')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            min={1}
            max={5}
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.star_rating') })}
            {...register(`${namePrefix}star_rating`)}
            isInvalid={!!(errors && errors.star_rating)}
          >

            {
              [...Array(5)].map((counter, index) => <option key={index} value={index + 1}>{index + 1}</option>)
            }
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            {errors && errors.star_rating && errors.star_rating.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 col col-12 col-md-6" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.guest_star_rating')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            min={1}
            max={10}
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.guest_star_rating') })}
            {...register(`${namePrefix}guest_star_rating`)}
            isInvalid={!!(errors && errors.guest_star_rating)}
          >
            {
              [...Array(10)].map((counter, index )=> <option key={index} value={index + 1}>{index + 1}</option>)
            }
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            {errors && errors.guest_star_rating && errors.guest_star_rating.message}
          </Form.Control.Feedback>
        </Form.Group>

        {
            images && images.map((image, index) => {
                return (
                    <ImageUpload
                        key={index}
                        register={register}
                        errors={errors}
                        namePrefix={namePrefix}
                        setValue={setValue}
                        index={index}
                        watch={watch}
                        removeImage={removeImage}
                        imageLink={image?.url ?? null}
                    />
                )
            })
        }
		<div className="add-image-repeater-button">
			<Button variant="primary" onClick={addImage} className="btn btn-primary heading-text text-white">
			  {t('pages.top_15_deals.form.add_image')}
			</Button>
		</div>
      </Row>
    </>
  )
}

export default HotelForm