import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

function TrialBenefits () {
  const { t } = useTranslation()
  return (
    <>
      <h4 className="my-3 heading-text head">
        {t('common.travel_max_trial.title')}
      </h4>
      <h6 className="my-4 fw-semibold">
        {t('common.travel_max_trial.subtitle')}
      </h6>
      <p className="my-4"> {t('common.travel_max_trial.trial_statement')}</p>
      <ul className="list-group list-group-flush benefits-list">
        <li className="list-group-item d-flex">
          <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
          {t('common.travel_max_trial.features.gain_exclusive_entry')}
        </li>
        <li className="list-group-item d-flex">
          <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
          {t('common.travel_max_trial.features.take_control')}
        </li>
        <li className="list-group-item  d-flex">
          <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
          {t('common.travel_max_trial.features.limitless_potential')}
        </li>
        <li className="list-group-item d-flex">
          <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
          {t('common.travel_max_trial.features.multiple_adventures')}
        </li>
        <li className="list-group-item d-flex">
          <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
          {t('common.travel_max_trial.features.passionate_community')}
        </li>
      </ul>
    </>
  )
}

export default TrialBenefits