import { useAuth } from '../../services/Auth'
import { useTranslation } from 'react-i18next'
import React, { useRef, useState, useEffect } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import AuthLayout from './AuthLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import useGet from '@/hooks/useGet'
import LoadingCover from '@/shared/components/LoadingCover'
import LOCATION from '@/constants/Location'
import { formatRecord } from '@/utility'
import AirportSelection from '../Trips/Request/components/AirportSelection'
import ReCAPTCHA from 'react-google-recaptcha'
import TrialBenefits from '../Banners/TrialBenefits'
import Location from '../../constants/Location'

function SignUp () {

  const auth = useAuth()
  const { t } = useTranslation()
  const [signingUp, setSigningUp] = useState(false)

  const recaptchaRef = useRef()
  const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

  const intended = localStorage.getItem('intended');

  const { max } = useParams()

  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])
  const [selectedAirport, setSelectedAirport] = useState(null)
  const [requestValues, setRequestValues] = useState(null)
  const [travelMaxTrial, setTravelMaxTrial] = useState(false)
  const [trialLoading, setTrialLoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const requestStore = useRef({})
  const { current: requests } = requestStore

  const submitRef = useRef(null)

  let signUpInitialValues = localStorage.getItem('sign_up_values')
  let initialValues = max === 'trial' && signUpInitialValues ? JSON.parse(signUpInitialValues) : {}

  let passwordMinLength = 6

  const schema = yup.object().shape({
    first_name: yup.string()
      .required(t('common.form_validation.first_name_is_required')),
    last_name: yup.string()
      .required(t('common.form_validation.last_name_is_required')),
    where_from: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.where_from.title') })),
        label: yup.string(),
      })),
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.is_required', { attribute: t('common.form.email') })),
    password: yup.string()
      .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', { length: passwordMinLength }))
      .required(t('common.form_validation.password_is_required')),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
      .required(t('common.form_validation.password_confirm_required')),
  })

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: (_ => {
      return {
        first_name: initialValues?.first_name,
        last_name: initialValues?.last_name,
        email: initialValues?.email,
        password: initialValues?.password,
        password_confirmation: initialValues?.password_confirmation,
        where_from: initialValues?.where_from,
      }
    })(),
  })

  const signUp = async (values) => {

    setServerError('')
    setSigningUp(true)


    localStorage.removeItem('sign_up_values');

    const token = await recaptchaRef.current.executeAsync()
    values.airports = values.where_from?.map(_ => _.value)
    values.max_user_request = max;

    if (token) {

      auth.signUp(values)
        .then(response => {
          setSigningUp(false)
          setTrialLoading(false)

          if (max || travelMaxTrial) {
            if (max === 'trial' || travelMaxTrial) {
              window.location.href = `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/1`
            } else {
              window.location.href = `${LOCATION.PAYMENT_METHODS.CREATE.path}/subscribe/0`
            }
          } else if (requestValues) {
            window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary`
          } else if(intended) {
            localStorage.removeItem('intended');
            window.location.href = intended;
          } else {
            window.location.href = '/'
          }
        })
        .catch(error => {
          setSigningUp(false)
          setTrialLoading(false)
          setTravelMaxTrial(false)
          if (error?.response?.status === 401) {
            setServerError(error.response.data.message)
          }

          if (error.response.status === 422) {
            let serverErrors = []
            for (const key in error.response.data.errors) {
              serverErrors.push(
                error.response.data.errors[key][0]
              )
            }
            setServerErrors(serverErrors)
          }
        })
    } else {
      setServerError(t('common.errors.recaptcha_error'))
    }
  }

  const tryTravelMax = () => {
    setTravelMaxTrial(true)
    setTrialLoading(true)

    localStorage.setItem('sign_up_values', JSON.stringify(getValues()))

    window.location.href = `${Location.AUTH.SIGN_UP.path}/trial`
  }

  const getAirports = (search) => {

    requests.airports && requests?.airports?.abort && requests.airports.abort()

    return new Promise((resolve, reject) => {
      requests.airports = auth.getRequest(LOCATION.AIRPORTS.API.path, { search })

      requests.airports.then(response => {
        resolve(response.data?.data.map(formatRecord))
      })
        .catch(error => reject(error))
    })
  }

  useEffect(() => {
    let requestData = localStorage.getItem('request_data')
    if (requestData) {
      setRequestValues(JSON.parse(requestData))
    }

  }, [])

  return (
    <AuthLayout
      title={max ? t('pages.signup.title_max') : t('pages.signup.title')}
      subtitle={<MemberBenefits/>}
      lite={!max}
      trial={max === 'trial'}
    >

      <Form noValidate onSubmit={handleSubmit(signUp)}>
        <Form.Group className="d-flex">
          <Form.Label className="me-1">{t('pages.signup.already_have_an_account_login')}</Form.Label>
          <Link
            to="/login"
            className="mx-0 px-0"
          >
            {t('pages.login.title')}
          </Link>
        </Form.Group>
        <Form.Group className="my-3" controlId="formBasicFirstName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('common.form.first_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.first_name') })}
            {...register('first_name')}
            isInvalid={!!errors.first_name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.first_name && errors.first_name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-3" controlId="formBasicLastName">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.last_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.last_name') })}
            {...register('last_name')}
            isInvalid={!!errors.last_name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.last_name && errors.last_name.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="my-3" controlId="formBasicEmail">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.email')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.email') })}
            {...register('email')}
            isInvalid={!!errors.email}
          />

          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-3" controlId="formBasicAirport">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.preferred_airport')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>

          <AirportSelection
            errors={errors}
            register={register}
            unregister={unregister}
            setValue={setValue}
            controlled={true}
            selectedAirports={initialValues.where_from}
            filter="countries"
          />
        </Form.Group>

        <Form.Group className="my-3" controlId="formBasicPassword">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.password')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder={t('common.form.password')}
            {...register('password')}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password && errors.password.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-3" controlId="formBasicConfirmPassword">
          <Form.Label className="fw-bold text-uppercase heading-text">
            {t('common.form.re_enter_password')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder={t('common.form.re_enter_password')}
            {...register('password_confirmation')}
            isInvalid={!!errors.password_confirmation}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password_confirmation && errors.password_confirmation.message}
          </Form.Control.Feedback>
        </Form.Group>
        {
          serverError !== '' &&
          <div className="form-group mb-4">
            <p className="text-danger py-1 mb-0">{t(serverError)}</p>
          </div>
        }
        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-4">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold"
                   key={index}>{t(error)}</p>)
            }
          </div>
        }
        <Form.Group className="d-grid gap-2 mt-5">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={RECAPTCHA_KEY}
          />
          <LoadingButton
            clickRef={submitRef}
            className="auth-btn text-primary px-5 heading-text text-uppercase"
            loading={signingUp}
            variant="dark"
            titleTranslationKey={max ? t('pages.signup.title_max') : t('pages.signup.title_lite')}
            type="submit"
          />
          {
            !max &&
            <LoadingButton
              onSubmit={tryTravelMax}
              className="auth-btn text-white px-5 heading-text text-uppercase mt-4"
              loading={trialLoading}
              variant="primary"
              titleTranslationKey={t('common.buttons.try_travel_max_subscription')}
              type="button"
            />
          }
        </Form.Group>
      </Form>
    </AuthLayout>
  )
}

function MemberBenefits () {
  const { t } = useTranslation()

  const { max } = useParams()

  const TravelMaxPriceYearly = 30
  return (
    <>
      {
        max ?
          max === 'trial' ?
            <TrialBenefits/>
            :
            <>
              <h5 className="my-3 heading-text text-primary head">
                {t('common.travel_max_feature.price_title', {
                  price: TravelMaxPriceYearly.toFixed(2),
                  currency: '£'
                })}
              </h5>
              <ul className="list-group list-group-flush benefits-list">
                <li className="list-group-item">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  {t('common.travel_max_feature.features.access_to_members_area')}
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  {t('common.travel_max_feature.features.set_airport_preferences_to_see_custom_deals')}
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  {t('common.travel_max_feature.features.browse_deals_curated_for_you')}
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  {t('common.travel_max_feature.features.save_multiple_trips_to_compare_prices')}
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
                  {t('common.travel_max_feature.features.and_more')}
                </li>
              </ul>
            </>
          :
          <ul className="list-group list-group-flush benefits-list">
            <li className="list-group-item">
              <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
              {t('pages.signup.benefits.travel_lite.see_results')}
            </li>
            <li className="list-group-item">
              <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
              {t('pages.signup.benefits.travel_lite.save_deal')}
            </li>
            <li className="list-group-item">
              <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
              {t('pages.signup.benefits.travel_lite.deal_sent_to_inbox')}
            </li>
            <li className="list-group-item">
              <FontAwesomeIcon className="me-2 text-primary" size="lg" icon={faCheck}/>
              {t('pages.signup.benefits.travel_lite.view_to_15_deals')}
            </li>
          </ul>
      }
    </>

  )
}

export default SignUp