import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import UserForm from './components/UserForm'
import { getValidationErrors } from '@/utility'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import AppModal from '@/shared/components/AppModal'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Create(props){
  const auth = useAuth()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [savingUser, setSavingUser] = useState(false)
  const [user, setUser] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [confirmText, setConfirmText] = useState(null)

  const [serverErrors, setServerErrors] = useState([]);

  const createUser = (values) => {
    setServerErrors([])
    setSavingUser(true)
    auth.postRequest(`${LOCATION.USERS.LIST.path}`, values)
      .then(response => {
        setUser(response?.data.data);
        setSavingUser(false)
        setModalTitle(t('pages.users.notifications.admin_created.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {
        setSavingUser(false)
        setServerErrors(getValidationErrors(error))
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    props.history.push(`${LOCATION.USERS.LIST.path}/${user?.id}`)
  }

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />
      <Helmet>
        <title>{t('pages.users.buttons.create_user')} - {t('pages.users.title')} - {t('common.app')}</title>
      </Helmet>

      <div className="p-4">
        <Breadcrumb className="text-dark heading-text">
          <Breadcrumb.Item className="">
            <Link to={LOCATION.USERS.LIST.path}>{t('pages.users.title')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('pages.users.buttons.create_admin')}</Breadcrumb.Item>
        </Breadcrumb>
        <UserForm
          admin={true}
          updateUser={createUser}
          savingUser={savingUser}
          serverErrors={serverErrors}
          submitLabel="pages.users.buttons.create_admin"
        />
      </div>
    </>
  )
}

export default Create;