import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import OnPageNotification from '@/shared/components/OnPageNotification'

function Error403 () {
  const { t } = useTranslation()
  let auth = useAuth()

  return (
    <OnPageNotification
      notificationTitle={t('common.errors.titles.error403')}
      notificationDescription={t('common.errors.body.error403')}
      notificationLinkText={t('common.go_home')}
      notificationAction={() => window.location.href = LOCATION.ROOT.path}
    />
  )
}

export default Error403
