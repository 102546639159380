import Chart from "react-apexcharts";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React from "react";

function CallComparisonPie({stats}){

  const {t} = useTranslation();

  const series = stats.status_composition.data;
  const  options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    colors: ['#28A745', '#FFC107', '#DC3545', '#17A2B8'],
    labels: stats.status_composition.labels,
  };

  return (
    <Card className="my-2">
      <Card.Header>{t('pages.dashboard.api_call_pie')}</Card.Header>
      <Card.Body>
        <Chart options={options} series={series} type="pie" width={350} />
      </Card.Body>
    </Card>
  )

}
export default CallComparisonPie;