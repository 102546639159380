import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import OnPageNotification from "../../shared/components/OnPageNotification";

function ConnectionError(){
  const {t} = useTranslation();
  let auth = useAuth();


  return (
    <OnPageNotification
      notificationTitle={t('common.errors.titles.connection_error')}
      notificationDescription={t('common.errors.body.connection_error')}
      notificationLinkText={t('common.retry')}
      notificationAction={() =>  window.location.reload()}
    />
  )
}
export default ConnectionError;