import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Breadcrumb, Button, Col, Row } from 'react-bootstrap'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import TripTable, { useColumns } from '@/shared/Tables/Trips'

function ShowDealWeek(){
  const { t } = useTranslation()
  const auth = useAuth()

  const { id } = useParams()

  const [dealWeek, setDealWeek] = useState(null)
  const history = useHistory()
  const route = useLocation()

  const [dealQuery, setDealQuery] = useState(route.state && route.state.query || {
    page: 0,
    skip: 0,
    limit: 15,
    week_id: id,
  })

  const [searching, setSearching] = useState(false)

  const getDealWeek = () => {
    return auth.getRequest(`${LOCATION.DEALS_WEEK.API.path}/${id}`)
      .then(response => {
        setDealWeek(response.data?.data)
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    let request = getDealWeek()

    return () => request?.abort && request.abort()
  }, [])

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        dealQuery,
      }
    )
  }, [dealQuery])

  if (!dealWeek) return <LoadingCover/>

  return (
    <>
      <Helmet>
        <title>{dealWeek.name} - {t('pages.top_15_deals.title')} - {t('common.app')}</title>
      </Helmet>

      <Row className="h-100">
        <Col className="col-12 p-4">
          <div className="d-flex justify-content-between">
            <Breadcrumb className="text-dark heading-text">
              <Breadcrumb.Item className="">
                <Link to={LOCATION.DEALS_WEEK.LIST.path}>{t('pages.top_15_deals.title')}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{dealWeek.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="d-flex">
              <Link to={`${LOCATION.DEALS_WEEK.LIST.path}/${dealWeek?.id}/edit`} className="btn btn-primary heading-text">
                {t('pages.top_15_deals.buttons.update_deal_week', {dealName: dealWeek.name})}
              </Link>
              <Button variant="danger" className="text-white heading-text mx-2">{t('common.buttons.delete')}</Button>
            </div>
          </div>
          <div className="my-4">
            <h5 className="heading-text my-3">{t('pages.deals.title')}</h5>
            <TripTable
              type="deals"
              query={dealQuery}
              showPagination={true}
              url={LOCATION.DEALS.API.path}
              dealWeek={dealWeek}
              onData={_ => setSearching(!_)}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}
export default ShowDealWeek;