import LOCATION from '@/constants/Location'
import Top15DealsPage from '@/pages/Admin/Top15Deals'
import CreateDealWeek from '@/pages/Admin/Top15Deals/CreateDealWeek'
import ShowDealWeek from '@/pages/Admin/Top15Deals/ShowDealWeek'
import EditDealWeek from '@/pages/Admin/Top15Deals/EditDealWeek'

const Top15Deals = [
  {
    path: LOCATION.DEALS_WEEK.LIST.path,
    component: Top15DealsPage,
    exact: true,
    main: false,
    gate: 'deal-weeks',
  },
  {
    path: LOCATION.DEALS_WEEK.CREATE_DEAL_WEEK.path,
    component: CreateDealWeek,
    exact: true,
    main: false,
    gate: 'deal-weeks',
  },
  {
    path: LOCATION.DEALS_WEEK.SHOW_DEAL_WEEK.path,
    component: ShowDealWeek,
    exact: true,
    main: false,
    gate: 'deal-weeks',
  },
  {
    path: LOCATION.DEALS_WEEK.EDIT_DEAL_WEEK.path,
    component: EditDealWeek,
    exact: true,
    main: false,
    gate: 'deal-weeks',
  },
]

export default Top15Deals;