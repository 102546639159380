import { useTranslation } from 'react-i18next'
import {Dropdown} from "react-bootstrap";
import React from "react";

function Footer () {
  const { t } = useTranslation()

  const travelMumWebsiteUr = process.env.REACT_APP_TRAVEL_MUM_WEBSITE_URL;

  return (
    <div className="container fw-bold px-3 py-5 d-flex justify-content-between text-center text-md-start flex-column flex-md-row">
      <div>© {new Date().getFullYear()} {t('common.app')}</div>
      <div className="mt-3 mt-md-0 d-grid d-md-flex">
        <a href={`${travelMumWebsiteUr}/about-us`} className="text-dark mx-3" target="_blank">About Us</a>
        <a href={`${travelMumWebsiteUr}/financial-protection`} className="text-dark mx-3" target="_blank">Financial Protection</a>
        <a href={`${travelMumWebsiteUr}/faq`} className="text-dark mx-3" target="_blank">FAQs</a>
        <a href={`${travelMumWebsiteUr}/privacy-policy`} className="text-dark mx-3" target="_blank">{t('common.privacy_policy')}</a>
        <a href={`${travelMumWebsiteUr}/terms-and-conditions`} className="text-dark mx-3"  target="_blank">{t('common.terms_and_condition')}</a>
        <a href={`${travelMumWebsiteUr}/contact-us`} className="text-dark mx-3"  target="_blank">{t('common.contact_us')}</a>
      </div>
      <div className="social-icons">
        <a href="https://www.tiktok.com/@thetravelmum" className="btn p-0 mx-2 mt-3 mt-md-0" target="_blank">
          <img src="/assets/images/icons/ticktok.svg"/>
        </a>
        <a href="https://www.instagram.com/thetravelmum" className="btn p-0 mx-2 mt-3 mt-md-0" target="_blank">
          <img src="/assets/images/icons/instagram.svg"/>
        </a>
        <a href="https://www.facebook.com/TheTravelmumGroup" className="btn p-0 mx-2 mt-3 mt-md-0" target="_blank">
          <img src="/assets/images/icons/facebook.svg"/>
        </a>
        <a href="https://www.youtube.com/channel/UCHz78Fhnmbl-HQtNvku3XYg" className="btn p-0 mx-2 mt-3 mt-md-0" target="_blank">
          <img src="/assets/images/icons/youtube.svg"/>
        </a>
      </div>
    </div>
  )
}

export default Footer