import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
});

let handleApiErrorResponse = () => {};

api.defaults.withCredentials = true;
api.defaults.withXSRFToken = true;

const postRequest = (endpoint, payload, headers) => {
    return new Promise((resolve, reject) => {

        api.post(`${endpoint}`, payload, headers)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    })

}

const getRequest = (endpoint, params) => {
    const source = axios.CancelToken.source();

    const request = new Promise((resolve, reject) => {
        api.get(`${endpoint}`, {
            params: params != null ? params : {},
            cancelToken: source.token,
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                if(axios.isCancel(error))
                {
                    reject();
                }
                else
                {
                    handleApiErrorResponse(error);
                    reject(error);
                }
            });
    });

    request.abort = function () {
        source.cancel();
    };

    return request;
}

const putRequest = (endpoint, payload) => {
    return new Promise((resolve, reject) => {
        api.put(`${endpoint}`, payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    })
}

const deleteRequest = (endpoint) => {
    return new Promise((resolve, reject) => {
        api.delete(`${endpoint}`)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    })
}

const setHandler = (handler) => handleApiErrorResponse = handler;
const setToken = (token) => {}; // api.defaults.headers.common['Authorization'] = 'Bearer ' + token;


export default api;

export {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,

    setHandler,
    setToken,
};
