import LOCATION from '@/constants/Location'
import DownloadResource from '../../pages/DownloadResource'

const TravelResources = [
  {
    path: LOCATION.TRAVEL_RESOURCES.DOWNLOAD.path,
    component: DownloadResource,
    exact: true,
    main: false,
  },
]

export default TravelResources