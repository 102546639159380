import { useAuth } from '@/services/Auth'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LOCATION from '@/constants/Location'
import React, { useEffect, useState } from 'react'
import LoadingCover from '@/shared/components/LoadingCover'
import LocationForm from './components/LocationForm'
import AppModal from '@/shared/components/AppModal'
import { getValidationErrors } from '@/utility'
import { Helmet } from 'react-helmet'
import { Breadcrumb } from 'react-bootstrap'

function Edit (props) {

  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [savingLocation, setSavingLocation] = useState(false)
  const [location, setLocation] = useState(true)
  const [deleting, setDeleting] = useState(false)
  const [deleteRequest, setDeleteRequest] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [locationDeleted, setLocationDeleted] = useState(false)

  const [serverErrors, setServerErrors] = useState([])

  const saveLocation = (values) => {
    let data = values
    data.airports = values.airports?.map(_ => _.value)
    setSavingLocation(true)
    auth.putRequest(`${LOCATION.DESTINATIONS.API.path}/${id}`, data)
      .then(response => {
        setModalTitle(t('pages.locations.notifications.location_updated.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null)
        setModalBody(null)
        setShowModal(true)
      })
      .catch(error => {
        setSavingLocation(false)
        setServerErrors(getValidationErrors(error))
      })
  }

  const deleteLocation = () => {
    setDeleting(true)
    setDeleteRequest(false)

    auth.deleteRequest(`${LOCATION.DESTINATIONS.API.path}/${id}`)
      .then(response => {
        setModalTitle(t('pages.locations.notifications.location_deleted.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null)
        setModalBody(null)
        setShowModal(true)
        setLocationDeleted(true)
      })
      .catch(error => {
        setDeleting(false)
      })
  }

  const deleteLocationRequest = () => {

    setModalTitle(t('pages.locations.notifications.delete_location_request.title'))
    setModalBody(t('pages.locations.notifications.delete_location_request.body'))
    setConfirmText(t('common.dialogs.actions.okay'))
    setCancelText(t('common.dialogs.actions.cancel'))
    setShowModal(true)
    setDeleteRequest(true)
  }

  const getLocation = () => {
    setLoading(true)
    return auth.getRequest(`${LOCATION.DESTINATIONS.LIST.path}/${id}`)
      .then(response => {
        setLocation(response?.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    if (deleteRequest) {
      deleteLocation()
    }
    if (locationDeleted) {
      props.history.push(LOCATION.DESTINATIONS.LIST.path)
    }
    if (savingLocation) {
      setSavingLocation(false)
      getLocation()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
    if (savingLocation) {
      setSavingLocation(false)
      getLocation()
    }
  }

  useEffect(() => {
    let result = getLocation()
    return () => result?.abort && result.abort()
  }, [])

  if (loading) return <LoadingCover/>
  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Helmet>
        <title>{t('pages.locations.buttons.update_location')} - {t('pages.locations.title')} - {t('common.app')}</title>
      </Helmet>

      <div className="p-4">
        <Breadcrumb className="text-dark heading-text">
          <Breadcrumb.Item className="">
            <Link to={LOCATION.DESTINATIONS.LIST.path}>{t('pages.locations.title')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{location.name}</Breadcrumb.Item>
        </Breadcrumb>
        <LocationForm
          location={location}
          saveLocation={saveLocation}
          submitting={savingLocation}
          serverErrors={serverErrors}
          deleting={deleting}
          deleteLocationRequest={deleteLocationRequest}
          submitLabel={t('pages.locations.buttons.update_location')}
        />
      </div>
    </>
  )
}

export default Edit