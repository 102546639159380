import { useAuth } from '../../services/Auth'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Button, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import FlightCard from './components/FlightCard'
import React, { useEffect, useState } from 'react'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'

function DealFlights (props) {
  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const [flights, setFlights] = useState([])
  const [loading, setLoading] = useState(true)
  const [deal, setDeal] = useState(null)

  const getFlights = () => {
    return auth.getRequest(`${LOCATION.FLIGHTS.API.path}`, {
      deal_id: id,
    })
      .then(response => {
        setFlights(response.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }
  const getDeal = () => {
    return auth.getRequest(`${LOCATION.DEALS.API.path}/${id}`)
      .then(response => {
        setDeal(response.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const selectFlight = (flight) => {
    let updatedTotal = parseFloat(deal.hotel.price) + parseFloat(flight.price)

    auth.putRequest(`${LOCATION.DEALS.API.path}/${id}`, {
      ...deal,
      ...{ flight_id: flight.id },
      ...{ total_amount: updatedTotal },
    })
      .then(response => {
        setDeal(response.data?.data)
        props.history.push(`${LOCATION.DEALS.API.path}/${id}`)
      })
      .catch(error => {
      })
  }

  useEffect(() => {
    let request = getDeal()

    return () => request?.abort && request.abort()
  }, [])

  useEffect(() => {
    let result = getFlights()
    return () => result?.abort && result.abort()
  }, [])

  if (loading && flights.length === 0 && !deal) {
    return <LoadingCover/>
  }

  return (
    <>
      <div className="container p-md-2 p-0">
        <Row className="mt-md-4 mt-0 heading-space">
          <Col className="col-md-6 col-12 d-flex">
            <Link to={`${LOCATION.DEALS.LIST.path}/${id}`}
                  className="btn text-white me-3 py-0 my-0 bg-white text-primary border-0 d-flex align-items-center">
              <FontAwesomeIcon size="lg" className="text-primary" icon={faArrowLeftLong}/>
            </Link>
            <div className="button-label primary heading-text">
              <span className="text-start">{t('pages.deals.choose_flight')}</span>
            </div>
          </Col>
          <Col className="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div className="button-label light shadow heading-text">
              {t('common.total_price', {
                price: deal?.total_amount,
                currency: auth.user?.currency?.symbol
              })}
            </div>
          </Col>
        </Row>

        <div className="p-4 p-md-0">
          <p className="w-100 text-md-start text-center my-4">
            {t('pages.deals.flight_choices_found', { city: deal?.location.destination })}
          </p>
          <Row className="">
            {
              flights?.map(flight => {
                  return (
                    <Col
                      className={`col-xl-4 col-lg-6 col-md-6 col-12 mb-4 select-card ${deal?.flight_id === flight.id ? 'selected' : ''}`}
                      key={flight.id}>
                      <FlightCard
                        disableBook={true}
                        key={flight.id}
                        flight={flight}
                        deal={deal}
                        selectFlight={selectFlight}
                        fullCard={true}
                        selected={deal?.flight_id === flight.id}
                      />
                    </Col>
                  )
                }
              )
            }
          </Row>
        </div>
      </div>
    </>
  )
}

export default DealFlights