import useGet from '../hooks/useGet'
import LOCATION from '../constants/Location'
import LoadingCover from '../shared/components/LoadingCover'
import PageHeader from '../layouts/components/PageHeader'
import { useTranslation } from 'react-i18next'
import OnPageNotification from '../shared/components/OnPageNotification'
import TravelMaxFeature from './Banners/TravelMaxFeature'
import React from 'react'
import { useAuth } from '../services/Auth'
import TravelMaxPremium from './Banners/TravelMaxPremium'

function DownloadResource () {

  const auth = useAuth();

  const { t } = useTranslation()

  const { data: travelResources, loading } = useGet(LOCATION.TRAVEL_RESOURCES.API.path)

  if( auth.user?.user_type !== 'travel_max' ){
    return  <TravelMaxPremium/>
  }

  if (loading) return <LoadingCover/>

  return (
    <>
      <PageHeader
        title={t('pages.travel_resources.title')}
      />
      <div className="container h-100">
        {
          travelResources && travelResources.length === 0 ?
            <OnPageNotification
              notificationTitle={t('pages.travel_resources.notifications.not_resources_found.title')}
            />
            : null
        }
      </div>
    </>
  )

}

export default DownloadResource