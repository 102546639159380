import { useEffect } from 'react';
import { ProvideAuth } from "@/services/Auth.js";
import Layout from "./layouts/Layout";
import Error500 from "./pages/errors/Error500";

// import { Provider, ErrorBoundary, LEVEL_ERROR } from '@rollbar/react';

// const rollbarConfig = {
//   accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
//   environment: process.env.REACT_APP_ENV,
// };

const ErrorDisplay = ({error, resetError}) => <Error500/>;

document.addEventListener("wheel", function(event){
  if(event.target.type === "number"){
    event.target.blur();
    setTimeout(() => event.target.focus(), 100);
  }
});


export default function App() {
    return (
    // <Provider config={rollbarConfig}>
    //   <ErrorBoundary level={LEVEL_ERROR} fallbackUI={ErrorDisplay}>
        <ProvideAuth>
          <Layout />
        </ProvideAuth>
      // </ErrorBoundary>
    // </Provider>
    );
}