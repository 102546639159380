import { Col, Form, Row } from 'react-bootstrap'
import LoadingButton from '@/shared/components/LoadingButton'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import TravelerSelection from './TravelerSelection'
import React from 'react'
import AirportSelection from './AirportSelection'

function QuickRequestForm ({ submitTripRequest, requestingTrip, savedRequest }) {

  const { t } = useTranslation()

  let localSavedValues = localStorage.getItem('trip_request')

  let savedValues = localSavedValues ? JSON.parse(localSavedValues) : null

  const schema = yup.object().shape({
    when: yup.string()
      .required(t('pages.trips.form.validation_message.required', { attribute: t('pages.trips.form.labels.when') })),
    how_long: yup.string()
      .required(t('pages.trips.form.validation_message.required', { attribute: t('pages.trips.form.labels.how_long') })),
    where_to: yup.string()
      .required(t('pages.trips.form.validation_message.required', { attribute: t('pages.trips.form.labels.where_to.title') })),

    where_to_avoid: yup.string()
      .required(t('pages.trips.form.validation_message.required', { attribute: t('pages.trips.form.labels.where_to_avoid') })),
    adults: yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.adults') })),
    children: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    children_ages: yup.array()
      .typeError(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
      .when(['children'], function (children, schema) {
        return children && children != 0 ?
          schema.of(yup.number()
            .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
            .transform((value) => (isNaN(value) ? undefined : value)))
          : schema.nullable()
      }),
    where_from: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.where_from.title') })),
        label: yup.string(),
      })),
    max_budget: yup.string()
      .required(t('pages.trips.form.validation_message.required', { attribute: t('pages.trips.form.labels.max_budget.title') })),
    trip_specifics: yup.string()
      .required(t('pages.trips.form.validation_message.required', { attribute: t('pages.trips.form.labels.trip_specifics.title') })),
  })

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: savedRequest ? savedValues : {},
    resolver: yupResolver(schema),
  })

  const whereTo = watch('where_to')

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitTripRequest)} className="mb-5">
        <Form.Group className="my-4" controlId="formBasicWhen">
          <Form.Label className="d-flex align-items-center">
            {t('pages.trips.form.labels.when.when')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('pages.trips.form.placeholder.type_here')}
            {...register('when')}
            isInvalid={!!errors.when}
          />

          <Form.Control.Feedback type="invalid">
            {errors.when && errors.when.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicWhen">
          <Form.Label className="d-flex align-items-center">
            {t('pages.trips.form.labels.when.how_long')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>
          <Form.Control
            placeholder={t('pages.trips.form.placeholder.type_here')}
            {...register('how_long')}
            isInvalid={!!errors.how_long}
          />

          <Form.Control.Feedback type="invalid">
            {errors.how_long && errors.how_long.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicWhen">
          <Form.Label className="d-flex align-items-center">
            {t('pages.trips.form.labels.where.title')}
          </Form.Label>
          <small className="my-2">{t('pages.trips.form.labels.where.subtitle')}</small>
          <Form.Control
            placeholder={t('pages.trips.form.placeholder.type_here')}
            {...register('where_to')}
            isInvalid={!!errors.where_to}
          />

          <Form.Control.Feedback type="invalid">
            {errors.where_to && errors.where_to.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicWhen">
          <Form.Label className="d-flex align-items-center">
            {t('pages.trips.form.labels.where_to_avoid')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>

          <Form.Control
            placeholder={t('pages.trips.form.placeholder.type_here')}
            {...register('where_to_avoid')}
            isInvalid={!!errors.where_to_avoid}
          />
          <Form.Control.Feedback type="invalid">
            {errors.where_to_avoid && errors.where_to_avoid.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="my-4">
          <Form.Label className="d-flex align-items-center">
            {t('pages.trips.form.labels.who_is_traveling_adults.heading')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>
          <TravelerSelection
            register={register}
            unregister={unregister}
            errors={errors}
            watch={watch}
          />
        </Row>

        <Form.Group className="my-4" controlId="formBasicWhen">
          <Form.Label className="d-flex align-items-center small">
            {t('pages.trips.form.labels.preferred_airports.title')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>
          <small>{t('pages.trips.form.labels.preferred_airports.subtitle')}</small>
          <AirportSelection
            errors={errors}
            unregister={unregister}
            register={register}
            reset={reset}
            setValue={setValue}
            selectedAirports={savedRequest ? savedValues?.where_from : []}
          />
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicBudget">
          <Form.Label className="d-flex align-items-center small">
            {t('pages.trips.form.labels.max_budget.title')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>
          <small>{t('pages.trips.form.labels.max_budget.subtitle')}</small>
          <Form.Control
            className="mt-2"
            {...register('max_budget')}
            placeholder={t('pages.trips.form.placeholder.type_here')}
            isInvalid={!!errors.max_budget}
          />

          <Form.Control.Feedback type="invalid">
            {errors.max_budget && errors.max_budget.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicBudget">
          <Form.Label className="d-flex align-items-center small">
            {t('pages.trips.form.labels.trip_specifics.title')}
            <span className="text-danger ms-1 small">*</span>
          </Form.Label>
          <small>{t('pages.trips.form.labels.trip_specifics.subtitle')}</small>
          <Form.Control
            type="text"
            placeholder={t('pages.trips.form.placeholder.type_here')}
            {...register('trip_specifics')}
            isInvalid={!!errors.trip_specifics}
          />

          <Form.Control.Feedback type="invalid">
            {errors.trip_specifics && errors.trip_specifics.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="d-grid gap-2 mt-4 mb-2">
          <LoadingButton
            className="heading-text"
            loading={requestingTrip}
            variant="primary"
            titleTranslationKey="common.submit"
            type="submit"
          />
        </Form.Group>
      </Form>
    </>
  )
}

export default QuickRequestForm