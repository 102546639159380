import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import PageHeader from '@/layouts/components/PageHeader'
import OnPageNotification from '@/shared/components/OnPageNotification'
import LOCATION from '@/constants/Location'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import FreeCustomTripsProgress from '../components/FreeCustomTripsProgress'
import { Button } from 'react-bootstrap'
import QuickRequestForm from './components/QuickRequestForm'
import { useAuth } from '@/services/Auth'
import PaymentScreen from '@/screens/PaymentScreen'
import { Link, useParams } from 'react-router-dom'
import TravelMaxPremium from '../../Banners/TravelMaxPremium'

function RequestForm (props) {
  const { t } = useTranslation()
  const auth = useAuth()

  const { saved_request } = useParams()

  const [requestingTrip, setRequestingTrip] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const [showPaymentScreen, setShowPaymentScreen] = useState(false)

  const submitTripRequest = (values) => {

    if (auth.user && (auth.user.trip_request_count < auth.user?.max_trip_request_count || auth.user.purchased_trips >= 1)) {
      setRequestingTrip(true)
      localStorage.removeItem('trip_request')
      auth.postRequest(`${LOCATION.TRIPS.HOLIDAY_REQUEST.path}`, {
        budget: values.max_budget,
        when: values.when,
        how_long: values?.how_long,
        where_from: values?.where_from?.map(_ => _.value),
        where_to: values?.where_to,
        where_to_avoid: values?.where_to_avoid,
        adults: values?.adults,
        children: values?.children == 0 ? null : values?.children,
        children_ages: values?.children_ages,
      })
        .then(response => {
          setRequestingTrip(false)
          setRequestSent(true)
          auth.checkToken()
        })
        .catch(error => {
          setRequestingTrip(false)
        })

    } else {
      localStorage.setItem('trip_request', JSON.stringify(values))
      props.history.push(`${LOCATION.PAYMENT_METHODS.CREATE.path}/buy-trip`)
    }

  }

  const getUser = () => {
    return auth.getRequest(`${LOCATION.USERS.API.path}/${auth.user.id}`)
      .then(response => {
        auth.updateUser(response.data?.data)
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    let request = getUser()
    return () => request?.abort && request.abort()
  }, [])

  if( auth.user?.user_type !== 'travel_max' ){
    return  <TravelMaxPremium/>
  }

  if (showPaymentScreen) {
    return <PaymentScreen/>
  }

  if (requestSent) {
    return (
      <>
        <PageHeader
          title={t('pages.trips.confirmation_message')}
        />
        <OnPageNotification
          notificationTitle={t('pages.trips.notifications.request_sent_sent.title')}
          notificationDescription={t('pages.trips.notifications.request_sent_sent.body')}
          notificationLink={LOCATION.ROOT.path}
          notificationLinkText={t('pages.trips.notifications.request_sent.link_text')}
          notificationIcon={<FontAwesomeIcon className="text-primary" size="2xl" icon={faCircleCheck}/>}
        />
      </>
    )
  }

  return (
    <>
      <PageHeader
        title={t('pages.trips.request_a_trip')}
      />
      <div className="container" style={{ maxWidth: '500px' }}>

        <FreeCustomTripsProgress/>
        <div className="d-grid gap-2 px-2 mb-4">
          <Link
            to={`${LOCATION.PAYMENT_METHODS.CREATE.path}/buy-trip`}
            className="btn btn-primary heading-text "
          >
            {t('pages.trips.buttons.purchase_more_plans')}
          </Link>
        </div>

        <p>{t('pages.trips.form.title')}</p>

        <QuickRequestForm
          submitTripRequest={submitTripRequest}
          requestingTrip={requestingTrip}
          savedRequest={saved_request}
        />

      </div>
    </>
  )
}

export default RequestForm