function AdminPageHeader ({ mainTitle, title, subtitle }) {
  return (
    <>
      <div className="d-flex">
        <h5 className={`heading-text ${title ? 'text-primary': 'text-dark'}`}>{mainTitle}</h5>
        {
          title ? <>

            <h5 className="heading-text">&nbsp;/&nbsp;</h5>
            <h5 className={`heading-text ${subtitle ? 'text-primary': 'text-dark'}`}>{title}</h5>
          </> : null
        }
        {
          subtitle ? <>

            <h5 className="heading-text">&nbsp;/&nbsp;</h5>
            <h5 className="heading-text">{subtitle}</h5>
          </> : null
        }
      </div>
    </>
  )
}

export default AdminPageHeader