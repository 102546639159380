import LOCATION from "@/constants/Location";
import RequestForm from "@/pages/Trips/Request/RequestForm";
import MySavedTrips from '@/pages/Trips/MySavedTrips'
import StartYourJourney from '@/pages/Trips/StartYourJourney'
import Trip from '@/pages/Trips/Trip'
import Deals from '@/pages/Deals'

const Trips = [
  {
    path: LOCATION.TRIPS.START_YOUR_JOURNEY.path,
    component: StartYourJourney,
    public: true,
    authVisit: true,
    exact: true,
  },
  {
    path: LOCATION.TRIPS.START_YOUR_JOURNEY_STEP.path,
    component: StartYourJourney,
    public: true,
    authVisit: true,
    exact: true,
  },
  // {
  //   path: LOCATION.TRIPS.REQUEST_A_TRIP.path,
  //   component: RequestForm,
  //   exact: true,
  //   main: false,
  // },
  {
    path: LOCATION.TRIPS.REQUEST_A_TRIP_CONTINUE.path,
    component: RequestForm,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.TRIPS.START_YOUR_JOURNEY_RESULTS.path,
    component: Deals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.TRIPS.START_YOUR_JOURNEY_LOCATION_RESULTS.path,
    component: Deals,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.TRIPS.MY_SAVED_TRIPS.path,
    component: MySavedTrips,
    exact: true,
    main: false,
  },
  {
    path: LOCATION.TRIPS.SHOW.path,
    component: Trip,
    exact: true,
    main: false,
  },
]

export default Trips;