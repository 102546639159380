import LOCATION from '@/constants/Location'
import Notifications from '@/pages/Notifications'

const NotificationsRoutes = [
  {
    path: LOCATION.NOTIFICATIONS.LIST.path,
    component: Notifications,
    exact: true,
    main: false,
  },
]
export default NotificationsRoutes