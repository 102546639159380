import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import UserForm from '../Users/components/UserForm'
import AppModal from '@/shared/components/AppModal'
import AdminPageHeader from '@/layouts/components/AdminPageHeader'
import LOCATION from '@/constants/Location'
import { getValidationErrors } from '@/utility'
import LoadingCover from '@/shared/components/LoadingCover'
import HolidayForm from './components/HolidayForm'
import { useAuth } from '@/services/Auth'
import { Breadcrumb } from 'react-bootstrap'

function Edit (props) {
  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [savingHoliday, setSavingHoliday] = useState(false)
  const [holiday, setHoliday] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [confirmText, setConfirmText] = useState(null);
  const [deleting, setDeleting] = useState(false)

  const [serverErrors, setServerErrors] = useState([])

  const updateHoliday = (values) => {
    setServerErrors([])
    setSavingHoliday(true)

    values.locations = values.locations?.map(_ => _.value);

    auth.putRequest(`${LOCATION.HOLIDAY_TYPES.LIST.path}/${id}`, values)
      .then(response => {
        setSavingHoliday(false)
        setModalTitle(t('pages.holidays.notifications.package_updated.title'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowModal(true)
      })
      .catch(error => {
        setSavingHoliday(false)
        setServerErrors(getValidationErrors(error))
      })
  }

  const deleteHoliday = () => {
      setServerErrors([])
      setDeleting(true)

      auth.deleteRequest(`${LOCATION.HOLIDAY_TYPES.LIST.path}/${id}`)
          .then(response => {
              setDeleting(false)
              setModalTitle(t('pages.holidays.notifications.package_deleted.title'))
              setConfirmText(t('common.dialogs.actions.okay'))
              setShowModal(true)
          })
          .catch(error => {
              setDeleting(false)
              setServerErrors(getValidationErrors(error))
          })
  }

  const getHoliday = () => {
    return auth.getRequest(`${LOCATION.HOLIDAY_TYPES.LIST.path}/${id}`)
      .then(response => {
        setHoliday(response?.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    props.history.push(`${LOCATION.HOLIDAY_TYPES.LIST.path}`)
  }

  useEffect(() => {
    let result = getHoliday()
    return () => result?.abort && result.abort()
  }, [])

  if (loading) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        confirmText={confirmText}
        handleConfirm={handleConfirm}
      />
      <Helmet>
        <title>{t('pages.holidays.edit_package')} - {t('pages.deals.holidays')} - {t('common.app')}</title>
      </Helmet>

      <div className="p-4">
        <Breadcrumb className="text-dark heading-text">
          <Breadcrumb.Item className="">
            <Link to={LOCATION.HOLIDAY_TYPES.LIST.path}>{t('pages.deals.holidays')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('pages.holidays.edit_package')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{holiday.name}</Breadcrumb.Item>
        </Breadcrumb>
        <HolidayForm
          holiday={holiday}
          updateHoliday={updateHoliday}
          savingHoliday={savingHoliday}
          serverErrors={serverErrors}
          submitLabel="pages.holidays.buttons.update_package"
          deleting={deleting}
          deleteHolidayRequest={deleteHoliday}
        />
      </div>
    </>
  )
}

export default Edit